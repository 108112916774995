export default {
    "login": {
      "sign": "로그인",
      "login": "바로 로그인하기",
      "remember": "나를 기억합니다. ",
      "about": "브랜드 스토리",
      "illegal_email": "무효한 메일 형식",
      "illegal_password": "비밀번호는 6-16자리 숫자가 필요하며 문자와 숫자가 포함되어야 합니다.",
      "password_matching": "두 비밀번호가 일치하지 않습니다.",
      "enterEmail": "이메일 또는 잭커리 ID를 입력하십시오.",
      "enter_code": "코드를 입력하세요. ",
      "forgot": "비밀번호를 잊어버리셨나요?",
      "create": "계정을 만들기",
      "thirdLogin": "또는 로그인 사용",
      "accountActivate": "계정이 활성화되었습니다.",
      "bindEmail": "이메일 바인딩하기",
      "error": "로그인이 실패했습니다. 나중에 다시 시도하십시오."
    },
    "policies": {
      "content": "제3자를 통해 로그인하면 이미 저희의",
      "policy-link": "https://kr.jackery.com/policy/privacy-policy",
      "policy": "개인 정보 보호 정책",
      "and": "및",
      "terms-link": "https://kr.jackery.com/policy/terms-of-service",
      "terms": "서비스 이용 약관",
      "comma": ".",
      "footer": "잭커리는 등록 또는 로그인을 선택한 타사에서 제공한 정보를 사용합니다."
    },
    "reset": {
      "modify": "비밀번호 변경",
      "back": "로그인으로 돌아가기",
      "success": "비밀번호가 변경되었습니다!",
      "referrer": "원래 페이지로 돌아가기",
      "seconds": "초"
    },
    "register": {
      "title": "등록하기",
      "enterEmail": "이메일을 입력하세요.",
      "agree": "잭커리를 동의합니다.",
      "agree_after": "",
      "getDeals": "잭커리의 최신 소식, 프로모션 및 제품 정보를 받는 데 동의합니다.",
      "login": "바로 로그인하기 ",
      "success": "계정 '{email}' 이 성공적으로 등록되었습니다!",
      "welcomeEmail": "환영 메시지가 이미 이메일 주소로 발송되었습니다.",
    },
    "header": {
      "logout": "로그아웃",
      "userCenter": "계정 센터",
      "personalCenter": "개인 센터",
      "shopping": "쇼핑하기",
      "setting": "설정",
      "myJackery": "계정 센터",
      "jackeryID": "개인 센터",
      "wishlist": "나의 위시리스트",
    },
    "footer": {
      "cookieContent": "당사는 제3자 cookies를 포함한 cookies를 사용하여 원활한 사이트 운영, 트래픽 분석, 향상된 소셜 미디어 경험 제공, 맞춤형 콘텐츠 및 광고를 보장합니다.",
      "cookieUrl": "#",
      "learn": "더 알아보기",
      "accept": "Cookies 동의합니다.",
      "copyright": "저작권© {date} 잭커리 주식회사는 모든 권리를 보유합니다.",
      "sitemap-link": "#",
      "sitemap": "사이트 맵",
    },
    "account": {
      "welcome": "환영합니다.",
      "email": "이메일",
      "registerEmail": "등록된 메일",
      "bindNewRegisterEmail": "새 이메일 주소",
      "emailIsEqual": "새 이메일 주소는 수정할 필요 없이 이전과 동일합니다.",
      "settings": "계정 설정",
      "settingTips": "계정 정보 및 보안 세부 정보를 빠르게 관리할 수 있습니다.",
      "titleProfiles": "나의 정보",
      "titleSecurity": "계정 보안",
      "titleAuthorization": "계정 바인딩하기",
      "nickname": "닉네임",
      "name": "성명",
      "gender": "성별",
      "birthday": "생년월일",
      "contactEmail": "이메일 주소",
      "contactPhone": "전화",
      "enterprise": "기업",
      "setted": "설정",
      "unset": "설정 취소",
      "password": "비밀번호",
      "avatarSettings": "프로필 사진 설정",
      "choosePicture": "이미지 선택",
      "apply": "적용",
      "secrecy": "비밀",
      "male": "남자",
      "female": "여자",
      "currentPassword": "현재 비밀번호",
      "newPassword": "새 비밀번호",
      "againPassword": "새 비밀번호 확인하세요.",
      "jackeryIdTips": "잭커리 ID는 한 번만 편집할 수 있으며 잭커리 ID는 숫자와 문자를 포함하되 32자를 초과할 수 없는 6자 이상이어야 합니다.",
      "birthdayTips": "최고의 서비스를 제공 드릴 수 있도록 생년월일을 입력하세요. 저희는 고객님의 모든 개인 정보에 대해 엄격히 비밀을 지킬 것입니다.",
      "nickNameTips": "닉네임은 문자나 숫자로 구성될 수 있으며 32자를 초과할 수 없습니다.",
      "nameTips": "저희가 연락드릴 수 있도록 실명을 사용하세요. 저희가 모든 개인 정보에 대해 엄격히 비밀을 지킬 것입니다.",
      "genderTips": "저희가 고품질의 서비스를 제공 드릴 수 있도록 성별을 입력하십시오. 저희가 모든 개인 정보에 대해 엄격히 비밀을 지킬 것입니다.",
      "contactEmailTips": "저희가 편리하게 연락 드릴 수 있도록 가장 자주 사용하는 이메일 주소를 사용하세요.저희는 고객님의 개인 정보에 대해 엄격히 비밀을 지킬 것입니다.",
      "phoneNumberTips": "저희가 편리하게 연락 드릴 수 있도록 가장 자주 사용하는 연락처를 입력하십시오. 저희는 고객님의 개인 정보에 대해 엄격히 비밀을 지킬 것입니다.",
      "phoneNumberTips2": "숫자, 괄호 및 하이픈만 지원됩니다.",
      "enterpriseNameTips": "상업 계정을 사용하시는 경우 귀하의 관련 상업 정보를 알려 주시면 저희가 최고 품질의 서비스를 제공 드리겠습니다.",
      "emailTips": "가입한 이메일 주소는 바로 로그인 계정입니다.사용자 계정을 보호하기 위해 등록된 이메일 주소를 변경하려면 인증이 필요합니다.",
      "passwordTips": "보안상의 이유로 더 강력한 비밀번호를 설정하십시오.",
      "location": "위치",
      "firstName": "이름",
      "lastName": "성",
      "successTip": "개인 정보를 성공적으로 제출했습니다.",
      "myProfile": "내 프로필",
      "editPasswordTips": "비밀번호에는 6-16자리 숫자가 필요하며 문자와 숫자가 포함되어야 합니다. 보안상의 이유로 강력한 암호를 설정하십시오.",
      "successTip1": "제출이 완료되었으며 {num} 잭커리 포인트는 24시간 이내에 받을 수 있습니다.",
      "phoneTip": "나의 첫 번째 문자 구독을 위해 {num} 포인트를 받고 잭커리의 최신 소식과 혜택을 받습니다. 잭커리의 서비스 이용 약관 및 개인 정보 보호 정책에 동의합니다.",
      "birthdayTip1": "생일을 기입하여 {num} 포인트 적립을 받으세요.",
      "birthdayTip2": "생일마다 {num} 포인트 적립을 받고 쇼핑 포인트를 두 배로 받습니다.",
      "birthdayTip3": "수정할 기회는 한 번뿐입니다.",
      "subscriberTip": "내 첫 번째 이메일 구독을 위해 {num} 포인트를 받고 잭커리의 최신 메시지와 혜택을 받으십시오. 잭커리의 서비스 약관 및 개인 정보 보호 정책에 동의합니다.",
      "changePassword": "비밀번호 변경하기",
      "errorTip": "제출이 실패했습니다. 확인하시고 다시 제출하십시오.",
      "countryRegion": "국가/지역",
    },
    "router": {
      "memberIndex": "맴버십",
      "memberGuide": "맴버십 가이드",
      "home": "홈",
      "404": "404오류",
      "about": "....에 대하여",
      "register": "등록하기",
      "login": "로그인",
      "reset": "재설정",
      "dashboard": "대시보드",
      "commonSetting": "일반 설정",
      "account": "계정 설정",
      "accountOverview": "개요",
      "myProfile": "나의 프로필",
      "myOrders": "나의 주문",
      "personalSettings": "개인 설정",
      "myDevices": "나의 장치",
      "myExtendedWarranty":"My Extended Warranty",
      "activate": "계정 활성화",
      "myService": "나의 서비스",
      "myReturn": "나의 반품",
      "myTradeIn": "나의 수상 판매",
      "myJackeryLife": "나의 잭커리 라이프",
      "myWishlist": "나의 위시리스트",
      "myWallet": "나의 지갑",
      "accountSettings": "계정 설정",
      "addressBook": "나의 주소",
      "myReferral": "My Referral",
    },
    "description": {
      "memberIndex": "잭커리 맴버십",
      "memberGuide": "잭커리 맴버십 가이드",
      "home": "잭커리 맴버십, 잭커리 계정 센터",
      "404": "404 오류",
      "about": "...에 대하여",
      "register": "잭커리 ID를 만들고 잭커리 회원이 됩니다.",
      "login": "고객으로 로그인하기",
      "reset": "재설정",
      "dashboard": "대시보드",
      "commonSetting": "일반 설정",
      "account": "잭커리 ID 계정 센터",
      "accountOverview": "개요",
      "myProfile": "나의 프로필",
      "myOrders": "나의 주문",
      "personalSettings": "개인 설정",
      "addressBook": "배송지 목록",
      "accountSettings": "계정 설정",
      "myDevices": "계정 장치",
      "activate": "비밀번호를 만들고 계정을 활성화하세요.",
      "registerActivate": "계정을 활성화하려면 아래를 클릭하세요. 활성화되면 이 계정을 사용하여 웹 사이트에 로그인할 수 있습니다.",
      "myReturn": "나의 반품",
      "myTradeIn": "나의 수상 판매",
      "myJackeryLife": "나의 잭커리 라이프",
      "myWishlist": "나의 위시리스트",
      "myService": "나의 서비스",
      "myWallet": "나의 지갑",
      "myReferral": "My Referral",
    },
    "operate": {
      "enable": "가능하게 됨",
      "disable": "손상됨",
      "confirm": "컴펌",
      "cancel": "취소",
      "remove": "제거하기",
      "search": "검색",
      "reset": "재설정",
      "add": "추가",
      "close": "닫기",
      "edit": "편집",
      "yes": "네",
      "no": "아니요.",
      "next": "다음",
      "prev": "이전",
      "reject": "거부하기",
      "view": "의견",
      "submit": "제출",
      "accept": "수용",
      "back": "뒤돌아가기",
    },
    "tips": {
      "sure": "확실합니까?",
      "placeholder": "입력하세요.",
      "enterConsultContent": "조회 내용을 입력하십시오.",
      "all": "전체",
      "custom": "풍속",
      "pending": "대기",
      "loading": "처리 중...",
      "loading2": "처리 중",
      "cancelled": "취소됨",
      "loaded": "프로세스 완료되었습니다.",
      "loaded2": "완료됨",
      "changeShop": "스토어 전환",
      "loggedOut": "로그아웃했습니다.",
      "refreshed": "새로 고침",
      "noDescribe": "설명 없음",
      "reminder": "힌트",
      "commError": "문제가 발생했습니다.",
      "reLogin": "다시 로그인하세요.",
      "verify": "인증 번호",
      "getVerifyCode": "인증 번호 받기",
      "verifyError": "인증 번호가 올바르지 않습니다.",
      "homePage": "홈페이지",
      "404": "이 페이지는 존재하지 않습니다.",
      "404Tips": "서버 IP 주소가 올바른지 확인하거나 계속 액세스 링크를 클릭하십시오.",
      "500": "앗! 문제가 생겼습니다.",
      "500Tips": "서버 오류",
      "500Tips2": "나중에 다시 시도해 주세요.",
      "urlExpired": "겅즘된 URL이 만료되었습니다.",
      "accountNotActivated": "계정이 활성화되지 않았습니다.",
      "accountActivatedSuccess": "계정이 성공적으로 활성화되었습니다.",
      "requestFrequently": "빈번히 하지 말고 확인 후 다시 시도하십시오.",
      "submitSuccess": "제출되었습니다.",
      "zipVerifyError": "우편번호 인증이 실패했습니다.",
      "otherError": "문제가 있으시면 관리자에게 문의하십시오.코드: {code}",
      "wrongPasswordMultipleTimes": "잘못된 비밀번호를 여러 번 입력했습니다. 다시 시도하십시오.{minute}분 후에",
      "networkError": "네트워크 오류",
      "thirdLoginError": "로그인 실패했습니다. 계정을 사용하여 다시 시도하십시오.",
      "fileTypeError": "파일 형식 오류, 업로드만 가능: {type} 형식 파일",
      "logoutConfirm": "로그아웃하시겠습니까?",
      "sendVerify": "인증번호가 발송되었습니다. 받은 편지함이나 스팸메일을 확인하십시오.",
      "copySuccessfully": "복제 성공",
    },
    "placeholder": {
      "email": "이메일 주소",
      "emailOrJackeryID": "이메일 주소/잭커리 ID*",
      "password": "비밀번호",
      "passwordAgain": "새 비밀번호 확인",
      "enterprise": "기업명",
      "code": "코드",
      "firstName": "이름",
      "lastName": "성",
      "select": "선택하세요.",
      "address1": "거리와 전화번호",
      "address2": "아파트, 스위트룸 등 (옵션)",
      "country": "국가/지역",
      "province": "시 / 주 / 지역",
      "city": "도시",
      "region": "지역",
      "zip": "우편번호",
      "zipError": "ZIP가 잘못되었습니다.",
      "phone": "너의 핸드폰",
      "company": "회사명(옵션)",
      "isNotEmpty": "이 옵션은 비워둘 수 없습니다.",
      "pobox": "우편함으로 발송하지 않습니다",
      "minChar2": "최소 2자",
      "inputChar": "문자 길이가 {length} 사이에 있여야 합니다."
    },
    "order": {
      "sn": "주문번호",
      "detail": "배송 상세 정보 보기",
      "productName": "제품명",
      "quantity": "수량",
      "price": "가격",
      "status": "주문 상태",
      "consult": "문의하기",
      "contact": "연락하기",
      "total": "합계",
      "empty": "주문 정보 없습니다.",
      "questionType": "문제 유형",
      "shipped": "배송됨",
      "return": "반품",
      "applied": "반품 및 환불",
      "revoke": "취소",
      "serviceType": "서비스 유형을 선택하십시오.",
      "serviceType_1": "반품 및 환불",
      "serviceType_2": "교환",
      "packageArrived": "이 제품 받으셨나요?",
      "reason": "이유를 입력하십시오.",
      "reason_1": "{day}-day문제 없음 반환",
      "reason_2": "품질 문제",
      "reason_3": "기타",
      "quality": "구체적인 품질 문제를 입력하십시오.",
      "productSerialNumber": "제품 일련 번호를 입력하십시오.",
      "selectReturnProduct": "반품 제품을 선택하십시오.",
      "returnQuantity": "반품 / 교환 수량을 입력하십시오.",
      "shippingAddress": "배송지",
      "returnAddress": "반품 주소",
      "revise": "편집",
      "uploadVideo": "비디오 / 이미지 업로드하기",
      "uploadLoading": "업로드 중",
      "videoLimit": "파일 크기는 xxxM 초과할 수 없습니다！",
      "uploadVideoError": "파일 업로드 실패했습니다.",
      "failureDetermination": "제품 품질 문제 해결",
      "steps_1": "1. 파워뱅크 문제 해결 절차",
      "steps_1_content": "1) LCD화면 스위치를 눌러 표시등이 켜져 있는지 확인하세요.\n\n2) 파워뱅크를 재설정하고 한 기간 동안 두어 놓은 후 충전하세요.\n\n-- 발전소가 E160/E240/E500/E1000이면 DISPLAY 버튼 10S를 누른 채\n\n-- 전원 공급 장치가 E1500/E2000이면 DISPLAY+USB 10S를 누른 채\n\n3) 장치를 꽂고 충전하기 전에 AC/DC 소켓 상단의 버튼을 눌러 불이 켜져 있는지 확인합니다.\n\n4) 기기를 다시 충전할 수 있는지 확인하십시오.\n\n\n\n위의 단계가 작동하지 않으면 발전소에 장애가 발생합니다.",
      "steps_2": "2. 태양 패널 문제 해결 절차",
      "steps_2_content": "1) 제공된 AC 충전기를 사용하여 파워뱅크를 충전하고 작업 가능한지 확인하십시오.\n\n2) 한낮의 강렬한 햇빛 아래서 테스트하십시오. 태양광 패널이 파워뱅크와 연결해 태양광 패널에 그림자가 없도록 하십시오.\n\n\n\n파워뱅크가 AC 충전기로 잘 충전할 수 있는데 태양광 패널과 함께 작업하지 않는다면 결함이 있습니다.",
      "learnMore": "더보기",
      "shrink": "축소",
      "noReason_30_day": "1. 잭커리는 공식 사이트에서 직접 구매한 상품에 대해 제품 수령 후 7일 내 환불 보증을 제공합니다. 잭커리 신제품을 좋아하실 것으로 믿습니다.그러나 어떤 이유로든 만족스럽지 못하면 제품 수령 후 7일 이내에 반품 또는 환불을 요청하시면 됩니다.\n\n\n\n2. 참고: 제품 불량, 하자 등 문제가 있는 한에서 고객 센터를 통하여 30일 이내에 반품 가능합니다. \n\n\n\n3. 반품 정책에 따라 반품하기 위한 검사: 제품이 원본 포장으로 반품되고 모든 재료를 포함하여 새로운 상태로 반품되는지 확인하십시오.",
      "selectDelivery": "반품 물류를 선택하십시오.",
      "enterTrackNumber": "추적 번호를 입력하세요.",
      "illegal_trackNumber": "올바른 추적 번호를 입력하십시오.",
      "enterDescription": "구체적인 설명을 입력하십시오.",
      "pointsBalance": "포인트 잔액",
      "comingSoonTip": "(잭커리 포인트는 제품 발송 후 31일에 지급할 예정입니다.)",
      "points": "포인트",
      "shopNow": "지금 쇼핑하기",
      "comingSoon": "Coming Soon",
      "tipTitle": "맴버십 혜택",
      "tip1": "① 잭커리 공식 웹 사이트에서 <a>에서 </a>를 주문하면 보증 서비스 기간 자동 연장(최장 5년) 및 잭커리 포인트(1달러 지출 = 1점)를 받을 수 있습니다.",
      "tip2": "② 비공식 채널에서 구입한 제품을 <a>로 등록하면 보증 서비스 기간 (최대 5년) 이 연장됩니다.",
      "tip3": "③ <a>를 통해 등록한 후 잭커리 공식 웹사이트에서 {num}</a>를 주문하면 무료 선물을 한 번에 받을 수 있습니다.",

    },
    "bank": {
      "name": "은행명",
      "branchName": "지점명",
      "type": "은행카드 유형",
      "account": "은행카드 번호",
      "realName": "카드 소지자 이름"
    },
    "return": {
      "orderNumber": "주문번호",
      "status": "반품 / 교환 상태",
      "attachment": "연결",
      "confirmRevoke": "반품 / 교환 신청을 취소하시겠습니까?",
      "returnPolicy_link": "https://kr.jackery.com/support/returns",
      "returnPolicy_1": "수리, 반품, 교환 및 교환 신청에 대한 FAQ는 다음을 참조하십시오.",
      "returnPolicy_2": "애프터 서비스 정책",
      "returnPolicy_3": "질문이 있으시면 문의하세요.",
      "returnPolicy_4": "support{'@'}jackery.com",
      "returnPolicy_5": "",
    },
    "devices": {
      "empty": "장치 정보 없습니다.",
      "more": "더 보기",
      "extendedWarrantyDeadline": "보증 서비스 만료일",
      "warrantyStatus": "보증 서비스 연장 상태 확인",
      "existWarranty": "적극적",
      "nonExistentWarranty": "보증 서비서 기간 연장 안 함",
      "userManual": "사용자 매뉴얼",
      "warrantyInfo": "‘제출’을 클릭하여 보증 서비스 연장을 활성화하세요.",
      "newsletter": "저희의 시사 통신을 구독하세요.",
      "warrantySuccess": "활동적인",
      "download": "다운로드",
      "none": "아무것도 없습니다.",
      "addNewDevice": "새 장치 추가",
      "shopNow": "지금 쇼핑하기",
    },
    "address": {
      "empty": "주소가 저장되지 않았습니다.",
      "add": "새로운 주소 추가하기",
      "edit": "주소 편집",
      "userName": "사용자 이름",
      "address1": "주소1",
      "address2": "주소2",
      "country": "국가/지역",
      "country_code": "국가 / 우편번호",
      "province": "시 / 주 / 지역",
      "province_code": "시 / 주 / 우편번호",
      "city": "도시",
      "zip": "우편번호",
      "phone": "휴대폰번호",
      "company": "회사",
      "default": "기본 배송 주소로 설정",
      "isDefault": "기본 배송지",
      "error": "배송 주소가 이상하거나 정보가 완전하지 않으니 확인하시기 바랍니다",
    },
    "character": {
      "comma": ","
    },
    "msg": {
      "success": "성공"
    },
    "user": {
      "log": {
        "expire": "다시 로그인하십시오."
      }
    },
    "message": {
      "order_status_shipping": "배송됨",
      "order_status_completed": "완료되었습니다.",
      "order_status_processing": "처리 중",
      "order_status_archived": "배송됨",
      "order_status_canceled": "취소됨",
      "order_status_unpaid": "미지불",
      "device_not_found": "장치를 찾을 수 없습니다.",
      "device_disable_can_not_set_warranty": "현재 장치가 유효하지 않아 보증 서비스 연장을 설정할 수 없습니다.",
      "device_was_warranty": "이 장치의 보증 기간이 연장되었습니다.",
      "device_not_support_warranty": "보증 서비스 연장은 현재 장치에서 지원되지 않습니다.",
    },
    "create": {
      "address": {
        "fail": "주소 추가 실패"
      }
    },
    "activation": {
      "email": {
        "expire": "무효한 이메일 주소"
      }
    },
    "select_country": {
      "title": "국가/지역 선택하기"
    },



    // 会员首页 多语言
    "member_index": {
      "common": {
        "register": "가입하기",
        "already_a_member": "벌써 회원이 되셨나요?",
        "login": "로그인",
        "points": "포인트",
        "redeem": "환매",
        "rules": "규칙",
        "copy_successfully": "복제 성공",
        "outOfStock": "품절",
      },
      "banner": {
        "title": "잭커리 맴버십",
        "description": "잭커리 포인트 얻기, 특정 혜택을 교화하기!<br/>잭커리와 함께 의미 있는 일을 하세요.",
        "coming_soon": "출시 예정",
        "my_rewards": "나의 보상",
        "my_activity": "나의 활동",
        "unused": "사용되지 않음",
        "to_be_redeemed": "환매 대기",
        "no_unused_rewards": "사용 가능한 보상이 없습니다",
        "no_convertible_rewards": "교환 가능한 상품이 없습니다.",
        "time": "시간",
        "points": "포인트",
        "no_activity_records": "현재 포인트 이벤트 기록이 없습니다.",
        "points_balance": "포인트 잔액",
        "action": "행동",
      },
      "section_tabs": {
        "membership_benefits": "맴버십 혜택",
        "redeem_points": "포인트 교환",
        "earn_points": "포인트 획득",
        "guide": "지시",
        "faq": "FAQ",
      },
      "membership_benefits": {
        "title": "잭커리 맴버십 독점 혜택",
        "banner_text": "구매를 통해 잭커리 포인트를 획득하고 독점 보상으로 교환합니다.",
        "coupon_benefit": "최초 등록 시 바로 혜택을 받을 수 있습니다.",
        "giftcard_benefit": "* {num} 이상의 주문은 한 번 무료 선물을 받을 수 있습니다.",
        "product_benefit": "정기적으로 제품 관리 알림을 받습니다.",
        "gift_benefit": "보증 서비스 기간 만료 시 15% 할인",

      },
      "redeem_points": {
        "title": "<br/>포인트를 교환하여 보상을 획득",
        "category_tabs": {
          "coupon": "쿠폰",
          "gift_card": "선물 카드",
          "jackery_products": "잭커리 제품",
          "virtualCard": "엑스트라"
        },
        "code": "코드",
        "successfully_redeemed": "교환 성공되었습니다.",
        "copy_and_use": "복제 및 사용",
        "pointTips": "잭커리 포인트 {0}개를 가지고 있습니다.",
        "hour": "시간",
        "seconds": "초",
        "redeem_confirm_title": "{0}점을 사용하겠습니다. 처리 후 환불 불가합니다.",
        "redeem_confirm_content": "교환하시겠습니까?",
        "information_tips": "선물 카드에 대한 정보가 이미 당신의 우편함으로 발송되었으니 확인해 주십시오.",
        "day": "일",
        "minute": "최소",
        "count_down_info": "이벤트 이미 종료했으니 보상을 교환할 수 없습니다. 페이지를 새로 고치십시오.",
        "unusedTips": "만료 전에 결제 시 사용하세요!"
      },
      "earn_points": {
        "title": "포인트 획득 방법",
        "go_now": "바로 가기",
        "points_earned": "획득한 포인트",
        "membership_registration": "회원 등록",
        "membership_registration_desc": "가입 시 잭커리 포인트 ({0}점)  획득.",
        "email_subscribtion": "이메일 구독하기",
        "email_subscribtion_desc": "이메일을 통해 잭커리에 가입하면 잭커리 포인트 0 점을 받을 수 있습니다.",
        "sms_subscribtion": "문자 구독하기",
        "sms_subscribtion_desc": "문자로 잭커리를 구독하면 잭커리 포인트 0개를 받으실 수 있습니다.",
        "fill_in_your_birthday": "생일을 기입하세요.",
        "fill_in_your_birthday_desc": "생일을 기입하여 {0}점<Br/> 각 생일에 1 포인트 포인트를 획득하고 두 배의 쇼핑 포인트를 획득합니다.",
        "extend_your_warranty": "보증 서비스를 연장하기",
        "extend_your_warranty_desc": `
        Get {0} Points by registering products on Jackery's official website per time.
        <br/>
        (For customers purchased from third-party channels.)
      `,
        "shop_and_earn": "쇼핑 및 획득",
        "shop_and_earn_desc": "쇼핑, 1달러 소비 = 포인트 1 획득 <br/>{1}개 주문 획득 {0}포인트 획득 <br/>{3}개 이상 주문 획득 {2}포인트 획득",
        "tip": "다중 사이트 구독은 하나의 구독 점수로 간주됩니다."
      },
      "guide": {
        "title": "작업 원리",
        "step_title_1": "1. 등록",
        "step_content_1": "잭커리 계정을 만들고 독점 회원 혜택을 받으십시오.",
        "step_title_2": "2. 상금 획득",
        "step_content_2": "포인트 획득 임무 완료 (예: 개인 정보 작성, 제품 구매, 이벤트 참가 등)",
        "step_title_3": "3. 보상 획득",
        "step_content_3": "잭커리 포인트로 독점 혜택(예: 쿠폰, 선물 카드 등)을 교환하십시오.",
        "learn_more": "더 알아보기",

      },
      "register_guide": {
        "title": "<br/>잭커리 회원 보상은 어떻게 받을 수 있습니까?",
        "step_1_title": "단계 1 - 가입 및 로그인",
        "step_1_subtitle_1": "가입",
        "step_1_subtitle_2": "로그인",
        "step_1_content_1": `① Go to the <a class="text-primary hover:text-primary" href="https://account.jackery.com/register?CR=US" target="_blank">Registration Page</a> or choose "Create an account" on <a class="text-primary hover:text-primary" href="https://account.jackery.com?CR=US" target="_blank">account.jackery.com</a>`,
        "step_1_content_2": "② 이메일 주소와 비밀번호를 입력하세요.",
        "step_1_content_3": "③ 새 비밀번호를 확인하고 이메일로 받은 인증번호를 입력하십시오.",
        "step_1_content_4": "④ \"잭커리의 개인 정보 보호 정책 및 서비스 약관에 동의합니다.\" 및 \"잭커리의 최신 뉴스, 거래, 프로모션 및 제품 정보를 수신하는 데 동의합니다.\" 잭커리의 개인 정보 보호 정책 및 서비스 조건을 읽으십시오.",
        "step_1_content_5": "⑤ 제출을 클릭하여 등록 완료하세요. <br/>(이미 등록한 경우 이 단계를 건너뛰거나 로그인하세요.)",
        "step_1_content_6": "① 3가지 로그인 방법:",
        "step_1_content_7": "로그인 페이지 가기",
        "step_1_content_8": "지금 로그인하기' 선택하세요. on <a class=\"text-primary hover:text-primary\" href=\"https://account.jackery.com/register?CR=US\" target=\"_blank\">account.jackery.com</a>",
        "step_1_content_9": "홈 페이지의 오른쪽 위에 있는 '계정' 아이콘 을 클릭합니다.",
        "step_1_content_10": "② 이메일 주소와 비밀번호를 입력하세요.",
        "step_1_content_11": "③ 로그인을 클릭하여 계정 센터로 이동합니다.",
        "step_1_img_1_pc": "https://cdn.shopify.com/s/files/1/0970/9262/files/1_60d266f7-af07-44b1-b0ef-2c0bb3e08911.png?v=1690164040",
        "step_1_img_2_pc": "https://cdn.shopify.com/s/files/1/0970/9262/files/2_01f507b6-4461-486a-8232-c45af21f6a7d.png?v=1690164040",
        "step_1_img_3_pc": "https://cdn.shopify.com/s/files/1/0970/9262/files/3_3e74281b-ba09-4b9d-8965-9f44e6c33fff.png?v=1690164040",
        "step_1_img_4_pc": "https://cdn.shopify.com/s/files/1/0970/9262/files/4_9d354d73-3eda-4047-9fc0-f3058358e781.png?v=1690164040",
        "step_1_img_5_pc": "https://cdn.shopify.com/s/files/1/0970/9262/files/5_f686accd-391f-4326-b586-6fb03b2579a7.png?v=1690164040",
        "step_1_img_6_pc": "https://cdn.shopify.com/s/files/1/0970/9262/files/6.1.png?v=1690164040",
        "step_1_img_1_mb": "https://cdn.shopify.com/s/files/1/0970/9262/files/1_db7869b1-77ec-44e4-a6e7-7f0ff17af3bd.png?v=1690164268",
        "step_1_img_2_mb": "https://cdn.shopify.com/s/files/1/0970/9262/files/2_3eb7d0c3-0a7b-47f2-b63a-741896c51177.png?v=1690164269",
        "step_1_img_3_mb": "https://cdn.shopify.com/s/files/1/0970/9262/files/3.1.png?v=1690164268",
        "step_1_img_4_mb": "https://cdn.shopify.com/s/files/1/0970/9262/files/3.2.png?v=1690164268",
        "step_1_img_5_mb": "https://cdn.shopify.com/s/files/1/0970/9262/files/3.3.png?v=1690164268",
        "step_1_img_6_mb": "https://cdn.shopify.com/s/files/1/0970/9262/files/3.4.png?v=1690164268",

        "step_2_title": "단계2 - 잭커리 포인트 획득",
        "step_2_content_1": "Check \"포인트를 획득하는 방법\" on <a class=\"text-primary hover:text-primary\" href=\"https://membership.jackery.com?CR=US\" target=\"_blank\">Membership page</a> 및 잭커리 포인트를 획득하는 방법을 배웁니다.",
        "step_2_img_1_pc": "https://cdn.shopify.com/s/files/1/0970/9262/files/6.2.png?v=1690164038",
        "step_2_img_1_mb": "https://cdn.shopify.com/s/files/1/0970/9262/files/3.5.png?v=1690164268",

        "step_3_title": "단계 3 - 잭커리 포인트 체크하기",
        "step_3_content_1": "계정에 로그인하면 <a class=\"text primary hover:text primary\"href=\"https://account.jackery.com/account/overview?CR=US\"target=\"_blank\"> 계정 센터 </a> 또는 <a class=\" text primary hover: text primary \"href=\"https://membership.jackery.com?CR=US\"target=\"_blank\"> 회원 페이지 </a>.",
        "step_3_content_2": "① 계좌센터: \"개요\", \"나의 프로필\", \"나의 지갑\" 등 세 가지 항목에서 포인트를 확인할 수 있습니다.",
        "step_3_content_3": "② 포인트 잔액을 클릭하면 <a class=\"text primary hover:text primary\" href=\"https://membership.jackery.com?CR=US\"target=\"_blank\"> 회원 페이지 </a> 에서 포인트 잔액을 볼 수도 있습니다.",
        "step_3_content_4": "포인트는 두 가지 유형이 있습니다. 대출 적분과 처리 대기 적분입니다.",
        "step_3_content_5": "포인트는 포인트 잔액에 표시되며 사용할 수 있습니다.",
        "step_3_content_6": "보류 중인 포인트는 'Coming Soon' 섹션에 표시되며 환전에 사용할 수 없습니다.",
        "step_3_content_7": "그러나 'Coming Soon' 섹션의 포인트는 지정된 날짜에 포인트 잔액으로 이동됩니다.",
        "step_3_content_8": "예를 들어, 상품이 배송될 때 구매에서 얻은 쇼핑 포인트는 'Coming soon' 섹션에 표시됩니다.30일이 지나면 이 포인트는 \"포인트 잔액\" 으로 이전됩니다.",
        "step_3_img_1_pc": "https://cdn.shopify.com/s/files/1/0970/9262/files/7_a691e69f-2114-4751-a9da-410cbdfa6bff.png?v=1690164038",
        "step_3_img_2_pc": "https://cdn.shopify.com/s/files/1/0970/9262/files/8_75e962a6-6192-44d8-bbb6-fa9455fff71a.png?v=1690164038",
        "step_3_img_3_pc": "https://cdn.shopify.com/s/files/1/0970/9262/files/9_fe3c99c0-256e-4757-a47a-c3a1ebe11d7d.png?v=1690164039",
        "step_3_img_4_pc": "https://cdn.shopify.com/s/files/1/0970/9262/files/10_078f419b-e804-4db4-9f79-b67ea82221e1.png?v=1690164039",
        "step_3_img_1_mb": "https://cdn.shopify.com/s/files/1/0970/9262/files/4.1.png?v=1690164270",
        "step_3_img_2_mb": "https://cdn.shopify.com/s/files/1/0970/9262/files/4.2.png?v=1690164269",
        "step_3_img_3_mb": "https://cdn.shopify.com/s/files/1/0970/9262/files/4.3.png?v=1690164269",
        "step_3_img_4_mb": "https://cdn.shopify.com/s/files/1/0970/9262/files/4.3.png?v=1690164269",

        "step_4_title": "4단계 - Jackery 포인트 교환",
        "step_4_content_1": "맴버십 페이지를 로그인하여",
        "step_4_content_2": "획득할 수 있는 보상은 강조 표시됩니다. 이 보상은 현재 포인트 잔액으로 교환할 수 있습니다.",
        "step_4_content_3": "② 원하는 보상을 선택하여 '교환'을 클릭하고 팝업 창에서 '확인'을 클릭하면 보상을 받을 수 있습니다.",
        "step_4_img_1_pc": "https://cdn.shopify.com/s/files/1/0970/9262/files/11_b1b5ad1b-0399-4c09-a4b0-c9cc7b79bdc9.png?v=1690164039",
        "step_4_img_2_pc": "https://cdn.shopify.com/s/files/1/0970/9262/files/12_8f7f3a10-45b3-4ded-a5ba-187a4895b62e.png?v=1690164039",
        "step_4_img_3_pc": "https://cdn.shopify.com/s/files/1/0970/9262/files/13_5dc9f08a-292c-476e-8ac9-e2d38ddf9011.png?v=1690164038",
        "step_4_img_4_pc": "https://cdn.shopify.com/s/files/1/0970/9262/files/14_6f22c90f-6f4b-4830-b3c4-c58ce12df6be.png?v=1690164038",
        "step_4_img_1_mb": "https://cdn.shopify.com/s/files/1/0970/9262/files/6.1_da837084-29b8-4e38-9a54-1ede6ee6d632.png?v=1690164270",
        "step_4_img_2_mb": "https://cdn.shopify.com/s/files/1/0970/9262/files/6.2_52b94a46-5597-4a97-a93f-03a61a07c9f4.png?v=1690164269",
        "step_4_img_3_mb": "https://cdn.shopify.com/s/files/1/0970/9262/files/6.3.png?v=1690164270",
        "step_4_img_4_mb": "https://cdn.shopify.com/s/files/1/0970/9262/files/7_8346db84-0340-461f-add6-63bef81cdbaf.png?v=1690164269",

        "step_5_title": "단계 5 - 사용 보상",
        "step_5_content_1": "잭커리 포인트로 쿠폰이나 선물 카드를 교환하고 \"복사 및 사용\"을 클릭하면 코드가 복사됩니다. 잭커리의 공식 웹 사이트에서 쇼핑하고 결제 시 이 코드를 사용하면 구매 시 더 많은 비용을 절약할 수 있습니다.",
        "step_5_content_2": "잭커리 포인트로 잭커리 제품을 교환하는 경우 결제 시 할인 코드를 붙여 무료로 제품을 받을 수 있습니다.",
        "step_5_img_1_pc": "https://cdn.shopify.com/s/files/1/0970/9262/files/15_5d2705c5-ac91-4321-bec9-d40f1f0d817c.png?v=1690164038",
        "step_5_img_2_pc": "https://cdn.shopify.com/s/files/1/0970/9262/files/16_b5979697-2c76-425e-b458-b59193916cb0.png?v=1690164038",
        "step_5_img_1_mb": "https://cdn.shopify.com/s/files/1/0970/9262/files/8_1f3437d6-ff4e-4eb3-8f97-4d7fd602b449.png?v=1690164270",
        "step_5_img_2_mb": "https://cdn.shopify.com/s/files/1/0970/9262/files/9_97bb5f6e-716a-402b-9748-7742fd56fa9d.png?v=1690164270",
      },
      "faq": {
        "title": "FAQ",
        "membership": {
          "title": "잭커리 맴버십",
          "question_1": "1.잭커리 멤버십이란 무엇입니까?",
          "answer_1": "잭커리 맴버십은 고객님이 <a class=\"textprimaryhover:textprimary\" href=\"에서 사용할 수 있는 포인트 기반 프로그램입니다.https://jackery.com\"> jackery.com </a>. 고객님의 포인트는 보상으로 바꿀 수 있습니다. 저희에게 가입하는 것은 완전히 무료입니다. 이것은 저희가 당신에게 감사를 드리는 방식이자 충성스러운 감사를 드립니다.",
          "question_2": "2.잭커리 회원 어떻게 가입합니까?",
          "answer_2": `
          You need to register a Jackery account to become one of Jackery members.
          <br/>
          If you do not have one, register now. Here is the <a class="text-primary hover:text-primary" href="/index/guide" target="_blank">step-by-step guide</a> if you need to learn how.
          <br/>
          If you already have an account on Jackery official website, you can activate or login to your existing account.
        `,
          "question_3": "3.이미 가입한 경우 잭커리의 다른 웹 사이트에서 다시 가입해야 하나요?",
          "answer_3": "사용자가 등록하면 해당 계정은 모든 공식 잭커리 웹 사이트와 호환됩니다.",
          "question_4": "잭커리 계정이 여러 개가 있으면 하나로 통합할 수 있습니까?",
          "answer_4": "다른 이메일 주소로 가입하면 잭커리 ID가 다르므로 병합할 수 없습니다.",
          "question_5": "5.잭커리 멤버십은 어떻게 취소합니까?",
          "answer_5": `
          Contact our customer service team to cancel your account. Points will be reset to zero.
          <br/>
          Logged out users can become Jackery members again through the registration process. Points will be recalculate.
          <br/>
          If you wish to re-register as a member, to ensure successful login, please use a different email account for registration.
        `,
        },
        "earn_points": {
          "title": "포인트 획득",
          "question_1": "1. 포인트를 어떻게 획득합니까?",
          "answer_1": `
          When you became a member of Jackery, you can earn points by completing specified tasks on the official website. Here are some notes for you.
          <br/>
          <br/>
          ① Membership Registration: If you registered before June 2023, you need to activate your membership by logging in and verifing your account. You can earn points for your first registration or activation.
          <br/>
          ② Email Subscription: You can earn points by subscribing to Email for once. No points for resubscribing after unsubscribing. Multi-site subscriptions only count as one subscription score.
          <br/>
          ③ SMS Subscription: You can earn points by subscribing to SMS for once. You need to enter your phone number in 'Account Center - My Profile' or on the page of 'Extend your warranty' , but not only subscribe from the footer of the website. Multi-site subscriptions only count as one subscription score.
          <br/>
          ④ Fill in your birthday: You can earn points by filling in your birthday. Get points on each birthday and Get shopping points double. Your birthday can only be modified once.
          <br/>
          ⑤ Extend your warranty: Only for users who purchase from third-party platforms such as Amazon, Walmart, etc. Once you extend product warranty from the official website successfully, points will be issued according to the rules.
          <br/>
          ⑥ Shopping points: After purchasing products on Jackery official website, you can get points based on the total value of orders. In addition, the {0} order or the purchase value reaching {1} allow you to get extra points respectively.
          <br/>
          ⑦ Joining Extra Points Activities: You can get points by participating in Extra Points Activities on Jackery official website. For details, please refer to the activity description on the page of Jackery membership.
        `,
          "question_2": "2.포인트는 언제 계좌에 기입합니까?",
          "answer_2": `
          There are two types of points arrival times:
          <br/>
          <br/>
          ① Fast issuance: After you complete the task specified on the official website, such as registration, subscription, filling in Birthday and warranty extention, the points will be issued to your account within 24 hours.
          <br/>
          ② Delayed issuance: Shopping related points will be issued as ‘coming soon points' after the goods are shipped. 30 days later, they will become active as 'points balance' (When an order includes multiple products, the date that the first product is dispatched shall prevail). The amount of points you receive for a qualifying order is based on its actual total payment.
          <br/>
          <br/>
          It should be noted that pending points cannot be used to redeem official website benefits.
        `,
          "question_3": "3.만약 포인트가 나의 계좌에 정확하게 기입되지 않았다면 어떻게 해야 합니까?",
          "answer_3": "회원 계정 및 포인트 잔액에 대해 궁금한 점이 있으시면 <a class=\"text primary hover:text primary\" href=\"mailto:hello{'@'}jackery.com\">hello{'@@'}jackery.com</a>",
          "question_4": "4.포인트가 만료되었습니까?",
          "answer_4": `
          Jackery points are valid for two years. Points earned by users will expire in 2 years from the official launch of the Membership Program（June 29th 2023), and expired points will be deducted from their account.
          <br/>
          In order to protect your rights and interests, please check and redeem your available points in time.
        `,
          "question_5": "5. 잭커리 회원 프로그램이 출시되기 전에 진행된 거래에서 포인트를 받을 수 있습니까?",
          "answer_5": "잭커리 멤버십은 2023년 6월 29일에 정식 출시되며, 잭커리 회원으로 가입하면 잭커리 공식 웹사이트에서 쇼핑하거나 지정된 작업을 완료하고 해당 원래 포인트(2020년 1월 1일부터)를 받게 됩니다. 원본 포인트는 2년 후(2023년 6월 29일부터) 만료된다.",
          "question_6": "6.세금이나 배송비는 포인트로 계산할 수 있습니까?",
          "answer_6": "아니요. 마일리지 계산에는 회원이 지불하는 택배비와 모든 세금이 포함되지 않으며 연방, 주, 지방세 또는 사용세를 포함하되 이에 국한되지는 않습니다.",
          "question_7": "7. 주문을 취소하거나 반품하거나 부분 환불을 신청하면 제 포인트는 어떻게 됩니까?",
          "answer_7": "취소, 환불 또는 반품된 모든 주문은 해당 구매에서 획득한 포인트를 취소하거나 감소시킵니다. 가격 조정으로 인한 일부 환불은 감점됩니다.",
          "question_8": "8. 사이트 간 포인트 / 환율 / 포인트 표시 방법에 대한 특별 지침",
          "answer_8": `
          Jackery Points can be earned and used on all of Jackery official websites.
          <br/>
          If you have point balances on Jackery's different official websites, the system will display the total point value after converting the balances at different sites through exchange rates.
          <br/>
          The total number of points will be converted based on the latest US dollar exchange rate on the 1st of each month, so the point balance will change according to the exchange rate every month.
          <br/>
          Points are in integer form, fractional parts will be counted in the point value, but will not be displayed and cannot be redeemed.
        `,
        },
        "redeem": {
          "title": "포인트 교환",
          "setting1": "사용자는 과거 {num}일 동안 최대 {time}회까지 교환할 수 있습니다.",
          "setting2": "사용자는'{time}'번만 교환할 수 있습니다.",
          "setting3": "사용자는 충분한 포인트 잔액만 있으면 복지를 바꿀 수 있습니다.",
          "question_1": "1. 잭커리 포인트를 어떻게 교환하여 혜택을 받습니까?",
          "answer_1": `
          You can login at Member homepage (<a class="text-primary hover:text-primary" href="https://membership.jackery.com?CR=US">membership.jackery.com</a>). The benefits that can be redeemed will be highlighted. You can select the benefits and click "Redeem".
          <br/>
          If you login to your account center, you can click the 'Points balance' to jump to the Member homepage to redeem your points for benefits.
          <br/>
          Points are not refundable once they have been redeemed. Please make sure you want to redeem before processing.
        `,
          "question_2": "2. 잭커리 포인트는 어떤 혜택을 교환할 수 있습니까?",
          "answer_2": `
          We provides you a wealth of benefits. Points can be redeemed for coupons, gift cards, Jackery product, interesting gift and more. The benefits are only valid to the site where it was collected or redeemed and cannot be used across sites. Please use the rewards within the validity period. Refer to the member homepage for details. Users can redeem rewards a limited number of times within a certain period; you will receive a system alert when the redemption limit has been reached. Once you enter a new cycle, you can redeem again.
          <br/>
          <br/>
          Notes
          <br/>
          ① Jackery coupon: Each coupon has a specific discount, minimum order amount, applicable products, and validity period. Please check the detailed information of each coupon when using it. Coupons cannot be combined or stacked with any other offers including sales, coupons, etc. {0}
          <br/>
          ② Jackery gift card: Each gift card has a specific denomination and validity period. Please check for more information of each gift card when using it. {1}
          <br/>
          ③Jackery Product：The offer cannot be combined or stacked with any other promotions, including sales and coupons. To claim the offer, enter the code and corresponding email address at checkout. Please remember that each Jackery Product coupon can only be used to purchase one specific item. {2}
          <br/>
          ④ Non-Jackery gift cards: There are a limited number of non-Jackery gift cards available. When the total inventory has been claimed, you may receive an "out of stock" message and will not be able to redeem your points. Non-Jackery gift cards will not display when they are out of stock. For details on the usage rules of non-Jackery gift cards, please refer to the corresponding brand’s official website. {3}
        `,
          "question_3": "3. 환전한 혜택을 어떻게 볼 수 있습니까?",
          "answer_3": `
          ① Login to the account center (<a class="text-primary hover:text-primary" href="https://account.jackery.com?CR=US" target="_blank">account.jackery.com</a>) , check it in 'My Wallet'. Or login to the member homepage (<a class="text-primary hover:text-primary" href="https://membership.jackery.com?CR=US">membership.jackery.com</a>), check it in 'My Rewards'.
          <br/>
          ② You will receive emails for rewards collection notice, including coupons, gift cards, Jackery products, etc.
        `,
          "question_4": "4.나의 보상, 혜택 및 포인트를 현금으로 바꿀 수 있습니까?",
          "answer_4": `
          Rewards, benefits, and points earned through Jackery Membership Program have no cash value, cannot be converted into cash and cannot be transferred to someone else (Except for the gift card which you can send to your friends or family members.).
          <br/>
          It is not allowed to sell the benefits you redeemed to a third party.
          <br/>
          Jackery has the right to take legal action against unauthorized transactions.
        `,
          "question_5": "5. 교환한 제품에 대해 배송비를 지불해야 합니까?",
          "answer_5": "대부분의 경우 저희가 교환된 제품에 대해 무료 배송 서비스를 제공해 드립니다.그러나 특정 제품에 대한 배송비를 지불해야 할 수도 있습니다.",
          "question_6": "6.포인트 교환의 보상을 취소, 반환 또는 교환 할 수 있습니까?",
          "answer_6": `
          Once the redemption is completed, it cannot be canceled. The rewards cannot be returned or exchanged.
          <br/>
          If the product arrives damaged, please contact customer service at <a class="text-primary hover:text-primary" href="https://support.jackery.com?cr=US" target="_blank">support.jackery.com</a> within 7 days.
        `,
          "question_7": "7. 교환한 제품은 제가 잭커리에서 구입한 제품과 함께 발송 가능한가요?",
          "answer_7": `
          No. Redeemed products (an item obtained through point redemption or from the draw) will be shipped separately and will be delivered within 60 working days.
          <br/>
          You need to submit or confirm the address information so that the official website can arrange delivery in time.
        `,
        },
        "others": {
          "title": "기타",
          "question_1": "1. 저희를 연락하여 더 많은 도움을 받기",
          "answer_1": `
          If you have more questions, please email our Customer Service at <a class="text-primary hover:text-primary" href="mailto:hello{'@'}jackery.com">hello{'@'}jackery.com</a>, visit <a class="text-primary hover:text-primary" href="https://support.jackery.com?cr=US" target="_blank">support.jackery.com</a> for help, or click to contact us.
        `,
          "question_2": "2. 잭커리 회원 포인트 규칙 위반 처리",
          "answer_2": "잭커리는 사용자가 포인트 획득, 포인트 사용 또는 이익 획득에 악의적이거나 거짓 행위를 한 경우 사용자 포인트를 동결 또는 취소하거나 서비스 제공을 중지하거나 사용자 계정을 재량껏 취소할 권리를 보유하며 사용자의 재등록 신청을 받아들이지 않습니다.",
          "question_3": "3. 특별 성명",
          "answer_3": `
          All rights about Jackery Membership reserved by Jackery.
          <br/>
          Membership benefits may change periodically; please refer to the membership homepage for details.
        `,
        },
      },

    },
    // overview
    "overview": {
      "welcomeGift": "환영 선물",
      "largeOrderBenefits": "대량 주문의 혜택",
      "firstRegistrationInfo": "잭커리 멤버십을 처음 등록할 때 {num} 혜택을 받을 수 있습니다.",
      "benefitsForYou": "고객님에게 제공 드리는 혜택",
      "welcomeGiftInfo": "100% 당첨, 쿠폰/선물 카드/실제 아이템이 기다리고 있습니다!",
      "unit": "포인트",
      "pointsBalance": "포인트 잔액",
      "largeOrderBenefitsInfo": "{num} 을 초과하는 주문은 무료 선물을 한 번 받을 수 있습니다.",
      "firstRegistration": "첫 등록",
      "comingSoon": "Coming Soon"
    },
    // 我的钱包
    "wallet": {
      "productRules": {
        "one": {
          "title": "1. 잭커리 제품 쿠폰이란 무엇입니까?",
          "step1": "1) 잭커리 제품 사은품은 잭커리가 프로모션, 오리엔테이션 마케팅, 회원 포인트 교환 및 기타 형식으로 사용자에게 배포한 쿠폰으로 고객에 대한 피드백으로 합니다.",
          "step2": "2) 잭커리가 출시한 잭커리 제품은 전자 형식으로 쿠폰으로 존재하며 잭커리의 공식 홈페이지에서 해당 제품의 전부 또는 일부 신용 감면을 통해 구매할 수 있습니다.",
          "step3": "3) 주문 구매 및 결제 시 잭커리 제품에 대한 할인 코드를 입력하면 카드 할인/금액이 주문 총액에서 자동으로 공제됩니다. 쿠폰은 현금으로 바꿀 수 없습니다.",
        },
        "two": {
          "title": "2. 잭커리 제품 쿠폰은 어떻게 확인할 수 있습니까?",
          "step1": "1) 잭커리 회원을 가입하고 '계정 센터 - 나의 지갑 - 잭커리 제품'에서 잭커리 제품 쿠폰(미사용, 사용 및 만료 정보 포함)을 확인하세요.",
          "step2": "2) 잭커리 제품 쿠폰의 유효 기간을 참조하십시오. 잭커리는 연체 지불을 보상하지 않으므로 유효 기간 내에 사용하십시오.",
        },

        "three": {
          "title": "3. 잭커리 제품 쿠폰 사용에 대한 설명:",
          "step1": "1) 각 잭커리 제품 쿠폰은 특정 유효 기간이 있으며 사용 시 세부 정보를 확인해야 합니다.",
          "step2": "2) 적용 사이트: 본 사이트에서 신청하거나 교환한 잭커리 제품 쿠폰은 본 사이트에서만 사용할 수 있으며 사이트 간에 사용할 수 없습니다.",
          "step3": "3) 동일한 주문은 쿠폰 한 장만 사용할 수 있으며 프로모션과 함께 사용할 수 없습니다. 동일한 주문에 여러 제품이 있는 경우 쿠폰은 해당 제품에만 적용되고 다른 제품에는 적용되지 않습니다.",
          "step4": "4) 잭커리 제품 쿠폰은 개인 계정에서만 사용할 수 있는 계정으로 바인딩됩니다. 결제 페이지에서의 이메일 주소는 쿠폰 수신자의 이메일 주소와 일치해야 하며 다른 사람에게 양도할 수 없습니다.",
          "step5": "5) 회원은 포인트를 사용하여 잭커리 제품 쿠폰을 교환 할 수 있으며 포인트를 공제 한 후 포인트와 쿠폰은 취소 할 수 없습니다. 일정 기간 동안 회원 포인트의 쿠폰 교환 횟수는 제한될 수 있습니다.",
          "step6": "6) 주문에서 쿠폰을 사용하는 경우 환불 시 쿠폰을 환불할 수 없습니다.",
        },
        "four": {
          "title": "4. 잭커리 제품 선물 교환 조항",
          "step1": "1) 잭커리의 공식 웹사이트에 접속하여 해당 제품 상세 정보 페이지에 접속하여 구매를 클릭하십시오.",
          "step2": "2) 결제 페이지에 해당 할인 코드를 입력합니다 (결제 시 코드 {XXX} 붙여넣기).",
          "step3": "3) 결제 페이지에서의 이메일은 쿠폰을 받기 위한 이메일입니다.",
        },
        "five": {
          "title": "5. 쿠폰 사용 규칙 위반 처리",
          "step1": "잭커리는 위조, 구매, 판매 또는 다른 사람에게서 양도한 쿠폰을 사용하는 경우 쿠폰을 동결하거나 무효화하거나 쿠폰 사용 주문을 취소하거나 쿠폰 자격을 취소하거나 서비스 제공을 중지하거나 사용자 계정을 해지할 권리가 있으며 사용자의 재등록 신청을 받아들이지 않을 권리가 있습니다."
        },

      },
      "giftRules": {
        "one": {
          "title": "1.잭커리 선물 카드란?",
          "step1": "1) 선물 카드는 잭커리가 프로모션, 오리엔테이션 마케팅, 멤버십 포인트 교환 및 기타 형식으로 사용자에게 배포하는 고객 피드백 방식으로 구매 지불을 줄입니다.",
          "step3": "3) 결제 주문을 구매할 때 잭커리 선물 카드 코드를 입력하면 카드 금액이 주문 총액에서 자동으로 공제됩니다. 선물 카드는 현금으로 바꿀 수 없습니다.",
          "step2": "2) 잭커리가 출시한 선물 카드는 전자적으로 존재하며 잭커리의 공식 웹 사이트에서 제품을 구매하는 데 사용할 수 있습니다."
        },

        "two": {
          "title": "2. 선물 카드를 어떻게 확인합니까?",
          "step1": "1) 잭커리 회원으로 가입하여 '계정 센터 - 나의 지갑 - 선물 카드'에서 선물 카드(미사용, 사용 및 만료 정보 포함)를 확인합니다.",
          "step2": "2) 선물 카드의 유효 기간을 참조하십시오. 잭커리는 연체 지불을 보상하지 않으므로 유효 기간 동안 사용하십시오.",
        },
        "three": {
          "title": "3. 선물 카드 사용 설명:",
          "step1": "1) 각 선물 카드에는 특정 액면가와 유효 기간이 있습니다. 사용 시 각 선물 카드의 상세 정보를 확인하십시오.",
          "step2": "2) 적용 사이트: 선물 카드는 수집하거나 교환하는 사이트에 유효하며 사이트 간에 사용할 수 없습니다.",
          "step3": "3) 선물 카드를 사용할 때 최소 주문 금액이 없습니다. 모든 제품에 적용됩니다.",
          "step4": "4) 선물 카드는 개인 계좌에 국한되지 않고 친구에게 선물로 이체할 수 있습니다.",
          "step5": "5) 동일한 주문에 여러 장의 선물 카드를 사용할 수 있으며, 프로모션과 함께 사용하거나 쿠폰과 함께 사용할 수 있습니다.",
          "step6": "6) 회원은 포인트로 기프트카드를 교환할 수 있으며 포인트를 공제한 후 포인트와 기프트카드는 모두 취소할 수 없습니다.",
          "step7": "7) 일정 기간 동안 회원 포인트로 기프트 카드를 교환할 수 있는 횟수는 제한될 수 있으며 횟수가 소진되면 시스템이 안내 드릴 것입니다.",
          "step8": "8) 주문에서 선물 카드를 사용하는 경우 환불 시 선물 카드를 환불할 수 없습니다.",

        },
        "four": {
          "title": "4. 선물 카드 사용 규칙 위반 처리",
          "step1": "위조, 매매한 선물 카드를 사용하는 경우 잭커리는 선물 카드를 동결하거나 무효화시킬 권리가 있으며 선물 카드 사용 주문을 취소하고 사용자의 선물 카드 자격을 취소하며 서비스 제공을 중지하거나 사용자의 계정을 취소하고 사용자의 재등록 신청을 받지 않을 권리가 있습니다."
        },
      },
      "giftCard": "선물 카드",
      "couponRules": {
        "one": {
          "step2": "2) 잭커리의 쿠폰은 전자적으로 존재하며 잭커리의 공식 사이트에서 제품을 구매하는 데 사용할 수 있습니다.",
          "step3": "3) 주문 구매 및 결제 시 잭커리 쿠폰의 할인 코드를 입력하면 카드 할인/금액이 주문 총액에서 자동으로 공제됩니다. 쿠폰은 현금으로 바꿀 수 없습니다.",
          "step1": "1) 쿠폰은 잭커리가 할인 혜택을 위해 프로모션, 오리엔테이션 마케팅, 멤버십 포인트 교환 및 기타 형식으로 사용자에게 배포하는 고객 피드백 방법입니다.",
          "title": "1.쿠폰이란 무엇입니까?"
        },
        "two": {
          "title": "2. 쿠폰은 어떻게 확인하나요?",
          "step1": "1) 잭커리 회원으로 가입하고 '계정 센터 - 나의 지갑 - 쿠폰'에서 쿠폰(미사용, 사용 및 만료 정보 포함)을 확인합니다.",
          "step2": "2) 쿠폰의 유효 기간을 참조하십시오. 유효기간 내에 사용하십시오. 기한이 지나면 환불하지 않습니다.",
        },
        "three": {
          "title": "3. 쿠폰 사용 방법:",
          "step1": "1) 각 쿠폰에는 특정 할인, 최소 주문 금액, 적용 제품 및 유효 기간이 있습니다. 사용 시 각 쿠폰의 상세 정보를 확인하십시오.",
          "step2": "2) 적용 사이트: 쿠폰은 쿠폰을 수집하거나 교환하는 사이트에 유효하며 사이트 간에 사용할 수 없습니다.",
          "step3": "3) 동일한 주문은 쿠폰 한 장만 사용할 수 있으며 프로모션과 함께 사용할 수 없습니다. 한 주문에 여러 개의 제품이 포함된 경우 쿠폰은 조건에 맞는 것에만 적용됩니다.",
          "step4": "4) 일부 쿠폰은 개인 계정에서만 사용할 수 있도록 한 계정에 연동됩니다. 주문 페이지의 이메일 주소는 쿠폰 수신자의 이메일 주소와 일치해야 하며 전이할 수 없습니다. 일부 쿠폰은 다른 사용자에게 증정할 수 있는데 자세한 내용은 쿠폰 사용 지침을 참조하십시오.",
          "step5": "5) 쿠폰은 선물 카드와 함께 사용할 수 있습니다.",
          "step6": "6) 회원은 포인트로 쿠폰을 교환할 수 있으며, 포인트를 공제한 후 포인트와 쿠폰은 모두 취소할 수 없습니다. 일정 기간 동안 회원 포인트의 쿠폰 교환 횟수는 제한될 수 있습니다.",
          "step7": "7) 주문에서 쿠폰을 사용하는 경우 환불 시 쿠폰을 환불할 수 없습니다.",
        },
        "four": {
          "title": "4. 쿠폰 사용 규칙 위반 처리",
          "step1": "잭커리는 위조, 구매, 판매 또는 다른 사람에게서 양도한 쿠폰을 사용하는 경우 쿠폰을 동결하거나 무효화하거나 쿠폰 사용 주문을 취소하거나 쿠폰 자격을 취소하거나 서비스 제공을 중지하거나 사용자 계정을 해지할 권리가 있으며 사용자의 재등록 신청을 받아들이지 않을 권리가 있습니다.",
        }
      },
      "extraRules": {
        "one": {
          "title": "1. 잭커리 추가 혜택이란 무엇입니까?",
          "step1": "1) 잭커리 기타 혜택은 비 잭커리 브랜드의 선물로 상품권, 쿠폰, 선물 카드와 실물 선물을 포함합니다.",
          "step2": "2) 잭커리는 판촉 활동, 오리엔테이션 마케팅, 회원 포인트 교환 등 형식으로 사용자에게 배포하여 고객에 대한 피드백으로 삼습니다. 잭커리 추가 포인트를 교환하면 포인트가 공제되며 포인트와 선물 모두 인출 할 수 없습니다.",
        },
        "two": {
          "title": "2. 잭커리 기타 정보는 어디서 확인할 수 있습니까?",
          "step1": "1) 잭커리 회원 가입하고 '계정 센터 - 나의 지갑 - 추가 기능'에서 확인하세요.",
          "step2": "2) 유효기간 내에 혜택을 교환하세요. 일단 기한이 지나면 다시 발행할 수 없습니다.",
          "step3": "3) 행사 상세 정보는 이메일로 발송 드립니다. 청구인은 이메일에서 관련 정보를 볼 수 있습니다.",
        },
        "three": {
          "title": "3. 잭커리 추가 선물 받기",
          "step1": {
            "title": "1) 실물선물을 받을 때의 규칙:",
            "tip1": "① 잭커리의 실물 선물이 아닌 경우 주소에 정확하게 기입해야 합니다.",
            "tip2": "②고객님의 제품은 수령 후 30-90일 이내에 발송됩니다.",
            "tip3": "③ 배송 후 추적 번호를 포함한 배송 상세 정보가 이메일로 발송됩니다.",
            "tip4": "④ 실물로 받은 상품이 파손되거나 다른 문제가 있으면 언제든지 고객센터에 연락하십시오.",
          },
          "step2": {
            "title": "2) 비잭커리 브랜드 가상 선물 카드 주의 사항",
            "tip1": "① 이메일로 전송될 \"내용 및 방법\"을 포함한 잭커리 브랜드가 아닌 가상 기프트 카드의 상세 정보.",
            "tip2": "② 잭커리가 아닌 브랜드의 가상 선물 카드를 받은 기한은 XX년이며 받는 자마자 활성화됩니다.활성화되면 선물 카드의 유효 기간은 해당 브랜드가 지정한 유효 기간에 따라 결정됩니다. 자세한 내용은 해당 브랜드 웹 사이트를 참조하십시오.",
            "tip3": "③ 잭커리 브랜드가 아닌 가상 선물 카드의 구체적인 사용 규칙은 선물 카드 교환 플랫폼 규칙 소개에서 찾을 수 있습니다.",
          },
        },
        "four": "4. 분쟁이 발생할 경우 잭커리는 최종 결정권을 보유합니다.",
      },
      "noCoupon": "쿠폰 없습니다.",
      "used": "사용됨",
      "comingSoon": "Coming Soon",
      "redeemMyCoins": "내 포인트 교환하기",
      "coupon": "쿠폰",
      "rules": "규칙",
      "noGiftCard": "사용 가능한 선물 카드가 없습니다.",
      "copyAndUse": "복사 및 사용",
      "jackeryPointsBalance": "포인트 잔액",
      "pointsDetails": "나의 활동",
      "viewMore": "더보기",
      "noExtras": "사용 가능한 추가 기능 없습니다.",
      "noJackeryProducts": "사용 가능한 잭커리 제품 쿠폰이 없습니다.",
      "daysLeft": "{num}일 남았습니다(미사용)",
      "un_used": "미사용",
      "jackeryProducts": "잭커리 제품",
      "expired": "만료됨",
      "extras": "기타",
      "code": "코드",
      "unit": "포인트",
      "redeemMyPoints": "내 포인트 교환하기"
    },
    // My Service
    "service": {
      "imgText2": "지원하기",
      "imgText1": "잭커리 오신 것을 환영합니다.",
      'pcImage': 'https://cdn.shopifycdn.net/s/files/1/0550/0524/9633/files/My_Service_f2d45c3c-6199-4b74-85d8-bc1977a5d9b9.png?v=1683191312',
      'mbImage': 'https://cdn.shopifycdn.net/s/files/1/0550/0524/9633/files/My_Service_efc7e4e4-a728-40e4-ab7b-31aab55839af.png?v=1683191345',
      "viewSupport": "고객 지원으로 이동하기",
      "contactUs": "연락하기",
      "text": "스마트 서비스인 잭커리 지원으로 이동합니다."
    },
    // My Extended Warranty
  "myExtendedWarranty":{
    "emptyTips":"No extended warranty information",
    "shopNow":"Shop Now",
    "sn":"SN for the extended warranty product",
    "notBound":"Not yet bound.",
    "activate":"Activate",
    "underReview":"Under review. Please be patient.",
  },
    // My Jackery Life
    "jackeryLife": {
      "text": "잭커리 라이프에서 잭커리와 이야기를 공유하고 대상을 수상할 수 있는 기회를 얻으십시오!",
      "imgText1": "잭커리 라이프",
      "imgText2": "잭커리 솔라와 함께 삽니다.",
      "learnMore": "더 알아보기"
    },
    "wishlist": {
      "createdAsc": "가장 빠른 날짜 추가",
      "shopNow": "지금 구매하기",
      "selected": "선택됨",
      "priceDesc": "낮은 가격으로",
      "viewMoreHref": "https://kr.jackery.com/",
      "selectAll": "전체 선택",
      "purchased": "구매",
      "addOn": "추가됨",
      "createdDesc": "최근 날짜",
      "buyNow": "지금 구매하기",
      "modalTitle": "제품을 삭제하고 나중에 확인하시겠습니까?",
      "removeToast": "이 제품은 이미 위시리스트에서 삭제되었습니다.",
      "empty": "위시리스트에 제품이 없습니다.",
      "priceAsc": "높은 가격으로",
      "amount": "총 금액",
      "modalOk": "컨펌",
      "modalCancel": "취소",
      "notGoodsGotoBuy": "아이템을 선택하십시오."
    },
    // Trade-in
    "trade-in": {
      "inspectionAndVerification": "검사 및 인증",
      "title": "나의 수상판매",
      "timeLine": "진행 중 거래",
      "productInformation": "세부 정보",
      "viewMore": "더보기",
      "orderCompleted": "주문 완료되었습니다.",
      "packageInTransit": "운송 중인 소포",
      "date": "날짜",
      "awaitingFeedback": "피드백 대기 중",
      "trackingNo": "추적 번호",
      "acutalProductModel": "Acutal 제품 모델",
      "level": "Acutal 제품 수준",
      "orderNumber": "주문번호",
      "transactionPrice": "거래 금액",
      "acceptNote": "* 선물 카드의 유효 기간은 10일입니다.",
      "productModel": "제품 모델",
      "QuoteConfirmation": "견적 확인",
      "issuingGiftCard": "선물 카드를 발급하기",
      "endDate": "마감일",
      "detailPage": "디테일",
      "priceConfirmation": "가격 확인",
      "rejectText": "확인해 주셔서 감사합니다.귀하가 제품 견적을 받아들이지 않기 때문에, 저희가 선물 카드를 제공 드릴 수 없습니다.",
      "sendShippingLabel": "운송 라벨 보내기",
      "closed": "닫기",
      "productReturn": "제품 반품",
      "startDate": "시작 날짜",
      "acceptText": "확인해 주셔서 감사합니다. 이제 선물 카드를 사용하실 수 있습니다.",
      "cardCode": "선물 카드 코드",
      "orderStatus": "주문 상태",
      "empty": "정보가 없습니다.",
      "packageDelivered": "소포가 이미 배달되었습니다.",
      "orderCancelled": "주문 취소됨",
      "estimatedPrice": "예상 가격"
    },
    "old-invite-new": {
      "myRewardsTitle": "My Rewards",
      "myRewardsSubTitle": "Manage and use rewards that have been claimed here. For expired and used rewards, please check them in My Wallet.",
      "myRewardsNoData": "Invite your friends to subscribe or purchase, and claim your rewards on the dashboard below.",
      "viewMore": "View More",
      "myReferralsTitle": "My Referrals",
      "myReferralsSubTitle": "Track the referral progress and claim the rewards. Click the reward for details.",
      "myReferralsNoData": "Invite your friends to subscribe or make a purchase, then claim your rewards here.",
      "claim": "Claim",
      "claimed": "Claimed",
      "expired": "Expired",

      // 补充
      "myReferral": "My Referral",
      "enterEmail": "Enter Your Email Address",
      "invalidEmail": "Invalid Email Address",
      "messageSent": "Message Sent",
      "copy": "COPY",
      "copied": "COPIED",
      "copiedSuccess": "COPIED SUCCESS!",
      "friendEmail": "Enter Your Friend's Email",
      "referee": "Referee",
      "referralType": "Referral Type",
      "rewards": "Rewards",
      "status": "Status",
      "instagramCopySuccessTip": "Referral Link has copied, paste and share to Instagram",
    }
  }
