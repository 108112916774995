import { createRouter, createWebHistory } from 'vue-router';

const m = import.meta.glob('../views/**/*.vue');
const load = (path) => m[`../views/${path}.vue`];

/**
 * constantRoutes
 * 不需要身份认证 所有情况都可以访问的页面
 */
export const constantRoutes = [
  {
    path: '/404', name: 'error404', component: load('404'),
    meta: {requireAuth: false, title: 'router.404', description: 'description.404'}
  },
  {
    path: '/500', name: '500', component: load('500'),
    meta: {requireAuth: false, title: 'router.500', description: 'description.500'}
  },
  {
    path: '/login', name: 'login', component: load('login'),
    meta: {requireAuth: false, title: 'router.login', description: 'description.login'}
  },
  {
    path: '/register', name: 'register', component: load('register'),
    meta: {requireAuth: false, title: 'router.register', description: 'description.register'}
  },
  {
    path: '/reset', name: 'reset', component: load('reset'),
    meta: {requireAuth: false, title: 'router.reset', description: 'description.reset'}
  },
  {
    path: '/activate', name: 'activate', component: load('activate'),
    meta: {requireAuth: false, title: 'router.activate', description: 'description.activate'}
  },
  {
    path: '/identification', name: 'identification', component: load('identification'),
    meta: {requireAuth: false, title: 'router.activate', description: 'description.activate'}
  },
  {
    path: '/policies', name: 'policies', component: load('policies/layout'),
    meta: { requireAuth: false, },
    children: [
      {
        path: 'terms-of-service', name: 'terms-of-service', component: load('policies/terms-of-service'),
        meta: { requireAuth: false, title: 'router.termsOfService', description: 'description.termsOfService' },
      },
      {
        path: 'privacy-policy', name: 'privacy-policy', component: load('policies/privacy-policy'),
        meta: { requireAuth: false, title: 'router.privacyPolicy', description: 'description.privacyPolicy' },
      }
    ]
  },
  {
    path: '/index', name: 'layout', component: load('index/layout'),
    meta: { requireAuth: false, },
    children: [
      {
        path: '', name: 'index', component: load('index/index'),
        meta: { requireAuth: false, title: 'router.memberIndex', description: 'description.memberIndex' },
      },
      {
        path: 'guide', name: 'guide', component: load('index/guide'),
        meta: { requireAuth: false, title: 'router.memberGuide', description: 'description.memberGuide' },
      }
    ]
  },
  {
    path: '/id',
    name: 'id',
    redirect: '/account',
    meta:{
      permissions: [ 'US', 'CA', 'GB', 'DE', 'FR', 'IT','ES', 'JP', 'AU', 'EU']
    }
  },
  {
    path: '/', name: 'home',
    redirect: '/account',
    meta: { requireAuth: true, title: 'router.home', description: 'description.home' },
    children: []
  },
  // { path: '/:catchAll(.*)', redirect: '/404', hidden: true }
]

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from) {
    return { x: 0, y: 0 }
  },
  routes: constantRoutes
});

/** 权限路由 */
export const dynamicRoutes = [
  {
    path: '/account',
    name: 'account',
    component: () => import('@/views/home.vue'),
    redirect: '/account/overview',
    meta: { requireAuth: true, title: 'router.home', description: 'description.home' },
    children: [
      {
        path: 'overview',
        name: 'accountOverview',
        component: () => import('@/views/account/overview.vue'),
        meta: {
          requireAuth: true,
          title: 'router.accountOverview',
          description: 'description.accountOverview',
          permissions: [ 'US', 'CA', 'GB', 'DE', 'FR', 'IT','ES', 'JP', 'AU', 'EU']
        }
      },
      {
        path: 'myProfile',
        name: 'accountProfile',
        component: () => import('@/views/account/myProfile.vue'),
        meta: {
          requireAuth: true,
          title: 'router.myProfile',
          description: 'description.myProfile',
          activeIcon: 'icon-a-MyProfile',
          permissions: [ 'US', 'CA', 'GB', 'DE', 'FR', 'IT','ES', 'JP', 'AU', 'EU']
        }
      },
      {
        path: 'address',
        name: 'accountAddress',
        component: () => import('@/views/account/address/index.vue'),
        meta: {
          requireAuth: true,
          title: 'router.addressBook',
          description: 'description.addressBook',
          activeIcon: 'icon-a-MyAddress',
          permissions: [ 'US', 'CA', 'GB', 'DE', 'FR', 'IT','ES', 'JP', 'AU', 'EU']
        }
      },
      {
        path: 'wishlist',
        name: 'accountWishlist',
        component: () => import('@/views/account/wishlist.vue'),
        meta: {
          requireAuth: true, title: 'router.myWishlist', description: 'description.myWishlist',
          activeIcon: 'icon-a-MyWishlist',
          permissions: [  'US', 'GB', 'DE', 'CA', 'FR', 'IT', 'ES' ]
        }
      },
      {
        path: 'order',
        name: 'accountOrder',
        component: () => import('@/views/account/order/index.vue'),
        meta: {
          requireAuth: true,
          title: 'router.myOrders',
          description: 'description.myOrders',
          activeIcon: 'icon-a-MyOrders',
          permissions: [ 'US', 'CA', 'GB', 'DE', 'FR', 'IT','ES', 'JP', 'AU', 'EU']
        }
      },
      {
        path: 'setting',
        name: 'accountSettings',
        component: () => import('@/views/account/setting.vue'),
        meta: {
          requireAuth: true, title: 'router.accountSettings', description: 'description.accountSettings',
          permissions: [ 'US', 'CA', 'GB', 'DE', 'FR', 'IT','ES', 'JP', 'AU', 'EU']
        }
      },
      {
        path: 'devices',
        name: 'accountDevices',
        component: () => import('@/views/account/devices.vue'),
        meta: {
          requireAuth: true, title: 'router.myDevices', description: 'description.myDevices',
          activeIcon: 'icon-a-MyDevice',
          permissions: [ 'US', 'CA', 'GB', 'DE', 'FR', 'IT','ES', 'JP', 'AU', 'EU']
        }
      },
      {
        path: 'referral',
        name: 'accountReferral',
        component: () => import('@/views/account/referral.vue'),
        meta: {
          requireAuth: true, title: 'router.myReferral', description: 'description.myReferral',
          activeIcon: 'icon-a-MyReferral-new',
          permissions: ['US', 'CA', 'GB', 'DE', 'AU', 'EU', 'FR', 'JP']
        }
      },
      {
        path: 'wallet', name: 'accountWallet',
        component: () => import('@/views/account/wallet.vue'),
        meta: {
          requireAuth: true, title: 'router.myWallet', description: 'description.myWallet',
          activeIcon: 'icon-a-MyWallet',
          permissions: [ 'US', 'CA', 'GB', 'DE', 'FR', 'IT','ES', 'JP', 'AU', 'EU']
        }
      },
      {
        path: 'return',
        name: 'accountReturn',
        component: () => import('@/views/account/return.vue'),
        meta: {
          requireAuth: true, title: 'router.myReturn', description: 'description.myReturn',
          activeIcon: 'icon-a-MyReturn',
          permissions: [ 'US', 'CA', 'GB', 'DE', 'FR', 'IT','ES', 'JP', 'AU', 'EU']
        }
      },
      {
        path: 'service',
        name: 'accountService',
        component: () => import('@/views/account/service.vue'),
        meta: {
          requireAuth: true, title: 'router.myService', description: 'description.myService',
          activeIcon: 'icon-a-MyService',
          permissions: [ 'US', 'CA', 'GB', 'DE', 'FR', 'IT','ES', 'JP', 'AU', 'EU']
        }
      },
      {
        path: 'trade-in',
        name: 'accountTradeIn',
        component: () => import('@/views/account/trade-in.vue'),
        meta: {
          requireAuth: true, title: 'router.myTradeIn', description: 'description.myTradeIn',
          activeIcon: 'icon-a-MyTrade-In',
          permissions: ['US','GB','DE']
        }
      },
      {
        path: 'myJackeryLife',
        name: 'accountJackeryLife',
        component: () => import('@/views/account/jackeryLife.vue'),
        meta: {requireAuth: true, title: 'router.myJackeryLife', description: 'description.myJackeryLife',
          activeIcon: 'icon-a-MyJackerylife',
          permissions: ['US','CA','DE','GB']
        }
      },
      {
        path: 'my-extended-warranty',
        name: 'accountPEW',
        component: () => import('@/views/account/paidExtendedWarranty.vue'),
        meta: {
          requireAuth: true, title: 'router.myExtendedWarranty',
          activeIcon: 'icon-a-MyExtendedWarrany',
          permissions: [ 'US', 'CA', 'JP']
        }
      },
    ]
  },
];

/** 初始路由 */
export default router;