export default {
  "login": {
    "sign": "Einloggen",
    "login": "Einloggen",
    "remember": "erinnern",
    "about": "Über uns",
    "illegal_email": "Invalides E-Mail-Format",
    "illegal_password": "Das Passwort muss 6-16 Zeichen beinhalten, muss Nummern und Buchstaben enthalten",
    "password_matching": "Falsches Passwort",
    "enterEmail": "E-Mail-Adresse/Jackery ID",
    "enter_code": "Bitte eingeben Bestätigungscode",
    "forgot": "Passwort vergessen?",
    "create": "Konto erstellen",
    "thirdLogin": "Oder anmelden mit:",
    "accountActivate": "Konto aktiviert",
    "bindEmail": "Binden Sie Ihre E-Mail-Adresse",
    "error": "Login fehlgeschlagen, bitte versuchen Sie es später erneut",
    "quickLogin": "Passwortfreie Anmeldung",
    "quickLoginA": "Passwortfreies Einloggen"
  },
  "policies": {
    "content": "Die Anmeldung durch einen Drittanbieter bedeutet, dass Sie unsere",
    "policy-link": "https://de.jackery.com/policies/privacy-policy",
    "policy": "Datenschutzerklärung",
    "and": "und",
    "terms-link": "https://de.jackery.com/policies/terms-of-service",
    "terms": "Nutzungsbedingungen ",
    "comma": "gelesen und akzeptiert haben.",
    "footer": "Jackery verwendet Informationen, die vom Drittanbieter bereitgestellt werden, mit dem Sie sich registrieren oder anmelden."
  },
  "reset": {
    "modify": "Zurücksetzen",
    "back": "Zurück zur Anmeldung",
    "success": "Neues Passwort erfolgreich erstellt!",
    "referrer": "Weiterleitung zur ursprünglichen Seite in",
    "seconds": "Sekunden"
  },
  "register": {
    "title": "Registrieren",
    "enterEmail": "E-Mail-Adresse",
    "agree": "Ich habe die Datenschutzrichtlinien und Nutzungsbedingungen von Jackery gelesen und bin einverstanden. ",
    "agree_after": "",
    "getDeals": "Ich stimme zu, die neuesten Produktangebote, Werbeaktionen und Produktinformationen zu erhalten.",
    "login": "Schnelle Anmeldung",
    "success": "{email} Ihr Konto wurde erfolgreich registriert!",
    "welcomeEmail": "Eine Willkommensnachricht wurde an Ihre E-Mail-Adresse versendet."
  },
  "header": {
    "logout": 'Ausloggen',
    "userCenter": "Konto-Center",
    "personalCenter": "Profileinstellungen",
    "shopping": "Zum Store",
    "setting": "Einstellung",
    "myJackery": "Konto-Center",
    "jackeryID": "Profileinstellungen",
    "wishlist": "Meine Wunschliste",
  },
  "footer": {
    "cookieContent": "Wir verwenden Cookies, einschließlich Cookies von Drittanbietern, um sicherzustellen, dass die Webseite reibungslos funktioniert, den Datenverkehr analysiert, ein verbessertes Social-Media-Erlebnis bietet sowie Inhalte und Werbung personalisiert.",
    "cookieUrl": "#",
    "learn": "Erfahren Sie mehr",
    "accept": "Cookies akzeptieren",
    "copyright": "Copyright © {date} Jackery Technology GmbH All Rights Reserved.",
    "sitemap-link": "#",
    "sitemap": "Sitemap"
  },
  "account": {
    "welcome": "Willkommen",
    "email": "E-Mail-Adresse",
    "registerEmail": "E-Mail registrieren",
    "bindNewRegisterEmail": "Neue E-Mail-Adresse",
    "emailIsEqual": "Die neue und ursprüngliche E-Mail-Adresse ist identisch und muss nicht geändert werden.",
    "settings": "Kontoeinstellungen",
    "settingTips": "Verwalten Sie schnell Ihre Konto- und Sicherheitseinstellungen",
    "titleProfiles": "Persönliches Profil",
    "titleSecurity": "Kontosicherheit",
    "titleAuthorization": "Konto binden",
    "nickname": "Nutzername",
    "name": "Name",
    "gender": "Geschlecht",
    "birthday": "Geburtsdatum",
    "contactEmail": "E-Mail-Adresse",
    "enterprise": "Geschäftskonto",
    "setted": "Bewerben",
    "pop_password": "Passwort erstellen",
    "unset": "Anwendung rückgängig machen",
    "password": "Passwort",
    "apply": "Bewerben",
    "secrecy": "Keine Angabe",
    "male": "Männlich",
    "female": "Weiblich",
    "currentPassword": "Aktuelles Passwort",
    "newPassword": "Neues Kennwort",
    "newShowAgain": "Nie wieder anzeigen",
    "againPassword": "Neues Passwort bestätigen",
    "jackeryIdTips": "Die Jackery ID kann als Benutzername verwendet und nur einmal bearbeitet werden. Diese sollte Zahlen, Buchstaben und Symbole enthalten. Die Länge sollte zwischen 6-32 Zeichen liegen.",
    "birthdayTips": "Bitte geben Sie Ihr Geburtsdatum ein, damit wir Ihnen den bestmöglichen Service bieten können. Wir behandeln alle Ihre persönlichen Daten streng vertraulich.",
    "nickNameTips": "Der Nickname kann aus beliebigen Buchstaben oder Zahlen bestehen und darf 32 Zeichen nicht überschreiten",
    "nameTips": "Es wird dringend empfohlen, dass Sie Ihren richtigen Namen verwenden, damit wir Sie kontaktieren können. Wir behandeln alle persönlichen Informationen streng vertraulich.",
    "genderTips": "Bitte geben Sie Ihren richtigen Namen ein, damit wir Ihnen den bestmöglichen Service bieten können. Wir behandeln alle Ihre persönlichen Daten streng vertraulich.",
    "contactEmailTips": "Bitte verwenden Sie Ihre gängigste E-Mail-Adresse, damit wir Sie problemlos kontaktieren können. Wir behandeln Ihre persönlichen Daten streng vertraulich.",
    "phoneNumberTips": "Bitte geben Sie Ihre gängigste Telefonnummer ein, damit wir Sie kontaktieren können. Wir behandeln Ihre persönlichen Daten streng vertraulich. Es werden nur Zahlen, Klammern und Bindestriche unterstützt.",
    "phoneNumberTips2": "Es werden nur Zahlen, Klammern und Bindestriche unterstützt",
    "enterpriseNameTips": "Wenn Sie ein Geschäftskonto verwenden, teilen Sie uns bitte Ihre relevanten Geschäftsinformationen mit, um Ihnen den bestmöglichen Service zu bieten.",
    "emailTips": "Bitte verwenden Sie Ihre gängigste E-Mail-Adresse, damit wir Sie problemlos kontaktieren können. Wir behandeln Ihre persönlichen Daten streng vertraulich.",
    "passwordTips": "Bitte wählen Sie ein stärkeres Passwort für Ihre Sicherheit",
    "location": "Ort",

    "contactPhone": "Telefon",
    "avatarSettings": "Avatar-Einstellungen",
    "choosePicture": "Bild auswählen",
    "myProfile": "Mein Profil",
    "changePassword": "Passwort wechseln",
    "creatPassword": "Passwort erstellen",
    "firstName": "Vorname",
    "lastName": "Nachname",
    "editPasswordTips": "Ihr Passwort muss aus 6-16 Ziffern bestehen und Buchstaben und Zahlen umfassen. Aus Sicherheitsgründen sollten Sie ein starkes Passwort wählen.",
    "subscriberTip": "Erhalten Sie {num} Punkte für mein erstes E-Mail-Abonnement und erhalten Sie die aktuellen Neuigkeiten und Angebote von Jackery. Stimmen Sie den Nutzungsbedingungen und der Datenschutzerklärung von Jackery zu.",
    "birthdayTip1": "Erhalten Sie {num} Punkte, indem Sie Ihren Geburtstag ausfüllen.",
    "birthdayTip2": "Verdienen Sie { num } Punkte an jedem Geburtstag und erhalten Sie doppelte Shopping-Punkte.",
    "birthdayTip3": "Nur eine Chance zur Änderung.",
    "phoneTip": "Erhalten Sie { num } Punkte für mein erstes SMS-Abonnement und erhalten Sie die aktuellen Neuigkeiten und Angebote von Jackery. Stimmen Sie den Nutzungsbedingungen und der Datenschutzerklärung von Jackery zu..",
    "countryRegion": "Land|Region",
    "successTip1": "Sie haben Ihr Profil erfolgreich eingereicht, { num } Jackery Punkte werden innerhalb von 24 Stunden gutgeschrieben.",
    "successTip": "Sie haben Ihr Profil erfolgreich eingereicht.",
    "errorTip": "Einreichung fehlgeschlagen, bitte prüfen und erneut einreichen."
  },
  "router": {
    "memberIndex": "MemberShip",
    "memberGuide": "MemberShip Guide",
    "home": "Heim",
    "404": "404",
    "500": "500",
    "about": "about",
    "register": "Jackery ID registrieren",
    "login": "Anmelden",
    "reset": "Zurücksetzen",
    "dashboard": "Dashboard",
    "commonSetting": "Allgemeine Einstellungen",
    "account": "Kontoeinstellungen",
    "accountOverview": "Überblick",
    "myProfile": "Mein Profil",
    "myOrders": "Meine Bestellung",
    "personalSettings": "Persönliche Einstellungen",
    "addressBook": "Meine Adresse",
    "accountSettings": "Kontoeinstellungen",
    "myDevices": "Mein Gerät",
    "myExtendedWarranty":"My Extended Warranty",
    "activate": "Konto aktivieren",
    "myWallet": "Mein Geldbeutel",
    "myService": "Mein Dienst",
    "myReturn": "Meine Rücksendung",
    "myJackeryLife": "Mein Jackery Stories",
    "myWishlist": "Meine Wunschliste",
    "myTradeIn": "Mein Upgrade",
    "myReferral": "Meine Empfehlungen",
  },
  "description": {
    "memberIndex": "Jackery",
    "memberGuide": "Jackery",
    "home": "Jackery",
    "404": "404",
    "500": "500",
    "about": "about",
    "register": "Erstellen Sie eine Jackery ID, um Mitglied zu werden.",
    "login": "Als Kunde anmelden",
    "reset": "Zurücksetzen",
    "dashboard": "Dashboard",
    "commonSetting": "Allgemeine Einstellungen",
    "account": "Kontoeinstellungen",
    "accountOverview": "Überblick",
    "myProfile": "Persönliches Profil",
    "myOrders": "Meine Bestellung",
    "personalSettings": "Persönliche Einstellungen",
    "addressBook": "Adressbuch",
    "accountSettings": "Kontoeinstellungen",
    "myDevices": "Mein Gerät",
    "activate": "Erstellen Sie ein Passwort, um Ihr Konto zu aktivieren.",
    "registerActivate": "Bitte klicken Sie unten, um Ihr Konto zu aktivieren. Nach der Aktivierung können Sie sich mit dem Konto auf unserer Website anmelden.",
    "myReturn": "Meine Rücksendung",
    "myWallet": "Mein Geldbeutel",
    "myService": "Mein Dienst",
    "myJackeryLife": "My Jackery Life",
    "myTradeIn": "Mein Upgrade"
  },
  "operate": {
    "enable": "Aktivieren",
    "disable": "Deaktiviert",
    "confirm": "Bestätigen",
    "cancel": "Abbrechen",
    "remove": "Entfernen",
    "search": "Suche",
    "reset": "Zurücksetzen",
    "add": "Hinzufügen",
    "close": "Schließen",
    "edit": "Bearbeiten",
    "yes": "Ja",
    "no": "Nein",
    "submit": "Versenden",
    "next": "Weiter",
    "prev": "Vorheriger Schritt",
    "back": "Zurück",
    "reject": "Ablehnen",
    "accept": "Akzeptieren"
  },
  "tips": {
    "sure": "Sind Sie sicher?",
    "placeholder": "Bitte eingeben",
    "enterConsultContent": "Bitte geben Sie Ihren Anfrageinhalt ein",
    "all": "Alle",
    "custom": "Benutzerdefiniert",
    "pending": "Ausstehend",
    "loading": "Wird bearbeitet...",
    "loading2": "Wird bearbeitet",
    "loaded": "Fortfahren",
    "loaded2": "Abgeschlossen",
    "cancelled": "Unbezahlt",
    "changeShop": "Shop wechseln",
    "logoutConfirm": "Sind Sie sicher, dass Sie sich ausloggen möchten?",
    "loggedOut": "Erfolgreich ausgeloggt",
    "refreshed": "Aktualisiert",
    "noDescribe": "Keine Beschreibung",
    "reminder": "Wichtige Hinweise",
    "commError": "Ups! Irgendwas ist schiefgelaufen.",
    "reLogin": "Bitte melden Sie sich erneut an",
    "verify": "Bestätigungscode", "sendVerify": "Der Bestätigungscode wurde an Ihre E-Mail-Adresse versendet. Bitte prüfen Sie Ihre Inbox oder Junkmail-Ordner.",
    "getVerifyCode": "Bestätigungscode erhalten",
    "verifyError": "Bestätigungscode ist nicht korrekt",
    "homePage": "Bestätigungscode ist nicht korrekt",
    "404": "Diese Seite existiert nicht.",
    "404Tips": "Bitte überprüfen Sie, ob die IP-Adresse des Servers korrekt ist oder klicken Sie auf den Link, um fortzufahren.",
    "500": "Ups! Irgendwas ist schiefgelaufen.",
    "500Tips": "Serverfehler,",
    "500Tips2": "bitte versuchen Sie es später erneut。",
    "urlExpired": "Bestätigung Verbindungsabbruch",
    "accountNotActivated": "Das Konto wurde nicht aktiviert",
    "accountActivatedSuccess": "Ihr Konto wurde erfolgreich aktiviert.",
    "requestFrequently": "Bitte wiederholen Sie den Vorgang nicht, sondern versuchen Sie es nach der Überprüfung erneut.",
    "submitSuccess": "Erfolgreich versendet",
    "zipVerifyError": "PLZ-Bestätigung fehlgeschlagen",
    "otherError": "Ups! Irgendwas ist schiefgelaufen, bitte kontaktieren Sie den Administrator. code: {code}",
    "wrongPasswordMultipleTimes": "Sie haben zu viele falsche Passwörter eingegeben. Bitte versuchen Sie es {minute} Minuten später erneut.",
    "networkError": "Netzwerkfehler",
    "thirdLoginError": "Login fehlgeschlagen, bitte versuchen Sie es erneut mit Ihrem Konto",
    "fileTypeError": "Dateitypfehler, nur Hochladen ist erlaubt: Datei vom Typ {type}",
    "copySuccessfully": "kopiert"
  },
  "placeholder": {
    "email": "E-Mail-Adresse *",
    "emailOrJackeryID": "E-Mail-Adresse/Jackery ID *",
    "password": "Passwort *",
    "passwordAgain": "Neues Passwort bestätigen *",
    "enterprise": "Geschäftskonto",
    "code": "Bestätigungscode *",
    "firstName": "Vorname",
    "lastName": "Nachname",
    "select": "Bitte auswählen",
    "address1": "Straße und Telefonnummer",
    "address2": "Apartment, Suite, etc. (optional)",
    "country": "Land/Region auswählen",
    "province": "Bundesland/Provinz/Region auswählen",
    "city": "Ihre Stadt",
    "region": "Region",
    "zip": "Postleitzahl",
    "zipError": "Inkorrekte Postleitzahl",
    "phone": "Ihre Telefonnummer",
    "company": "Unternehmensname (optional)",
    "isNotEmpty": "Diese Option darf nicht leer bleiben",
    "pobox": "Wir liefern nicht an Postfächer",
    "minChar2": "Mindestens 2 Zeichen",
    "inputChar": "die Zeichenlänge liegt bei {length} Bits"
  },
  "order": {
    "sn": "Bestellnummer",
    "detail": "Versanddetails anzeigen",
    "productName": "Produktname",
    "quantity": "Menge",
    "price": "Preis",
    "status": "Bestellstatus",
    "consult": "Kundenanfragen",
    "contact": "Kontaktieren Sie uns",
    "total": "Gesamt",
    "empty": "Derzeit keine Bestellungen gefunden ~",
    "questionType": "Problemtyp",
    "shipped": "Verschickt",
    "return": "Rückgabe",
    "applied": "Zurückgeschickt und erstattet",
    "revoke": "Widerrufen",
    "serviceType": "Bitte wählen Sie eine Serviceart aus",
    "serviceType_1": "Rückgabe und Rückerstattung",
    "serviceType_2": "Ersetzen",
    "packageArrived": "Bestätigen Sie, dass Sie die Ware erhalten haben",
    "reason": "Bitte geben Sie einen Grund ein",
    "reason_1": "{day} Tage Rückgabe ohne Rückfragen",
    "reason_2": "Qualitätsmängel",
    "reason_3": "Andere",
    "quality": "Bitte geben Sie de spezifischen Qualitätsmängel an",
    "productSerialNumber": "Bitte geben Sie die Seriennummer des Produkts ein",
    "selectReturnProduct": "Bitte wählen Sie das zurückgegebene Produkt aus",
    "returnQuantity": "Bitte geben Sie die Anzahl der Umtäusche/Rücksendungen ein",
    "shippingAddress": "Versandadresse",
    "returnAddress": "Rücksendeadresse",
    "revise": "Bearbeiten",
    "uploadVideo": "Upload video",
    "uploadLoading": "Das Video wird gerade hochgeladen",
    "videoLimit": "Die Videogröße darf {limit} MB nicht überschreiten!",
    "uploadVideoError": "Video-Upload fehlgeschlagen",
    "failureDetermination": "Fehlerbehebung bei der Produktqualität",
    "steps_1": "1. Schritte zur Fehlersuche bei der Power Station.",
    "steps_1_content": "1) Bitte drücken Sie auf das Display, um zu sehen, ob die Kontrollleuchte leuchtet.\n2) Bitte setzen Sie die Power Station zurück und legen Sie sie für eine Weile an einen anderen Ort, bevor Sie das Gerät wieder aufladen.\n---Halten Sie die 'DISPLAY'-Taste 10 Sekunden lang gedrückt, wenn es sich um eine E160/E240/E500/E1000-Station handelt.\n---Halten Sie die Taste \"DISPLAY+USB\" 10 Sekunden lang gedrückt, wenn es sich um eine E1500/E2000-Station handelt.\n\n3) Drücken Sie die Taste auf der Oberseite der AC/DC-Steckdose, um zu prüfen, ob das Licht leuchtet, bevor Sie Ihr Gerät einstecken und aufladen lassen.\n4)Prüfen Sie, ob das Gerät wieder normal aufgeladen werden kann.\n\nWenn die oben genannten Schritte nicht funktionieren, ist die Stromversorgungseinheit defekt.\n\n",
    "steps_2": "2. Schritte zur Fehlerbehebung beim Solarmodul",
    "steps_2_content": "1) Benutzen Sie bitte das mitgelieferte AC-Ladegerät, um die Power Station aufzuladen und zu prüfen, ob sie funktioniert.\n2) Testen Sie das Gerät unter starker Sonneneinstrahlung. Verbinden Sie das Solarpanel mit dem Kraftwerk und stellen Sie sicher, dass kein Schatten auf das Panel fällt.\n\nEs ist ein Defekt, wenn die Energiestation mit dem AC-Ladegerät gut aufgeladen werden kann, aber mit dem Solarpanel nicht funktioniert.",
    "learnMore": "Mehr",
    "shrink": "weglegen",
    "noReason_30_day": "",
    "selectDelivery": "Bitte Retourenlogistik auswählen",
    "enterTrackNumber": "Bitte geben Sie die Sendungsnummer ein",
    "illegal_trackNumber": "Bitte geben Sie die korrekte Sendungsnummer ein",
    "enterDescription": "Bitte geben Sie eine spezifische Beschreibung ein",

    "comingSoon": 'Demnächst',
    "cancelled": 'Abgesagt',
    "points": "Punkte",
    "comingSoonTip": ' (Jackery-Punkte werden am einunddreißigsten Tag nach dem Versand des Produkts aktiviert).',
    "pointsBalance": "Punktestand",
    "tipTitle": 'Vorteile für Mitglieder',
    "shopNow": "Jetzt Einkaufen",
    "tip1": `① Bei Ihren <a> Bestellungen </a> auf der offiziellen Jackery-Website nutzen Sie die automatische Verlängerung der Gewährleistung (bis zu 5 Jahre) und erhalten Jackery-Punkte (1 ausgegebener Dollar = 1 verdienter Punkt).`,
    "tip2": `② Bei der <a> Registrierung von Produkten, </a> die Sie über inoffizielle Kanäle erworben haben, verlängern Sie Ihre Gewährleistung (bis zu 5 Jahre).`,
    "tip3": `③ <a> Indem Sie nach der Registrierung eine Bestellung über {num} </a> auf der offiziellen Website von Jackery aufgeben, können Sie einmalig ein kostenloses Geschenk erhalten.`
  },
  "bank": {
    "name": "Bank Name",
    "branchName": "Bankfiliale",
    "type": "Bankkartentyp",
    "account": "Kartennummer",
    "realName": "Name des Karteninhabers"
  },
  "return": {
    "orderNumber": "Bestellnummer",
    "status": "Rückgabe/Umtausch Status",
    "attachment": "Anhang",
    "confirmRevoke": "Bitte bestätige, ob du den Rückgabe-/Umtauschantrag stornieren möchtest?",
    "returnPolicy_link": "https://de.jackery.com/policies/refund-policy",
    "returnPolicy_1": "Häufig gestellte Fragen zu Reparatur-/Rückgabeanträgen findest du in unserer ",
    "returnPolicy_2": " 'After-Sale Policy'. ",
    "returnPolicy_3": "Solltest du noch Fragen haben, wende dich bitte an ",
    "returnPolicy_4": "support{'@'}jackery.com",
    "returnPolicy_5": ""
  },
  "devices": {
    "empty": "Keine Daten zum Gerät",
    "more": "Mehr",
    "extendedWarrantyDeadline": "Ablaufdatum der Garantie",
    "warrantyStatus": "Überprüfen Sie den Status Ihrer (erweiterten Garantie)",
    "existWarranty": "Aktiv",
    "nonExistentWarranty": "Inaktiv",
    "userManual": "Benutzerhandbuch",
    "warrantyInfo": "Klicken Sie auf 'Absenden', um Ihre erweiterte Garantie zu aktivieren.",
    "newsletter": "Abonnieren Sie unseren Newsletter",
    "warrantySuccess": "Aktiv",
    "download": "Herunterladen",
    "none": "Nicht verfügbar",
    "addNewDevice": "Neues Gerät hinzufügen",
    "shopNow": "Jetzt einkaufen"
  },
  "address": {
    "empty": "Keine Adresse gespeichert ~",
    "add": "Neue Adresse hinzufügen",
    "edit": "Adresse bearbeiten",
    "userName": "Nutzername",
    "address1": "Adresse 1",
    "address2": "Adresse 2",
    "country": "Land/Region",
    "country_code": "Land/Postleitzahl",
    "province": "Bundesland/Provinz/Region",
    "province_code": "Bundesland/Provinz/Postleitzahl",
    "city": "Stadt",
    "zip": "Postleitzahl",
    "phone": "Telefonnummer",
    "company": "Unternehmen",
    "default": "Als Standard-Versandadresse festlegen",
    "isDefault": "Standardadresse",
    "error": "Die Lieferadresse ist anormal oder die Informationen sind unvollständig, bitte überprüfen"
  },
  "character": {
    "comma": ","
  },
  // Following is server language pack
  "msg": {
    "success": "Erfolg"
  },
  "user": {
    "log": {
      "expire": "Bitte melden Sie sich erneut an"
    }
  },
  "message": {
    "order_status_shipping": "Verschickt",
    "order_status_completed": "Abgeschlossen",
    "order_status_processing": "Wird bearbeitet",
    "order_status_archived": "Verschickt",
    "order_status_canceled": "Unbezahlt",
    "order_status_unpaid": "unbezahlt",
    "device_not_found": "Gerät existiert nicht",
    "device_disable_can_not_set_warranty": "Dieses Gerät hat keinen Anspruch auf erweiterte Garantie.",
    "device_was_warranty": "Die Garantie Ihres Geräts wurde verlängert.",
    "device_not_support_warranty": "Dieses Gerät hat keinen Anspruch auf erweiterte Garantie."
  },
  "create": {
    "address": {
      "fail": "Adresse hinzufügen fehlgeschlagen"
    }
  },
  "activation": {
    "email": {
      "expire": "Ungültige E-Mail-Adresse"
    }
  },
  "select_country": {
    "title": "Land/Region Auswählen"
  },



  // 会员首页 多语言
  "member_index" : {
    "common": {
      "login": "Anmelden",
      "register": "Registrieren",
      "already_a_member": "Bereits ein Mitglied?",
      "points": "Punkte",
      "redeem": "Einlösen",
      "rules": "Regeln",
      "copy_successfully": "kopiert",
      "outOfStock": "Nicht auf Lager",
      "quick_login": "Einloggen/Anmelden"
    },
    // Banner 标题
    "banner": {
      "title": `Jackery<br/>Mitgliedschaft`,
      "description": `Sammeln Sie Jackery-Punkte, lösen Sie exklusive Vorteile ein, und tun Sie etwas Sinnvolles mit Jackery`,
      "points_balance": "Punktestand",
      "coming_soon": "Demnächst",
      "my_rewards": "Meine Belohnungen",
      "my_activity": "Meine Aktivitäten",
      "unused": "Unbenutzt",
      "to_be_redeemed": "Konvertierbar",
      "no_unused_rewards": "Keine verfügbaren Belohnungen",
      "no_convertible_rewards": "Keine umwandelbaren Vorteile",
      "no_activity_records": "Derzeit sind keine Punktaktivitäten registriert",
      "time": "Zeit",
      "action": "Aktion",
      "points": "Punkte",
    },
    "section_tabs": {
      "membership_benefits": "Vorteile der Mitgliedschaft",
      "redeem_points": "Punkte einlösen",
      "earn_points": "Punkte sammeln",
      "guide": "Anweisungen",
      "faq": "Häufig gestellte Fragen",
    },
    // Benefits
    "membership_benefits": {
      "title": "Exklusive Vorteile der Jackery-Mitgliedschaft",
      "banner_text": "Sammeln Sie Jackery-Punkte mit Ihrem Einkauf und lösen Sie exklusive Prämien ein.",
      "coupon_benefit": "Erhalten Sie {num} Rabatt bei Ihrer ersten Registrierung",
      "giftcard_benefit": "Kostenloses Geschenk bei Bestellungen über {num}",
      "product_benefit": "Erhalten Sie regelmäßige Erinnerungen an die Produktpflege",
      "gift_benefit": "Erhalten Sie 15% Rabatt, sobald die Garantie abgelaufen ist"
    },
    // Redeem Points
    "redeem_points": {
      "title": "Holen Sie sich Belohnungen<br/>indem Sie Jackery-Punkte einlösen",
      "unusedTips": "Verwenden Sie es an der Kasse, bevor es abläuft!",
      "pointTips": "Sie haben {0} Jackery-Punkte.",
      "category_tabs": {
        "coupon": "Gutschein",
        "gift_card": "Geschenkkarte",
        "jackery_products": "Jakcery-Produkt",
        "virtualCard": "Extras",
      },
      "code": "Code",
      "redeem_confirm_title": "{0} Punkte werden verwendet. Sie werden nach der Bearbeitung nicht zurückerstattet.",
      "redeem_confirm_subtitle": "",
      "redeem_confirm_content": "Sind Sie sicher, dass Sie einlösen möchten?",
      "successfully_redeemed": "Erfolgreich eingelöst",
      "information_tips": "Die Informationen über die Geschenkkarte wurden Ihnen per E-Mail zugesandt. Bitte überprüfen Sie Ihr Mailbox.",
      "copy_and_use": "Kopieren und verwenden",
      "day": "Tg.",
      "hour": "Std.",
      "minute": "Min.",
      "seconds": "Sek.",
      "count_down_info": "Die Aktion ist beendet und der Belohnungen kann nicht eingelöst werden. Bitte aktualisieren Sie die Seite."
    },
    // 获取积分方式
    "earn_points": {
      "title": "Möglichkeiten, Punkte zu sammeln",
      "go_now": "Jetzt gehen",
      "points_earned": "Verdiente Punkte",
      "membership_registration": "Registrierung als Mitglied",
      "membership_registration_desc": "Erhalten Sie {0} Jackery-Punkte, wenn Sie beitreten.",
      "email_subscribtion": "E-Mail-Abonnement",
      "email_subscribtion_desc": "Ehalten Sie {0} Jackery-Punkte, indem Sie Jackery per E-Mail abonnieren.",
      "sms_subscribtion": "SMS-Abonnement",
      "sms_subscribtion_desc": "Ehalten Sie {0} Jackery-Punkte, indem Sie Jackery per SMS abonnieren.",
      "fill_in_your_birthday": "Geben Sie Ihren Geburtstag ein",
      "fill_in_your_birthday_desc": "Holen Sie sich {0} Punkte, indem Sie Ihren Geburtstag eingeben.<br/>Sammeln Sie an jedem Geburtstag {1}-Punkte und erhalten Sie doppelte Einkaufspunkte.",
      "extend_your_warranty": "Verlängern Sie Ihre Garantie",
      "extend_your_warranty_desc": `
        Holen Sie sich {0} Punkte, indem Sie Produkte auf der offiziellen Website von Jackery registrieren.
        <br/>
        (Für Kunden, die über Drittanbieterkanäle gekauft haben.)
      `,
      "shop_and_earn": "Einkaufen und Verdienen",
      "shop_and_earn_desc": "Gehen Sie einkaufen, 1 ausgegebener Euro = 1 verdienter Punkt<br/>{0} Punkte für die {1} Bestellung<br/>{2} Punkte für Bestellungen über {3}",
      "tip": "Multi-Site-Abonnements zählen nur als ein Abonnement-Punkt."
    },
    "guide": {
      "title": "Wie funktioniert es",
      "step_title_1": "1. Registrieren",
      "step_content_1": "Erstellen Sie ein Jackery-Konto und genießen Sie exklusive Mitgliedschaftsvorteile.",
      "step_title_2": "2. Punkte sammeln",
      "step_content_2": `Erledigen Sie Aufgaben, um Punkte zu sammeln (z. B. Ausfüllen persönliche Daten \/ Einkaufen von Produkten \/ Teilnahme an Veranstaltungen usw.)`,
      "step_title_3": "3. Belohnungen erhalten",
      "step_content_3": `Lösen Sie Ihre Jackery-Punkte gegen exklusive Angebote (z. B. Gutscheine, Geschenkkarten usw.) ein.`,
      "learn_more": "Lernen Sie mehr kennen",
    },
    // 注册 指引页
    "register_guide": {
      "title": "Wie Sie Ihre Jackery <br/>Mitgliedschaftsprämien erhalten?",
      "step_1_title": "Schritt 1 - Registrieren und Anmelden",
      "step_1_subtitle_1": "[Registrieren]",
      "step_1_content_1": `① Gehen Sie zur <a class="text-primary hover:text-primary" href="https://account.jackery.com/register?CR=DE" target="_blank">Registrierungsseite</a> oder wählen Sie „Ein Konto erstellen“ auf <a class="text-primary hover:text-primary" href="https://account.jackery.com?CR=DE" target="_blank">account.jackery.com</a>`,
      "step_1_content_2": "② Füllen Sie Ihre E-Mail-Adresse und Ihr Passwort aus.",
      "step_1_content_3": "③ Bestätigen Sie Ihr neues Passwort und geben Sie den Verifizierungscode ein, den Sie über Ihre E-Mail erhalten haben.",
      "step_1_content_4": `④ Kreuzen Sie „Ich stimme der Datenschutzerklärung und den Nutzungsbedingungen von Jackery zu“ und „Ich bin damit einverstanden, die neuesten Nachrichten, Angebote, Aktionen und Produktinformationen von Jackery zu erhalten.“ Lesen Sie die Datenschutzerklärung und die Nutzungsbedingungen von Jackery, um Ihre Rechte zu schützen.`,
      "step_1_content_5": `⑤Klicken Sie auf „Abschicken“, um Ihre Registrierung zu beenden.<br/>(Wenn Sie sich bereits registriert haben, überspringen Sie bitte diesen Schritt oder melden Sie sich an).`,
      "step_1_subtitle_2": "[Anmelden]",
      "step_1_content_6": `①3 Möglichkeiten zur Anmeldung:`,
      "step_1_content_7": `Gehen Sie zur <a class="text-primary hover:text-primary" href="https://account.jackery.com/login?CR=DE" target="_blank">Anmelden-Seite</a>`,
      "step_1_content_8": `Wählen Sie „Jetzt anmelden“ auf <a class="text-primary hover:text-primary" href="https://account.jackery.com/register?CR=DE" target="_blank">account.jackery.com</a>`,
      "step_1_content_9": `Klicken Sie auf das Symbol „Konto“ oben rechts auf der Startseite.`,
      "step_1_content_10": `② Füllen Sie Ihre E-Mail-Adresse und Ihr Passwort aus.`,
      "step_1_content_11": `③ Klicken Sie auf „ANMELDEN“, um Ihr Kontocenter aufzurufen.`,
      "step_1_img_1_pc": "https://cdn.shopify.com/s/files/1/0572/7217/2741/files/1_65ca3e35-9f98-4c6b-8888-070148bc9501.png?v=1690166668",
      "step_1_img_2_pc": "https://cdn.shopify.com/s/files/1/0572/7217/2741/files/2_9cb7bb8a-0868-4537-ad7b-1d612a7cdc38.png?v=1690166668",
      "step_1_img_3_pc": "https://cdn.shopify.com/s/files/1/0572/7217/2741/files/4_8a4c024a-e826-4ca5-8733-d6248828fe36.png?v=1690166667",
      "step_1_img_4_pc": "https://cdn.shopify.com/s/files/1/0572/7217/2741/files/3_bbbdb05b-99e1-4db2-9e5a-513fe160325e.png?v=1690166667",
      "step_1_img_5_pc": "https://cdn.shopify.com/s/files/1/0572/7217/2741/files/5_6c4dad96-4e41-4423-882b-a8ae65aa5a3c.png?v=1690166668",
      "step_1_img_6_pc": "",
      "step_1_img_1_mb": "https://cdn.shopify.com/s/files/1/0572/7217/2741/files/1_bafa8680-afee-4ae7-829d-1a8e871c5679.png?v=1690166927",
      "step_1_img_2_mb": "https://cdn.shopify.com/s/files/1/0572/7217/2741/files/2_8dcd4b9e-6c41-4d31-8235-7e894b91bcd4.png?v=1690166927",
      "step_1_img_3_mb": "https://cdn.shopify.com/s/files/1/0572/7217/2741/files/3.1.png?v=1690166927",
      "step_1_img_4_mb": "https://cdn.shopify.com/s/files/1/0572/7217/2741/files/3.2.png?v=1690166927",
      "step_1_img_5_mb": "https://cdn.shopify.com/s/files/1/0572/7217/2741/files/3.3.png?v=1690166926",
      "step_1_img_6_mb": "",

      "step_2_title": "Schritt 2 - Verdienen Sie Jackery-Punkte",
      "step_2_content_1": `Schauen Sie unter „Möglichkeiten zum Punktesammeln“ auf der <a class="text-primary hover:text-primary" href="https://membership.jackery.com?CR=DE" target="_blank">Mitgliedschaftsseite</a> nach und erfahren Sie, wie Sie Jackery-Punkte sammeln können.`,
      "step_2_img_1_pc": "https://cdn.shopify.com/s/files/1/0572/7217/2741/files/6.2.png?v=1690166666",
      "step_2_img_1_mb": "https://cdn.shopify.com/s/files/1/0572/7217/2741/files/3.5.png?v=1690166926",

      "step_3_title": "Schritt 3 - Jackery-Punkte überprüfen",
      "step_3_content_1": `Nachdem Sie sich bei Ihrem Konto angemeldet haben, können Sie Ihren Punktestand über das <a class="text-primary hover:text-primary" href="https://account.jackery.com/account/overview?CR=DE" target="_blank">Kontocenter</a> oder die <a class="text-primary hover:text-primary" href="https://membership.jackery.com?CR=DE" target="_blank">Mitgliedschaftsseite</a> überprüfen.`,
      "step_3_content_2": `① Kontocenter: Es gibt drei Wege, um Ihre Punkte zu überprüfen: „Übersicht“, „Mein Profil“ und „Mein Geldbeutel“.`,
      "step_3_content_3": `② Klicken Sie auf „Punktestand“, um zur <a class="text-primary hover:text-primary" href="https://membership.jackery.com?CR=DE" target="_blank">Seite Mitgliedschaft</a> zu gelangen, wo Sie ebenfalls Ihren Punktestand einsehen können.`,
      "step_3_content_4": `Es gibt zwei Arten von Punkten: Gutgeschriebene Punkte und ausstehende Punkte.`,
      "step_3_content_5": `Die gutgeschriebenen Punkte werden im „Punktestand“ angezeigt und können zum Einlösen verwendet werden.`,
      "step_3_content_6": `Die ausstehenden Punkte werden im Abschnitt „Demnächst“ angezeigt und können nicht zum Einlösen verwendet werden.`,
      "step_3_content_7": `Die Punkte im Abschnitt „Demnächst“ werden jedoch zum angegebenen Zeitpunkt in den „Punktestand“ übertragen.`,
      "step_3_content_8": `Zum Beispiel werden die durch einen Einkauf gesammelten Einkaufspunkte im Abschnitt „Demnächst“ angezeigt, wenn die Ware versandt wird. 30 Tage später werden diese Punkte in den „Punktestand“ übertragen.`,
      "step_3_img_1_pc": "https://cdn.shopify.com/s/files/1/0572/7217/2741/files/7_4577b96d-c17c-42be-8784-71e700cf97da.png?v=1690166665",
      "step_3_img_2_pc": "https://cdn.shopify.com/s/files/1/0572/7217/2741/files/8_a935dc5e-84b8-4044-b79d-505e6bb8a9e5.png?v=1690166666",
      "step_3_img_3_pc": "https://cdn.shopify.com/s/files/1/0572/7217/2741/files/9_19973c81-80d2-46ff-bfc2-424a128384aa.png?v=1690166666",
      "step_3_img_4_pc": "https://cdn.shopify.com/s/files/1/0572/7217/2741/files/10_2ecc6d62-6fb7-4521-b0d3-7d497f09e2e3.png?v=1690166667",
      "step_3_img_1_mb": "https://cdn.shopify.com/s/files/1/0572/7217/2741/files/4.1.png?v=1690166927",
      "step_3_img_2_mb": "https://cdn.shopify.com/s/files/1/0572/7217/2741/files/4.2.png?v=1690166927",
      "step_3_img_3_mb": "https://cdn.shopify.com/s/files/1/0572/7217/2741/files/4.3.png?v=1690166927",
      "step_3_img_4_mb": "https://cdn.shopify.com/s/files/1/0572/7217/2741/files/4.4.png?v=1690166928",

      "step_4_title": "Schritt 4 - Jackery-Punkte einlösen",
      "step_4_content_1": `① Melden Sie sich auf der <a class="text-primary hover:text-primary" href="https://membership.jackery.com?CR=DE" target="_blank">Mitgliedschaftsseite</a> an und klicken Sie auf „Meine Prämien - Zum Einlösen“ oder durchsuchen Sie die Seite „Prämien durch Einlösen Ihrer Punkte erhalten“`,
      "step_4_content_2": `Die Prämien, die Sie erhalten können, werden hervorgehoben, für die Sie Ihren aktuellen Punktestand einlösen können.`,
      "step_4_content_3": `② Wählen Sie die gewünschte Prämie aus und klicken Sie auf „Einlösen“, tippen Sie auf dem Pop-up auf „Bestätigen“ und dann können Sie die Prämien erhalten.`,
      "step_4_img_1_pc": "https://cdn.shopify.com/s/files/1/0572/7217/2741/files/12_3d8e739e-bf93-437b-a3f7-eb11412dfd76.png?v=1690166667",
      "step_4_img_2_pc": "https://cdn.shopify.com/s/files/1/0572/7217/2741/files/13_d1f63abb-9e82-4a00-9fdf-dd558d1f8fdd.png?v=1690166666",
      "step_4_img_3_pc": "https://cdn.shopify.com/s/files/1/0572/7217/2741/files/11_d5e6246f-30ac-4e84-bed9-ef2409986c3d.png?v=1690166666",
      "step_4_img_4_pc": "https://cdn.shopify.com/s/files/1/0572/7217/2741/files/14_c1963610-55aa-404b-a32f-a95154d40c8f.png?v=1690166666",
      "step_4_img_1_mb": "https://cdn.shopify.com/s/files/1/0572/7217/2741/files/4.5.png?v=1690166928",
      "step_4_img_2_mb": "https://cdn.shopify.com/s/files/1/0572/7217/2741/files/4.6.png?v=1690166927",
      "step_4_img_3_mb": "https://cdn.shopify.com/s/files/1/0572/7217/2741/files/5_344ee935-5ff7-41be-a129-c724c77bf500.png?v=1690166927",
      "step_4_img_4_mb": "https://cdn.shopify.com/s/files/1/0572/7217/2741/files/7_ff1f3ae7-63ff-4bdb-b059-2800f30acb5d.png?v=1690166928",

      "step_5_title": "Schritt 5 - Verwendung der Prämien",
      "step_5_content_1": `Wenn Sie Jackery-Punkte für Gutscheine oder Geschenkkarten eingelöst haben, klicken Sie auf „Kopieren und verwenden“, der Code würde kopiert worden sein. Kaufen Sie auf der offiziellen Website von Jackery ein, verwenden Sie den Code an der Kasse, und Sie können bei Ihrem Einkauf mehr sparen.`,
      "step_5_content_2": `Wenn Sie Jackery-Punkte für das Jackery-Produkt eingelöst haben, können Sie das Produkt an der Kasse kostenlos erhalten, indem Sie den Rabattcode einfügen.`,
      "step_5_img_1_pc": "https://cdn.shopify.com/s/files/1/0572/7217/2741/files/15_64b18886-6af6-42a1-abdc-fe3db2bf2e2e.png?v=1690166666",
      "step_5_img_2_pc": "https://cdn.shopify.com/s/files/1/0572/7217/2741/files/16_94218a40-d624-4dbe-889d-4a6bf85b5e97.png?v=1690166666",
      "step_5_img_1_mb": "https://cdn.shopify.com/s/files/1/0572/7217/2741/files/8_3d3538bc-68c9-42e1-82b4-753e31e3d6b3.png?v=1690166928",
      "step_5_img_2_mb": "https://cdn.shopify.com/s/files/1/0572/7217/2741/files/9_0254825c-06b2-495f-a358-2e4d58efd774.png?v=1690166927",
    },
    "faq": {
      "title": "FAQ",
      "membership": {
        "title": "Jackery-Mitgliedschaft",
        "question_1": "1. Was ist die Jackery-Mitgliedschaft?",
        "answer_1": `Die Jackery-Mitgliedschaft ist ein punktebasiertes Programm, durch das unsere Kunden (wie Sie!) auf <a class="text-primary hover:text-primary" href="https://de.jackery.com">de.jackery.com</a> Punkte sammeln können. Ihre Punkte können gegen Prämien eingelöst werden. Die Mitgliedschaft ist völlig kostenlos. Es ist unsere Weise, Ihnen zu danken und zu zeigen, wie sehr wir Ihre Treue zu schätzen wissen.`,
        "question_2": "2. Wie nehme ich an der Jackery-Mitgliedschaft teil?",
        "answer_2": `
          Sie müssen ein Jackery-Konto registrieren, um Mitglied bei Jackery zu werden.
          <br/>
          Wenn Sie noch keins haben, registrieren Sie sich jetzt. Hier stellen wir Ihnen eine <a class="text-primary hover:text-primary" href="/index/guide" target="_blank">Schritt-für-Schritt</a>-Anleitung zur Verfügung, falls Sie das Verfahren kennenlernen müssen.
          <br/>
          Wenn Sie bereits ein Konto auf der offiziellen Jackery-Website haben, können Sie Ihr bestehendes Konto aktivieren oder sich anmelden.
        `,
        "question_3": "3. Muss ich mich auf der anderen Website von Jackery neu registrieren, wenn ich mich bereits registriert habe?",
        "answer_3": "Sobald sich der Benutzer registriert hat, ist sein Konto mit allen offiziellen Websites von Jackery kompatibel.",
        "question_4": "4. Kann ich, wenn ich mehrere Jackery-Konten habe, diese zu einem einzigen zusammenführen?",
        "answer_4": "Wenn Sie sich mit verschiedenen E-Mail-Adressen registrieren, wird die Jackery-ID unterschiedlich sein und kann nicht zusammengeführt werden.",
        "question_5": "5. Wie kann ich die Mitgliedschaft bei Jackery stornieren?",
        "answer_5": `
          Kontaktieren Sie unseren Kundendienst, um Ihr Konto zu stornieren. Die Punkte werden auf Null zurückgesetzt.
          <br/>
          Abgemeldete Benutzer können über den Registrierungsprozess erneut Mitglied bei Jackery werden. Punkte werden neu berechnet.
          <br/>
          Wenn Sie sich wieder als Mitglied registrieren möchten, um eine erfolgreiche Anmeldung zu gewährleisten, verwenden Sie bitte eine andere E-Mail-Adresse für die Registrierung.
        `,
      },
      "earn_points": {
        "title": "Punkte sammeln",
        "question_1": "1. Wie kann man Punkte sammeln?",
        "answer_1": `
          Wenn Sie Mitglied bei Jackery geworden sind, können Sie Punkte sammeln, indem Sie bestimmte Aufgaben auf der offiziellen Website abschließen. Hier sind einige Hinweise für Sie.
          <br/>
          <br/>
          ① Mitgliedschaft Registrierung: Wenn Sie sich vor April 2022 registriert haben, müssen Sie Ihre Mitgliedschaft aktivieren, indem Sie sich anmelden und Ihr Konto verifizieren. Sie können für Ihre erste Registrierung oder Aktivierung Punkte sammeln.
          <br/>
          ② E-Mail-Abonnement: Sie können Punkte für ein einmaliges E-Mail-Abonnement sammeln, für ein erneutes Abo nach einer Abmeldung gibt es keine Punkte. Multi-Site-Abonnements zählen nur als ein Abonnement-Punkt.
          <br/>
          ③ SMS-Abonnement: Sie können Punkte sammeln, indem Sie einmalig ein SMS-Abonnement abschließen. Sie müssen Ihre Telefonnummer in 'Mein Konto - Mein Profil' oder auf der Seite 'Verlängern Sie Ihre Gewährleistung' eingeben, aber nicht nur in der Fußzeile der Website abonnieren. Multi-Site-Abonnements zählen nur als ein Abonnement-Punkt.
          <br/>
          ④ Geben Sie Ihr Geburtsdatum ein: Sie können Punkte sammeln, indem Sie Ihren Geburtstag eingeben. Sie können an jedem Geburtstag Punkte erhalten und Einkaufspunkte doppelt erhalten. Ihr Geburtstag kann nur einmal geändert werden.
          <br/>
          ⑤ Erweitern Sie Ihre Gewährleistung: Nur für Benutzer, die auf Plattformen Dritter wie Amazon, Walmart usw. einkaufen. Sobald Sie die Gewährleistung für das Produkt auf der offiziellen Website erfolgreich verlängert haben, werden die Punkte entsprechend den Regeln vergeben.
          <br/>
          ⑥ Einkaufspunkte: Nach dem Kauf von Produkten auf der offiziellen Website von Jackery können Sie Punkte erhalten, die sich nach dem Gesamtwert Ihrer Bestellungen richten. Zusätzlich können Sie bei der {0}. Bestellung oder ab einem Einkaufswert von {1} jeweils zusätzliche Punkte erhalten.
          <br/>
          ⑦ Teilnahme an Extra Punkte Aktivitäten: Sie können Punkte erhalten, indem Sie an Extra Punkte Aktivitäten auf der offiziellen Website von Jackery teilnehmen. Einzelheiten entnehmen Sie bitte der Beschreibung der Aktivität auf der Seite der Jackery-Mitgliedschaft.
        `,
        "question_2": "2. Wann werden die Punkte auf dem Konto gutgeschrieben?",
        "answer_2": `
          Es gibt zwei Arten von Zeiten, in denen die Punkte ankommen:
          <br/>
          <br/>
          ① Schnelle Ausgabe: Nachdem Sie die auf der offiziellen Website angegebene Aufgabe abgeschlossen haben, wie z.B. die Registrierung, das Abonnement, das Ausfüllen des Geburtstags und die Verlängerung der Gewährleistung, werden die Punkte innerhalb von 24 Stunden auf Ihrem Konto gutgeschrieben.
          <br/>
          ② Verspätete Ausgabe: Punkte für Einkäufe werden nach dem Versand der Ware als 'Demnächst-Punkte' ausgegeben. 30 Tage später werden sie als \"Punkte-Guthaben\" aktiviert. (Wenn eine Bestellung mehrere Produkte umfasst, gilt das Datum, an dem das erste Produkt versandt wird). Die Anzahl der Punkte, die Sie für eine qualifizierende Bestellung erhalten, richtet sich nach der tatsächlichen Gesamtzahlung.
          <br/>
          <br/>
          Es wird darauf hingewiesen, dass ausstehende Punkte nicht für die Einlösung von offiziellen Website-Vorteilen verwendet werden können.
        `,
        "question_3": "3. Was ist zu tun, wenn meine Punkte nicht ordnungsgemäß auf meinem Konto gutgeschrieben wurden?",
        "answer_3": `Wenn Sie Fragen zu Ihrem Mitgliedskonto und Ihrem Punktestand haben, kontaktieren Sie bitte unseren Kundendienst unter <a class="text-primary hover:text-primary" href="mailto:hello.eu{'@'}jackery.com">hello.eu{'@'}jackery.com</a>.`,
        "question_4": "4. Verfallen die Punkte?",
        "answer_4": `
          Jackery-Punkte sind zwei Jahre lang gültig. Die von Benutzern gesammelten Punkte verfallen 2 Jahre nach dem offiziellen Start des Mitgliedschaftsprogramms (29. Juni 2023), und verfallene Punkte werden von ihrem Konto abgezogen.
          <br/>
          Um Ihre Rechte und Interessen zu schützen, prüfen Sie bitte rechtzeitig Ihre verfügbaren Punkte und lösen diese ein
        `,
        "question_5": "5. Erhalte ich Punkte für Transaktionen, die vor dem Start des Jackery-Mitgliedschaftsprogramms getätigt wurden?",
        "answer_5": "Ja! Die Jackery-Mitgliedschaft wird offiziell am 29. Juni 2023 auf den Markt gebracht. Sie erhalten die entsprechenden Original-Punkte für Ihre Einkäufe oder das Abschließen bestimmter Aufgaben auf der offiziellen Jackery-Website (ab 1. Januar 2020), sofern Sie sich als Jackery-Mitglied registrieren. Die Original-Punkte verfallen in 2 Jahren (ab dem 29. Juni 2023).",
        "question_6": "6. Können Steuern oder Versandgebühren auf die Punkte angerechnet werden?",
        "answer_6": "Nein. Bei der Berechnung der Punkte werden die vom Mitglied zu zahlenden Versandkosten und alle Steuern, einschließlich, aber nicht beschränkt auf Bundes-, Status- und lokale Steuern oder Verwendungssteuern, nicht berücksichtigt.",
        "question_7": "7. Was geschieht mit meinen Punkten, wenn ich Ihre Bestellung storniere, das Produkt zurückgebe oder eine teilweise Rückerstattung eines Produkts beantrage?",
        "answer_7": "Jede Bestellung, die storniert, zurückerstattet oder zurückgegeben wird, wird die Punkte, die Sie für diesen Kauf erhalten haben, stornieren oder verringern. Für Teilrückerstattungen, die durch Preiseinstellungen verursacht werden, werden Punkte abgezogen.",
        "question_8": "8. Besonderer Hinweis zu Website-übergreifenden Punkten / Wechselkurs / Punkteanzeigemethode",
        "answer_8": `
          Jackery-Punkte können auf allen offiziellen Jackery-Websites erworben und verwendet werden.
          <br/>
          Wenn Sie auf den verschiedenen offiziellen Websites von Jackery ein Punkteguthaben haben, zeigt das System den Gesamtpunktwert an, nachdem die Guthaben auf den verschiedenen Websites durch Wechselkurse umgerechnet wurden.
          <br/>
          Die Gesamtzahl der Punkte wird auf der Grundlage des aktuellen US-Dollar-Wechselkurses am 1. eines jeden Monats umgerechnet, so dass das Punkteguthaben jeden Monat entsprechend dem Wechselkurs wechselt.
          <br/>
          Die Punkte sind ganzzahlig, Bruchteile werden im Punktewert mitgezählt, werden aber nicht angezeigt und können nicht eingelöst werden.
        `,
      },
      "redeem": {
        "title": "Punkte einlösen",
        "setting1": "Benutzer können das Angebot bis zu {time} Mal in den letzten {num} Tagen einlösen.",
        "setting2": "Benutzer können nur {time} Mal einlösen.",
        "setting3": "Benutzer können Vorteile einlösen, solange sie ausreichendes Punkteguthaben haben.",
        "question_1": "1. Wie können Sie Jackery-Punkte für Vorteile einlösen?",
        "answer_1": `
          Sie können sich auf der Startseite (<a class="text-primary hover:text-primary" href="https://membership.jackery.com?CR=DE">membership.jackery.com</a>) anmelden. Die Vorteile, die Sie einlösen können, werden hervorgehoben. Sie können die Vorteile auswählen und auf \"Einlösen\" klicken.
          <br/>
          Wenn Sie sich bei Ihrem Kontocenter anmelden, können Sie auf 'Punktestand' klicken, um zur Startseite zu gelangen und Ihre Punkte für Vorteile einzulösen.
          <br/>
          Die Punkte können nicht zurückerstattet werden, sobald sie eingelöst wurden. Bitte stellen Sie sicher, dass Sie die Punkte vor der Bearbeitung einlösen wollen.
        `,
        "question_2": "2. Für welche Leistungen können Jackery-Punkte eingelöst werden?",
        "answer_2": `
          Wir bieten Ihnen eine Vielzahl von Vorteilen. Die Punkte können für Gutscheine, Geschenkkarten, Jackery Produkte, interessante Geschenke und mehr eingelöst werden. Die Vorteile gelten nur für die Website, auf der sie erhoben oder eingelöst wurden, und können nicht auf anderen Websites verwendet werden. Bitte verwenden Sie die Prämien innerhalb des Gültigkeitszeitraums. Einzelheiten finden Sie auf der Startseite für Mitglieder. Benutzer können Prämien innerhalb eines bestimmten Zeitraums nur eine begrenzte Anzahl von Malen einlösen. Sie erhalten eine Systemmeldung, wenn die Einlösegrenze erreicht ist. Sobald Sie einen neuen Zyklus beginnen, können Sie erneut einlösen.
          <br/>
          <br/>
          Hinweise
          <br/>
          ① Jackery Gutschein: Jeder Gutschein hat einen bestimmten Rabatt, einen Mindestbestellwert, zutreffende Produkte und eine Gültigkeitsdauer. Bitte prüfen Sie die detaillierten Daten zu jedem Gutschein, wenn Sie ihn verwenden. Gutscheine können nicht mit anderen Angeboten, einschließlich Sales, Coupons usw., kombiniert oder zusammengelegt werden. {0}
          <br/>
          ② Jackery Geschenkkarte: Jede Geschenkkarte hat einen bestimmten Nennwert und eine bestimmte Gültigkeitsdauer. Bitte prüfen Sie die Daten für jede Geschenkkarte, wenn Sie sie verwenden. {1}
          <br/>
          ③Jakcery-Produkt：Das Angebot darf nicht mit anderen Aktionen, einschließlich Schlussverkäufen und Gutscheinen, kombiniert oder zu anderen Aktionen addiert werden. Um das Angebot in Anspruch zu nehmen, geben Sie den Code und die entsprechende E-Mail-Adresse an der Kasse ein. Bitte beachten Sie, dass jeder Jackery Produktgutschein nur für den Kauf eines bestimmten Elements verwendet werden kann. {2}
          <br/>
          ④ Nicht-Jackery-Geschenkkarten: Es ist eine begrenzte Anzahl von Nicht-Jackery-Geschenkkarten verfügbar. Wenn der gesamte Bestand eingelöst wurde, erhalten Sie möglicherweise die Meldung \"nicht mehr vorrätig\" und können Ihre Punkte nicht mehr einlösen. Nicht-Jackery-Geschenkkarten werden nicht angezeigt, wenn sie nicht mehr vorrätig sind. Einzelheiten zu den Regeln für die Verwendung von Nicht-Jackery-Geschenkkarten finden Sie auf der offiziellen Website der entsprechenden Marke. {3}
        `,
        "question_3": "3. Wie prüfe ich die eingelösten Vorteile?",
        "answer_3": `
          ① Melden Sie sich bei der Kontoverwaltung (<a class="text-primary hover:text-primary" href="https://account.jackery.com?CR=DE">account.jackery.com</a>) an, prüfen Sie sie unter 'Meine Wallet'. Oder melden Sie sich auf der Startseite (<a class="text-primary hover:text-primary" href="https://membership.jackery.com?CR=DE">membership.jackery.com</a>) an, und prüfen Sie sie unter \"Meine Prämien\".
          <br/>
          ② Sie erhalten E-Mails mit Hinweisen zum Erheben von Rewards, einschließlich Gutscheinen, Geschenkkarten, Jackery-Produkten, etc
        `,
        "question_4": "4. Können meine Rewards, Vorteile und Punkte in Bargeld umgewandelt werden?",
        "answer_4": `
          Belohnungen, Vorteile und Punkte, die Sie im Rahmen des Jackery-Mitgliedschaftsprogramms erhalten, haben keinen Barwert, können nicht in Bargeld umgewandelt werden und können nicht auf eine andere Person übertragen werden. (Mit Ausnahme der Geschenkkarte, die Sie an Ihre Freunde oder Familienmitglieder schicken können.)
          <br/>
          Es ist nicht erlaubt, die von Ihnen eingelösten Vorteile an Dritte zu verkaufen.
          <br/>
          Jackery hat das Recht, gegen nicht autorisierte Transaktionen rechtlich vorzugehen.
        `,
        "question_5": "5. Muss ich für eingelöste Produkte Versandgebühren bezahlen?",
        "answer_5": "In den meisten Fällen bieten wir einen kostenlosen Versand für eingelöste Produkte an. Allerdings kann es sein, dass Sie für bestimmte Produkte Versandgebühren zahlen müssen.",
        "question_6": "6. Kann ich mit Punkten eingelöste Prämien stornieren, zurückgeben oder umtauschen?",
        "answer_6": `
          Sobald die Einlösung abgeschlossen ist, kann sie nicht mehr storniert werden. Die Prämien können nicht zurückgegeben oder umgetauscht werden.
          <br/>
          Wenn das Produkt beschädigt ankommt, kontaktieren Sie bitte den Kundendienst unter <a class="text-primary hover:text-primary" href="https://support.jackery.com?cr=DE" target="_blank">support.jackery.com</a> innerhalb von 7 Tagen
        `,
        "question_7": "7. Können die eingelösten Produkte mit Produkten versandt werden, die ich bei Jackery gekauft habe?",
        "answer_7": `
          Nein. Eingelöste Produkte (ein Artikel, den Sie durch Punkteeinlösung oder aus der Verlosung erhalten haben) werden separat versandt und innerhalb von 60 Arbeitstagen geliefert.
          <br/>
          Sie müssen, Ihre Adressendaten übermitteln oder bestätigen, damit die offizielle Website die Auslieferung rechtzeitig veranlassen kann.
        `,
      },
      "others": {
        "title": "Sonstiges",
        "question_1": "1. Kontaktieren Sie uns für weitere Hilfe",
        "answer_1": `
        Wenn Sie weitere Fragen haben, wenden Sie sich bitte per E-Mail an unseren Kundendienst unter <a class="text-primary hover:text-primary" href="mailto:hello.eu{'@'}jackery.com">hello.eu{'@'}jackery.com</a>, besuchen Sie <a class="text-primary hover:text-primary" href="https://support.jackery.com?cr=DE" target="_blank">support.jackery.com</a>, um Hilfe zu erhalten, oder klicken Sie, um uns zu kontaktieren.
        `,
        "question_2": "2. Behandlung von Verstößen gegen die Regeln für Jackery-Mitgliedschaftspunkte",
        "answer_2": `Wenn ein Nutzer böswillig oder falsch gehandelt hat, indem er Punkte erworben, verwendet oder Vorteile erlangt hat, behält sich Jackery das Recht vor, die Punkte des Nutzers einzufrieren oder zu löschen, die Dienstleistungen einzustellen oder das Konto des Nutzers zu löschen und den Antrag des Nutzers auf erneute Registrierung nicht zu akzeptieren.`,
        "question_3": "3. Besondere Erklärung",
        "answer_3": `
          Alle Rechte an der Jackery-Mitgliedschaft liegen bei Jackery.
          <br/>
          Mitgliedschaftsvorteile können sich periodisch ändern; bitte beachten Sie die Mitgliederhomepage für Details.
        `,
        "question_4": "4. Bösartige Registrierung (einschließlich der Verwendung falscher Informationen oder der Erstellung mehrerer Konten zur Erlangung zusätzlicher Belohnungen) führt zur Disqualifikation",
        "answer_4": `
          Jackery behält sich das Recht vor, Belohnungen oder Bestellungen, die auf folgende Weise erhalten wurden, zu stornieren:
          <br/>
          ① Verwendung falscher Informationen: Die Verwendung falscher Namen, Adressen, E-Mail-Adressen oder anderer persönlicher Informationen zur Kontenerstellung ist strengstens verboten.
          <br/>
          ② Erstellung mehrerer Konten: Es ist strengstens verboten, dass ein einzelner Benutzer mehrere Konten erstellt, um zusätzliche Belohnungen oder Rabatte zu erhalten. Dies schließt die Verwendung verschiedener E-Mail-Adressen oder jeglicher Identitätsinformationen zur Registrierung mehrerer Konten ein.
          <br/>
          ③ Automatisierte Registrierungswerkzeuge: Die Verwendung von automatisierten Werkzeugen oder Skripten für die Massenregistrierung von Konten ist strengstens verboten.
          <br/>
          ④ Bösartiges Verhalten: Jedes andere Verhalten, das als bösartig angesehen wird, einschließlich der Ausnutzung von Systemlücken oder der Beteiligung an betrügerischen Transaktionen, ist strengstens verboten.
        `,
      }
    }
  },
  // overview
  "overview": {
    "unit": 'Punkte',
    "pointsBalance": "Punktestand",
    "comingSoon": "Demnächst",
    "benefitsForYou": "Vorteile für Sie",
    "welcomeGift": "Willkommensgeschenk",
    "welcomeGiftInfo": "100 % Gewinn, Gutscheine/Geschenkkarten/aktuelle Artikel warten auf Sie!",
    "firstRegistration": "Erste Registrierung",
    "firstRegistrationInfo": " Sie erhalten {num} Rabatt bei Ihrer ersten Registrierung für die Mitgliedschaft bei Jackery.",
    "largeOrderBenefits": "Vorteile bei großen Bestellungen",
    "largeOrderBenefitsInfo": "Einmalig ein kostenloses Geschenk für Ihre Bestellung über {num}",
    "referral": "Freunde werben",
    "referralInfo": "Lassen Sie sich belohnen, wenn Ihre Freunde sich registrieren und einkaufen!",
  },
  // 我的钱包
  "wallet": {
    "unit": 'Punkte',
    "jackeryPointsBalance": "Punktestand",
    "comingSoon": "Demnächst",
    "pointsDetails": "Meine Aktivität",
    "redeemMyCoins": "Meine Punkte einlösen",
    "coupon": "Gutschein",
    "giftCard": "Geschenkkarte",
    "jackeryProducts": "Jackery Produkt",
    "extras": "Extras",
    "used": "Gebraucht",
    "expired": "Abgelaufen",
    "daysLeft": "{num} Tage übrig(Unbenutzt)",
    "un_used": "Unbenutzt",
    "partially_used": "Teilweise verwendet",
    "code": "Code",
    "rules": "Regeln",
    "copyAndUse": "Kopieren und verwenden",
    "noCoupon": "Keine Gutscheine verfügbar",
    "noGiftCard": "Keine Geschenkkarte verfügbar",
    "noJackeryProducts": "Kein Jackery Produkt Coupon verfügbar",
    "noExtras": "Keine Extras verfügbar",
    "redeemMyPoints": "Meine Punkte einlösen",
    "viewMore": "Mehr Erfahren",
    "couponRules": {
      "one": {
        "title": "1. Was ist ein Gutschein?",
        "step1": "1) Gutscheine sind Methoden für das Kundenfeedback, die Jackery durch Werbeaktionen, gezieltes Marketing, Einlösung von Mitgliedspunkten und andere Formen zur Reduzierung von Kaufzahlungen an die Benutzer verteilt.",
        "step2": "2) Die von Jackery verteilten Gutscheine sind elektronisch und können für den Kauf von Produkten auf der offiziellen Website von Jackery verwendet werden.",
        "step3": "3) Geben Sie beim Kauf und Bezahlen einer Bestellung den Rabattcode des Jackery-Gutscheins ein, und der Kartenrabatt/-betrag wird automatisch vom Gesamtbetrag der Bestellung abgezogen. Der Gutschein kann nicht in Bargeld umgewandelt werden.",
      },
      "two": {
        "title": "2. Wie können Sie Gutscheine überprüfen?",
        "step1": "1) Registrieren Sie sich als Jackery-Mitglied und überprüfen Sie die Gutscheine (einschließlich der Informationen über nicht genutzte, benutzte und abgelaufene Gutscheine) unter Benutzerzentrum - Meine Brieftasche - Gutscheine.",
        "step2": "2) Bitte beachten Sie die Gültigkeitsdauer des Gutscheins. Bitte verwenden Sie ihn innerhalb der Gültigkeitsdauer. Es erfolgt keine Rückerstattung, wenn er abgelaufen ist.",
      },
      "three": {
        "title": "3. Wie Sie den Gutschein verwenden?",
        "step1": "1) Jeder Gutschein hat einen spezifischen Rabatt, einen Mindestbestellwert, Produkte, für die der Gutschein gültig sind, und eine Gültigkeitsdauer. Bitte beachten Sie die weiteren Informationen jedes Gutscheins bei Verwendung.",
        "step2": "2) Websiten, auf denen der Gutschein verwendbar werden kann: Der Gutschein ist nur auf der Website gültig, auf der er gesammelt oder eingelöst wurde, und kann nicht auf anderen Websites verwendet werden.",
        "step3": "3) Es kann nur ein Gutschein für dieselbe Bestellung verwendet werden und kann nicht mit anderen Sonderangeboten kombiniert werden; Wenn eine Bestellung mehrere Artikel enthält, wird der Gutschein nur gegen die qualifizierten Artikel eingelöst.",
        "step4": "4) Einige Gutscheine sind an ein Konto gebunden und können nur für persönliche Konten verwendet werden. Die E-Mail-Adresse auf der Bestellseite muss mit der E-Mail-Adresse des Gutscheinempfängers übereinstimmen und kann nicht übertragen werden. Einige Gutscheine können an andere Benutzer verschenkt werden. Weitere Informationen finden Sie in der Gutschein-Nutzungsanweisungen.",
        "step5": "5) Der Gutschein kann zusammen mit Geschenkkarten verwendet werden.",
        "step6": "6) Mitglieder können Punkte gegen Gutscheine einlösen, und nach Abzug der Punkte sind sowohl Punkte als auch Gutscheine unwiderruflich. Es kann eine Begrenzung der Anzahl der Einlösungen von Mitgliedspunkten für Gutscheine innerhalb eines bestimmten Zeitraums geben.",
        "step7": "7) Wenn für die Bestellung ein Gutschein verwendet wird, kann der Gutschein im Falle einer Rückerstattung nicht zurückgegeben werden."
      },
      "four": {
        "title": "4. Umgang mit Verstößen gegen die Regeln für die Verwendung von Gutscheinen",
        "step1": "Wenn ein Benutzer einen gefälschten, gekauften, verkauften oder von einer anderen Person übertragenen Gutschein verwendet, hat Jackery das Recht, den Gutschein zu sperren oder für ungültig zu erklären, Bestellungen zu stornieren, für die Gutscheine verwendet wurden, die Qualifikation des Benutzers für die Geschenkkarte zu stornieren, die Erbringung von Dienstleistungen einzustellen oder das Konto des Benutzers zu löschen, und hat das Recht, den Antrag des Benutzers auf erneute Registrierung nicht zu akzeptieren."
      }
    },
    "giftRules": {
      "one": {
        "title": "1. Was ist eine Jackery-Geschenkkarte?",
        "step1": "1) Geschenkkarten sind Methoden für das Kundenfeedback, die Jackery durch Werbeaktionen, gezieltes Marketing, Einlösung von Mitgliedspunkten und andere Formen zur Reduzierung von Kaufzahlungen an die Benutzer verteilt.",
        "step2": "2) Die von Jackery verteilten Geschenkkarten sind elektronisch und können für den Kauf von Produkten auf der offiziellen Website von Jackery verwendet werden.",
        "step3": "3) Geben Sie beim Kauf und Bezahlen einer Bestellung den Rabattcode des Jackery-Gutscheins ein, und der Kartenrabatt/-betrag wird automatisch vom Gesamtbetrag der Bestellung abgezogen. Geschenkkarten können nicht in Bargeld umgewandelt werden.",
      },
      "two": {
        "title": "2. Wie können Sie eine Geschenkkarte überprüfen?",
        "step1": "1) Registrieren Sie sich als Jackery-Mitglied und überprüfen Sie die Gutscheine (einschließlich der Informationen über nicht genutzte, benutzte und abgelaufene Gutscheine) unter Benutzerzentrum - Meine Brieftasche - Gutscheine.",
        "step2": "2) Bitte beachten Sie die Gültigkeitsdauer der Geschenkkarte. Bitte verwenden Sie diese innerhalb der Gültigkeitsdauer. Es erfolgt keine Rückerstattung, wenn sie abgelaufen ist.",
      },
      "three": {
        "title": "3. Anweisungen zur Verwendung von Geschenkkarten:",
        "step1": "1) Jede Geschenkkarte hat einen bestimmten Nennwert und eine bestimmte Gültigkeitsdauer. Bitte beachten Sie die weitere Informationen jeder Geschenkkarte bei Verwendung.",
        "step2": "2) Websiten, auf denen die Geschenkkarte verwendbar ist: Die Geschenkkarte ist nur auf der Website gültig, auf der sie gesammelt oder eingelöst wurde, und kann nicht auf anderen Websites verwendet werden.",
        "step3": "3) Für die Verwendung einer Geschenkkarte ist kein Mindestbestellwert erforderlich. Sie gilt für alle Produkte.",
        "step4": "4) Geschenkkarten sind nicht auf die persönliche Nutzung des Kontos beschränkt und können auch als Geschenk an Freunde übertragen werden.",
        "step5": "5) Mehrere Geschenkkarten können für dieselbe Bestellung verwendet werden, können mit Sonderangeboten kombiniert werden und können zusammen mit Gutscheinen verwendet werden.",
        "step6": "6) Mitglieder können Punkte gegen Geschenkkarten einlösen, und nach Abzug von Punkten sind sowohl Punkte als auch Geschenkkarten unwiderruflich.",
        "step7": "7) Die Anzahl der Punkte, die innerhalb eines bestimmten Zeitraums gegen Geschenkgutscheine eingelöst werden können, kann begrenzt sein, und das System wird Sie daran erinnern, wenn die Anzahl der Punkte aufgebraucht ist.",
        "step8": "8) Wenn für die Bestellung eine Geschenkkarte verwendet wird, kann die Geschenkkarte im Falle einer Rückerstattung nicht zurückgegeben werden.",
      },
      "four": {
        "title": "4. Umgang mit Verstößen gegen die Regeln für die Verwendung von Geschenkkarten",
        "step1": "Wenn ein Benutzer eine gefälschte, gekaufte, verkaufte oder von einer anderen Person übertragene Geschenkkarte verwendet, hat Jackery das Recht, die Geschenkkarte zu sperren oder für ungültig zu erklären, Bestellungen zu stornieren, für die Geschenkkarten verwendet wurden, die Qualifikation des Benutzers für die Geschenkkarte zu stornieren, die Erbringung von Dienstleistungen einzustellen oder das Konto des Benutzers zu löschen, und hat das Recht, den Antrag des Benutzers auf erneute Registrierung nicht zu akzeptieren."
      }
    },
    "productRules": {
      "one": {
        "title": "1. Was ist ein Jackery-Produkt-Gutschein?",
        "step1": "1) Jackery-Produkt-Werbegeschenke sind eine Art von Gutschein, den Jackery durch Werbeaktionen, gezieltes Marketing, Einlösung von Mitgliedspunkten und andere Formen als Feedback an Kunden verteilt.",
        "step2": "2) Das von Jackery verteilte Jackery-Produkt sind elektronische Gutscheine und können durch vollständige oder teilweise Gutschriftenreduktion für entsprechende Produkte auf der offiziellen Website von Jackery erworben werden.",
        "step3": "3) Geben Sie beim Kauf und bei der Bezahlung einer Bestellung den Rabattcode des Jackery-Produktes ein, und der Kartenrabatt/-betrag wird automatisch vom Gesamtbetrag der Bestellung abgezogen. Gutscheine können nicht in Bargeld umgewandelt werden.",
      },
      "two": {
        "title": "2. Wie können Sie den Jackery-Produkt-Gutschein überprüfen?",
        "step1": "1) Registrieren Sie sich als Jackery-Mitglied und überprüfen Sie die Gutscheine (einschließlich der Informationen über nicht genutzte, benutzte und abgelaufene Gutscheine) unter Benutzerzentrum - Meine Brieftasche - Gutscheine.",
        "step2": "2) Bitte beachten Sie die Gültigkeitsdauer des Jackery-Produkt-Gutscheins. Bitte verwenden Sie ihn innerhalb der Gültigkeitsdauer. Es erfolgt keine Rückerstattung, wenn er abgelaufen ist.",
      },
      "three": {
        "title": "3. Anweisungen zur Verwendung von Jackery-Produkt-Gutscheinen:",
        "step1": "1) Jeder Jackery-Produkt-Gutschein hat eine bestimmte Gültigkeitsdauer. Bitte beachten Sie die weiteren Informationen jeder Geschenkkarte bei Verwendung.",
        "step2": "2) Websiten, auf denen Jackery-Produkt-Gutscheine verwendbar sind: Jackery-Produkt-Gutscheine sind nur auf der Website gültig, auf der sie angefordert oder eingelöst wurden, und können nicht auf anderen Websites verwendet werden.",
        "step3": "3) Es kann nur ein Gutschein für dieselbe Bestellung verwendet werden und kann nicht mit Sonderangeboten kombiniert werden. Wenn mehrere Produkte auf derselben Bestellung erscheinen, gilt der Gutschein nur für das qualifizierte Produkt.",
        "step4": "4) Der Jackery-Produkt-Gutschein ist an ein Konto gebunden und kann nur für persönliche Konten verwendet werden. Die E-Mail-Adresse auf der Bestellseite muss mit der E-Mail-Adresse des Gutscheinempfängers übereinstimmen und kann nicht übertragen werden.",
        "step5": "5) Mitglieder können Punkte gegen Jackery-Produkt-Gutscheine einlösen, und nach Abzug der Punkte sind sowohl Punkte als auch Gutscheine unwiderruflich. Es kann eine Begrenzung der Anzahl der Einlösungen von Mitgliedspunkten für Gutscheine innerhalb eines bestimmten Zeitraums geben.",
        "step6": "6)  Wenn für die Bestellung ein Gutschein verwendet wird, kann der Gutschein im Falle einer Rückerstattung nicht zurückgegeben werden."
      },
      "four": {
        "title": "4. Umtauschbedingungen für Jackery-Produktgeschenke",
        "step1": "1) Loggen Sie sich auf der offiziellen Website von Jackery ein und gehen Sie auf die entsprechende Produktdetailseite, klicken Sie auf Kaufen",
        "step2": "2) Geben Sie den entsprechenden Rabattcode auf der Kassenseite ein (Fügen Sie den Code {XXX} an der Kasse ein)",
        "step3": "3) Bitte beachten Sie, dass die E-Mail auf der Kassenseite die E-Mail für den Erhalt von Gutscheinen ist."
      },
      "five": {
        "title": "5. Umgang mit Verstößen gegen die Regeln für die Verwendung vom Gutschein",
        "step1": "Wenn ein Benutzer einen gefälschten, gekauften, verkauften oder von einer anderen Person übertragenen Gutschein verwendet, hat Jackery das Recht, die Geschenkkarte zu sperren oder für ungültig zu erklären, Bestellungen zu stornieren, für die Geschenkkarten verwendet wurden, die Qualifikation des Benutzers für den Gutschein zu stornieren, die Erbringung von Dienstleistungen einzustellen oder das Konto des Benutzers zu löschen, und hat das Recht, den Antrag des Benutzers auf erneute Registrierung nicht zu akzeptieren."
      }
    },
    "extraRules": {
      "one": {
        "title": "1. Was sind Jackery Extras?",
        "step1": "1) Jackery Extras sind Geschenke anderer Marken als Jackery, einschließlich Coupons, Gutscheine, Geschenkkarten und physische Geschenke.",
        "step2": "2) Jackery verteilt sie durch Werbeaktionen, gezieltes Marketing, Einlösung von Mitgliedspunkten und andere Formen als Feedback an die Kunden. Nach dem Einlösen von Punkten für Jackery Extras werden die Punkte abgezogen, und sowohl Punkte als auch Geschenke können nicht zurückgezogen werden.",
      },
      "two": {
        "title": "2. Wie überprüfen Sie die Jackery Extras?",
        "step1": "1) Registrieren Sie sich als Jackery-Mitglied und überprüfen Sie sie unter Benutzerzentrum - Meine Brieftasche - Extras.",
        "step2": "2) Redeem your offers within the validity period. It can not be reissued once it has expired.",
        "step3": "3) Die Einzelheiten des Angebots werden per E-Mail verschickt. Der Antragsteller kann die entsprechenden Informationen in seiner Mailbox einsehen."
      },
      "three": {
        "title": "3. Fordern Sie das Jackery Extra-Geschenk an",
        "step1": {
          "title": "1) Regeln für die Inanspruchnahmme eines physischen Geschenks:",
          "tip1": "① Physische Nicht-Jackery-Geschenke müssen deutlich in der Adresse ausgefüllt werden.",
          "tip2": "② Ihr Artikel wird innerhalb von 30-90 Tagen nach Inanspruchnahme verschickt.",
          "tip3": "③ Die Versanddetails, einschließlich der Tracking-Nummer, werden Ihnen per E-Mail zugesandt, sobald der Artikel versandt wurde. ",
          "tip4": "④ Sollten die erhaltenen Preise beschädigt sein oder andere Probleme aufweisen, wenden Sie sich bitte an unseren Kundendienst.",
        },
        "step2": {
          "title": "2) Überlegungen zu virtuellen Geschenkkarten anderer Marken als Jackery",
          "tip1": "① Einzelheiten zu virtuellen Geschenkkarten anderer Marken als Jackery, einschließlich ‚Was und Wie‘, werden per E-Mail gesendet.",
          "tip2": "② Die Frist für den Erhalt von virtuellen Geschenkkarten anderer Marken als Jackery beträgt XX Jahre, die bei Erhalt aktiviert wird. Nach der Aktivierung wird die Gültigkeitsdauer der Geschenkkarte auf der Grundlage der von der entsprechenden Marke angegebenen Gültigkeitsdauer bestimmt. Einzelheiten finden Sie auf der Website der jeweiligen Marke.",
          "tip3": "③ Die spezifischen Nutzungsregeln für virtuelle Geschenkkarten anderer Marken als Jackery finden Sie in der Einführung in die Regeln für die Plattform zum Einlösen von Geschenkkarten",
        }
      },
      "four": "4. Im Falle von Streitigkeiten behält sich Jackery das Recht auf endgültige Entscheidungen vor."
    }
  },
  // My Service
  "service": {
    'imgText1': 'Willkommen in Jackery',
    'imgText2': 'Support',
    'pcImage': 'https://cdn.shopifycdn.net/s/files/1/0550/0524/9633/files/My_Service_f2d45c3c-6199-4b74-85d8-bc1977a5d9b9.png?v=1683191312',
    'mbImage': 'https://cdn.shopifycdn.net/s/files/1/0550/0524/9633/files/My_Service_efc7e4e4-a728-40e4-ab7b-31aab55839af.png?v=1683191345',
    'text': 'Gehen Sie zum Jackery Support für Smart Service.',
    'contactUs': 'Kontaktieren',
    'viewSupport': 'Zum Kundendienst gehen',
  },
  // My Extended Warranty
  "myExtendedWarranty":{
    "emptyTips":"No extended warranty information",
    "shopNow":"Shop Now",
    "sn":"SN for the extended warranty product",
    "notBound":"Not yet bound.",
    "activate":"Activate",
    "underReview":"Under review. Please be patient.",
  },
  // My Jackery Life
  "jackeryLife": {
    'title': 'Meine Teilung',
    'imgText1': '',
    'imgText2': '',
    'pcImage': 'https://cdn.shopify.com/s/files/1/0572/7217/2741/files/PC-DE_500x280__png.png?v=1721620476',
    'mbImage': 'https://cdn.shopify.com/s/files/1/0572/7217/2741/files/PC-DE_500x280__png.png?v=1721620476',
    'text': 'Poste ein Foto oder Video mit Jackery-Produkten und deiner Nutzungserfahrung, um die Chance auf eine 100€ Geschenkkarte zu haben.',
    'learnMore': 'Mehr erfahren',
  },
  "wishlist": {
    "empty": "Keine Produkte in der Wunschliste",
    "shopNow": "JETZT KAUFEN",
    "modalTitle": "Sind Sie sicher, dieses Produkt aus Ihrer Wunschliste zu entfernen? ",
    "modalCancel": "Abbrechen",
    "modalOk": "Bestätigen",
    "removeToast": "Das Produkt wurde aus Ihrer Wunschliste entfernt.",
    "createdDesc": "Hinzugefügt am Neuesten",
    "createdAsc": "Hinzugefügt am Ältesten",
    "priceDesc": "Preis Hoch bis Niedrig",
    "priceAsc": "Preis Niedrig bis Hoch",
    "purchased": "GEKAUFT",
    "buyNow": "JETZT KAUFEN",
    "selectAll": "Alles auswählen",
    "selected": "Ausgewählt",
    "amount": "Gesamtsumme",
    "addOn": "Hinzugefügt am",
    "notGoodsGotoBuy": 'Bitte wählen Sie einen Artikel aus',
    "viewMoreHref": "https://de.jackery.com/collections/all"
  },
  // Trade-in
  "trade-in": {
    "title": "My Trade-In",
    "orderNumber": "Bestellnummer",
    "productModel": "Produktmodell",
    "acutalProductModel": "Aktuelles Produktmodell",
    "trackingNo": "Tracking Number",
    "level": "Aktuelles Produktniveau",
    "estimatedPrice": "Schätzpreis",
    "transactionPrice": "Transaktionspreis",
    "cardCode": "Geschenkkartencode",
    "priceConfirmation": "Preisbestätigung",
    "startDate": "Startdatum",
    "endDate": "Endtermin",
    "detailPage": "Detail",
    "productInformation": "Detail Information",
    "timeLine": "Upgrade-Fortschritt",
    "date": "Datum",
    "acceptText": "Danke für deine Bestätigung. Sie können Ihre Geschenkkarte jetzt verwenden.",
    "acceptNote": "*Bitte beachten Sie, dass die Geschenkkarte nur 10 Tage gültig ist.",
    "rejectText": "Danke für deine Bestätigung. Da Sie das Produktangebot nicht annehmen, können wir Ihnen keinen Geschenkgutschein ausstellen.",
    "rejectNote": `Kontaktieren Sie  <a class="text-primary hover:text-primary" href="mailto:hello.eu{'@'}jackery.com">hello.eu{'@'}jackery.com</a>   für die Produktrückgabe (*bitte beachten Sie, dass die Kosten für die Rücksendung zu Ihren Lasten gehen)`,
    "closed": "Geschlossen",
    "empty": "No information.",
    "sendShippingLabel": "Versandetikett senden",
    "packageInTransit": "Paket im Transport",
    "packageDelivered": "Package Delivered",
    "inspectionAndVerification": "Inspektion und Verifizierung",
    "QuoteConfirmation": "Angebotsbestätigung",
    "issuingGiftCard": "Geschenkkarte ausstellen",
    "productReturn": "Produktrückgabe",
    "orderCompleted": "Bestellung abgeschlossen",
    "orderCancelled": "Bestellung storniert",
    "viewMore": "Mehr sehen",
    "awaitingFeedback": "Erwarte Feedback",
    "orderStatus": "Bestellstatus",
    "copy": "Kopieren"
  },
  "old-invite-new": {
    "myRewardsTitle": "Meine Prämien",
    "myRewardsSubTitle": "Verwalten und verwenden Sie hier Prämien, auf die Sie Anspruch haben. Verfallene und bereits verwendete Prämien prüfen Sie bitte in Mein Wallet.",
    "myRewardsNoData": "Laden Sie Ihre Freunde ein, sich anzumelden oder etwas zu kaufen, und fordern Sie Ihre Prämien im Dashboard unten an.",
    "viewMore": "Mehr Ansehen",
    "myReferralsTitle": "Meine Empfehlungen",
    "myReferralsSubTitle": "Verfolgen Sie den Fortschritt der Empfehlung und nehmen Sie die Belohnung in Anspruch. Klicken Sie auf die Belohnung, um weitere Informationen zu erhalten.",
    "myReferralsNoData": "Laden Sie Ihre Freunde ein, sich anzumelden oder einen Einkauf zu tätigen, und fordern Sie dann hier Ihre Belohnungen an.",
    "claim": "Anspruch",
    "claimed": "Anspruch geltend gemacht",
    "expired": "Abgelaufen",

    // 补充
    "myReferral": "Meine Empfehlung",
    "enterEmail": "Geben Sie Ihre E-Mail-Adresse ein",
    "invalidEmail": "Ungültige E-Mail-Adresse",
    "messageSent": "Nachricht gesendet",
    "copy": "Kopieren",
    "copied": "Kopiert",
    "copiedSuccess": "erfolgreich kopiert.",
    "friendEmail": "Geben Sie die E-Mail Adresse Ihres Freundes ein",
    "referee": "Empfohlene(r)",
    "referralType": "Empfehlungstyp",
    "rewards": "Prämien",
    "status": "Status",
    "instagramCopySuccessTip": "Der Empfehlungslink wurde kopiert, eingefügt und auf Instagram geteilt",
  }
}
