import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index';
import store from './store';
import vueI18n from './lang';
import { cookie } from "./utils/common";

import "./permission"

import 'ant-design-vue/es/message/style/css';
import 'ant-design-vue/es/notification/style/css';
import 'ant-design-vue/es/badge/style/css';

// reset style
import './assets/main.less';
import './utils/CDP.js'

import '@unocss/reset/tailwind-compat.css'
import 'virtual:uno.css'

// 引入Jackery Business Components业务组件库
import "@jackery/biz-components/dist/index.css"
import "@jackery/biz-components/dist/index.es.js"

// 本地联调模式切换
// import '@jackery/biz-components/src/styles/theme.less'
// import '@jackery/biz-components/src/index.ts'


/** 页面首次加载时, 处理vuex状态, 分配路由, 设置主题等 start ----------- */
// localStorage.getItem('JACKERY_ID_USER') && store.commit('changeUser', JSON.parse(localStorage.getItem('JACKERY_ID_USER')));
// let meta = document.createElement('meta');
// meta.name = "version";
// meta.content = `v${config.version}`;
// document.getElementsByTagName('head')[0].appendChild(meta);
/** 页面首次加载时, 处理vuex状态, 分配路由, 设置主题等 end ----------- */


// 设置语言
function setLanguage() {
  const _lang = cookie.get('_jk_lang') || localStorage.getItem('JACKERY_ID_LANG') || 'en-US';
  document.querySelector('html').setAttribute('lang', _lang.substring(0, 2));
  return _lang;
}

// 动态加载字体文件
function loadFontIfNeeded(lang) {
  if (lang === 'ja-JP') {
    return new Promise((resolve, reject) => {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = '/jp-font.css';
      link.onload = resolve; // 字体加载完成后 resolve
      link.onerror = reject; // 字体加载失败后 reject
      document.head.appendChild(link);
    });
  }
  return Promise.resolve(); // 如果不是日语，直接 resolve
}

// 初始化应用
function initializeApp() {
  // jackery_account_env
  if (import.meta.env.MODE === 'production') { window.jackery_account_env = "prod" }
  if (import.meta.env.MODE === 'demo') { window.jackery_account_env = "demo" }
  if (import.meta.env.MODE === 'uat') { window.jackery_account_env = "uat" }
  console.log('welcome to jackery. For more information about us, please follow https://www.jackery.com/pages/about-us');
  
  const app = createApp(App);
  app.use(store).use(router).use(vueI18n).mount('#app');
}

// 主逻辑
const lang = setLanguage();
loadFontIfNeeded(lang)
  .then(() => {
    initializeApp();
  })
  .catch((error) => {
    console.error('字体加载失败:', error);
    initializeApp(); // 即使字体加载失败，也继续初始化应用
  });

