export default {
  "login": {
    "sign": "ログイン",
    "login": "すぐ登録",
    "remember": "私を覚えてますか",
    "about": "私たちに関しては",
    "illegal_email": "メールアドレスを正しく入力してください。",
    "illegal_password": "パスワードには6〜16桁の文字と数字が含まれている必要があります。",
    "password_matching": "パスワードが一致しません。",
    "enterEmail": "メールアドレス/Jackery ID",
    "enter_code": "認証コード",
    "forgot": "パスワードをお忘れですか?",
    "create": "新規会員登録はこちら",
    "thirdLogin": "または外部サービスでログイン",
    "accountActivate": "アカウントをアクティブ化してください",
    "bindEmail": "メールをバインドする",
    "error": "ログインに失敗しました。しばらくしてからもう一度お試しください",
    "quickLogin": "パスワードなしでサインイン",
    "quickLoginA": "パスワードなしでログイン"
  },
  "policies": {
    "content": "外部サービスでログインすることにより、当社の",
    "policy-link": "https://www.jackery.jp/pages/privacy",
    "policy": "プライバシーポリシー",
    "and": "と",
    "terms-link": "https://www.jackery.jp/pages/terms-of-service",
    "terms": "利用規約",

    "comma": "",
    "footer": "に 同意したこと、ならびに外部サービスによって提供された情報を当社が利用することを承諾したものとします。"
  },
  "reset": {
    "modify": "パスワードの変更",
    "back": "会員登録へ戻る",
    "success": "パスワードの再設定が完了しました。",
    "referrer": "元のページに戻ります",
    "seconds": "秒"
  },
  "register": {
    "title": "新規会員の登録",
    "enterEmail": "メールアドレス",
    "agree": "に同意のうえ、確認画面へお進みください",
    "agree_after": "",
    "getDeals": "メールマガジンの配信に同意する",
    "login": "簡単ログイン",
    "success": "あなたのアカウント '{email}' が登録されました。会員登録ありがとうございます！",
    "welcomeEmail": "登録完了メールを送信しました。"
  },
  "header": {
    "logout": 'ログアウト',
    "userCenter": "アカウントセンター",
    "personalCenter": "マイページ",
    "shopping": "ストアへ",
    "setting": "設定",
    "myJackery": "アカウントセンター",
    "jackeryID": "マイページ",
    "wishlist": "欲しいものリスト",
  },
  "footer": {
    "cookieContent": "当社のウェブサイトではCookieを使用して収集した情報を利用して、お客様のウェブサイトの利用状況（アクセス状況、トラフィック、ルーティング等）を分析し、ウェブサイト自体のパフォーマンス改善や、当社からお客様に提供するサービスの向上、改善のために使用することがあります。",
    "cookieUrl": "#",
    "learn": "もっと詳しく",
    "accept": "cookiesを受け入れる",
    "copyright": "Copyright © {date} 株式会社Jackery Japan All Rights Reserved.",
    "sitemap-link": "#",
    "sitemap": "サイトマップ"
  },
  "account": {
    "welcome": "ようこそ",
    "email": "メールアドレス",
    "registerEmail": "メールを登録する",
    "bindNewRegisterEmail": "新しいメールアドレス",
    "emailIsEqual": "新しいメールアドレスは元のメールアドレスと同じで変更できません。",
    "settings": "設定",
    "settingTips": "1つの Jackery ID とパスワードで、Jackeryのサービスすべてにアクセスできます。",
    "titleProfiles": "個人情報",
    "titleSecurity": "アカウントセキュリティ",
    "titleAuthorization": "アカウント連携",
    "nickname": "ユーザーネーム",
    "name": "氏名",
    "gender": "性别",
    "birthday": "生年月日",
    "contactEmail": "連絡用メールアドレス",
    "contactPhone": "電話番号",
    "enterprise": "ビジネスアカウント",
    "setted": "設定済",
    "unset": "未設定",
    "password": "パスワード",
    "pop_password": "パスワードを作成する",
    "avatarSettings": "プロフィール画像編集",
    "choosePicture": "画像を選択",
    "apply": "確認",
    "secrecy": "回答しない",
    "male": "男性",
    "female": "女性",
    "currentPassword": "現在のパスワード",
    "newPassword": "新しいパスワード",
    "newShowAgain": "二度と表示しない",
    "againPassword": "新しいパスワードを確認する",
    "jackeryIdTips": "JackeryIDを1回のみ変更できます，Jackery IDは6～32文字以内で設定してください",
    "birthdayTips": "",
    "nickNameTips": "「ユーザーネーム」の長さは32文字以内で設定してください。",
    "nameTips": "",
    "genderTips": "",
    "contactEmailTips": "ご注文内容や配送について、メールにてご連絡させていただく事があります。",
    "phoneNumberTips": "ご注文内容や配送について、電話にてご連絡させていただく事があります。",
    "phoneNumberTips2": "電話番号を入力してください (数字、ハイフンのみ入力可)。 \n ※+81を選択し、携帯電話の先頭の0を省いた番号をご入力ください。",
    "enterpriseNameTips": "ビジネスアカウントをご利用の場合は、より良いサービス提供のために、お客様のビジネス情報をお知らせください。",
    "emailTips": "メールアドレスを変更するには、セキュリティ認証が必要です。",
    "passwordTips": "セキュリティ上の理由により、他サイト等で使用しているパスワードの使用は控えてください。",
    "location": "国/地域",
    "myProfile": "プロフィール",
    "changePassword": "パスワード変更",
    "creatPassword": "パスワードを作成する",
    "firstName": "名",
    "lastName": "姓",
    "editPasswordTips": "パスワードは6～16桁で、アルファベットと数字を含む必要があります。セキュリティのため、強力なパスワードを設定してください。",
    "subscriberTip": "初回メルマガ登録で { num } ポイントを獲得し、Jackeryの最新ニュースやキャンペーンを受信します。Jackeryのサービス利用規約とプライバシーポリシーに同意します。",
    "birthdayTip1": "誕生日記入で{num}ポイントを獲得。",
    "birthdayTip2": "誕生日ごとに{num}ポイントが付与され、お買い物ポイントが2倍になります。",
    "birthdayTip3": "誕生日は１回のみ記入できます。",
    "phoneTip": "初回SMS購読で {num} ポイントを獲得し、Jackeryの最新ニュースやキャンペーンを受信します。Jackeryのサービス利用規約とプライバシーポリシーに同意します。",
    "countryRegion": "国名|地域名",
    "successTip1": "プロフィールの編集に成功しました。{num} Jackeryポイントは24時間以内に付与されます。",
    "successTip": "プロフィールの編集に成功しました。",
    "errorTip": "編集が失敗しました。ご確認の上、再度送信してください。"
  },
  "router": {
    "memberIndex": "MemberShip",
    "memberGuide": "MemberShip Guide",
    "home": "ホーム",
    "404": "404",
    "500": "500",
    "about": "about",
    "register": "会員登録",
    "login": "ログイン",
    "reset": "パスワードの変更",
    "dashboard": "Dashboard",
    "commonSetting": "一般設定",
    "account": "会員情報",
    "accountOverview": "概要",
    "myProfile": "プロフィール",
    "myOrders": "注文履歴",
    "personalSettings": "個人設定",
    "addressBook": "お届け先",
    "accountSettings": "設定",
    "myDevices": "デバイス",
    "myExtendedWarranty":"私の延長保証",
    "activate": "アカウントを有効化する",
    "myWallet": "ウォレット",
    "myService": "サービス",
    "myReturn": "返品／交換",
    "myJackeryLife": "Jackery Life",
    "myWishlist": "欲しいものリスト",
    "myReferral": "私の招待",
  },
  "description": {
    "memberIndex": "Jackery",
    "memberGuide": "Jackery",
    "home": "Jackery",
    "404": "404",
    "500": "500",
    "about": "about",
    "register": "1つの Jackery ID とパスワードで、Jackeryの会員サービスすべてにアクセスできます。",
    "login": "会員登録",
    "reset": "パスワードの変更",
    "dashboard": "Dashboard",
    "commonSetting": "一般設定",
    "account": "Jackery ID-会員情報",
    "accountOverview": "概要",
    "myProfile": "プロフィール",
    "myOrders": "注文履歴",
    "personalSettings": "個人設定",
    "addressBook": "送り先",
    "accountSettings": "アカウント設定",
    "myDevices": "デバイス",
    "activate": "パスワードを設定することでアカウントを有効にします。",
    "registerActivate": "アカウントをアクティブ化するようにをクリックし、ログインを行うことができます。",
    "myReturn": "返品／交換",
    "myWallet": "ウォレット",
    "myService": "サービス",
    "myJackeryLife": "Jackey Life"
  },
  "operate": {
    "enable": "有効",
    "disable": "無効",
    "confirm": "確認",
    "cancel": "キャンセル",
    "remove": "削除",
    "search": "検索",
    "reset": "リセット",
    "add": "追加",
    "close": "閉じる",
    "edit": "編集",
    "yes": "はい",
    "no": "いいえ",
    "submit": "送信",
    "next": "次へ",
    "prev": "前へ",
    "back": "戻る",
  },
  "tips": {
    "sure": "本気ですか?",
    "placeholder": "入力してください。",
    "enterConsultContent": "お問い合わせの内容を入力ください。",
    "all": "すべて",
    "custom": "カスタマイズ",
    "pending": "処理対象",
    "loading": "処理中...",
    "loading2": "処理中",
    "loaded": "成功に処理しました。",
    "loaded2": "完了",
    "cancelled": "キャンセル",
    "changeShop": "店舗切り替え中",
    "logoutConfirm": "本当にログアウトしますか?",
    "loggedOut": "ログアウトしました",
    "refreshed": "リフレッシュ",
    "noDescribe": "説明なし",
    "reminder": "エラー",
    "commError": "エラー発生。",
    "reLogin": "もう一度ログインしてください。",
    "verify": "認証コード", "sendVerify": "認証コードを入力されたメールアドレス宛に送信しました。メールをご確認ください。もしも届かない場合は念のため、迷惑フォルダもご確認ください。",
    "getVerifyCode": "認証コードを送る",
    "verifyError": "認証コードに誤りがあります。",
    "homePage": "ホームページ",
    "404": "ページが見つかりませんでした。",
    "404Tips": "ご記入されたURLを確認して、アクセスするようにクリックしてください。",
    "500": "エラー発生。",
    "500Tips": "サーバーエラー,",
    "500Tips2": "後で試してください。",
    "urlExpired": "確認リンクの有効期限が切れました",
    "accountNotActivated": "アカウントをアクティブ化してください。",
    "accountActivatedSuccess": "アカウントを有効化になりました。",
    "requestFrequently": "頻繁にクリックしないでください。認証されたら再試行してください。",
    "submitSuccess": "提出に成功しました。",
    "zipVerifyError": "郵便番号の確認に失敗しました",
    "otherError": "エラー出てきました, 管理者に連絡してください. code: {code}",
    "wrongPasswordMultipleTimes": "入力したパスワードが多すぎます。 {minute}分後にもう一度やり直してください。",
    "networkError": "ネットワークエラー",
    "thirdLoginError": "情報の取得中にエラーが発生しました。しばらくしてから、もう一度お試しください。",
    "fileTypeError": "ファイル タイプ エラー、アップロードのみが許可されています: {type} タイプ ファイル",
    "copySuccessfully": "コピーしました"
  },
  "placeholder": {
    "email": "メールアドレス",
    "emailOrJackeryID": "メールアドレス/Jackery ID",
    "password": "パスワード",
    "passwordAgain": "確認のため再度ご入力ください",
    "enterprise": "ビジネスアカウント",
    "code": "認証コード",
    "firstName": "名",
    "lastName": "姓",
    "select": "選んでください",
    "address1": "市区町村名（例：千代田神田神保町）",
    "address2": "番地・ビル名（例：1-3-5）",
    "country": "国",
    "province": "都道府県を",
    "city": "市",
    "region": "地域",
    "zip": "郵便番号",
    "zipError": "無効な郵便番号",
    "phone": "電話番号",
    "company": "会社名（任意）",
    "isNotEmpty": "入力してください",
    "pobox": "私書箱への配送は行っておりません",
    "minChar2": "2文字以上",
    "inputChar": "{length} 文字の文字長"
  },
  "order": {
    "sn": "注文番号",
    "detail": "配送の詳細",
    "productName": "製品名",
    "quantity": "個数",
    "price": "価格",
    "status": "注文状況",
    "deliverTime": "発送予定",
    "consult": "お問い合わせ",
    "contact": "お問い合わせ",
    "total": "合計",
    "empty": "注文履歴がありません",
    "questionType": "問題のタイプ",
    "shipped": "出荷済み",
    "return": "返品/交換",
    "applied": "返品返品完了",
    "revoke": "取り消す",
    "serviceType": "下記よりご希望のオプションサービスをご選択ください。",
    "serviceType_1": "返品返金",
    "serviceType_2": "交換を希望する",
    "packageArrived": "配送状況の確認",
    "reason": "以下より返品/交換理由をご選択ください。",
    "reason_1": "ご注文日から{day}日以内である",
    "reason_2": "商品不具合",
    "reason_3": "その他",
    "quality": "お届けした商品状況について下記より適切なものをご選択ください。",
    "productSerialNumber": "製品のシリアル番号をご入力ください。",
    "selectReturnProduct": "返品商品を選択してください",
    "returnQuantity": "数量をご入力ください",
    "shippingAddress": "お届け先",
    "returnAddress": "商品返送先",
    "revise": "編集",
    "uploadVideo": "動画添付",
    "uploadLoading": "アップロードしています",
    "videoLimit": "ファイル サイズの上限は {limit} メガバイト (MB) です。",
    "uploadVideoError": "エラーになりました。",
    "failureDetermination": "Product quality troubleshooting",
    "steps_1": "1. Power station troubleshooting steps",
    "steps_1_content": "1) Please press the display to see if the indicator light is on.\n2) Please hold press the power button (named display) for 10 seconds to reset the power station and place it somewhere for a while before recharging the power station.\n3) Press the button on the top of the AC/DC outlet to check if the light is on before you plug in your device and have it charged.\n4) Please check if your device can be charged normally again.\n\nIf the above doesn't work, please send us the following information so that we can help you further.\n1) The order number;\n2) The serial number(either on the bottom of the power station or the upper right corner on the back of the station.);\n3) A video that can show the problem clearly(upload to Google Drive and send us the link).\n4) Your current address.\n\n",
    "steps_2": "2. Solar panel troubleshooting steps",
    "steps_2_content": "1) Please use the provided AC charger to charge the power station to see if it can work.\n2) Please test under midday strong sunlight. Connect the solar panel with the power station, make sure there is no shadow cast on the panel.\n\nIf the power station can be recharged well by AC charger, please send us the following information below so that we can help you further.\n1) The order number;\n2) The serial number(either below the handle bar code or at the opposite side of the USB port.);\n3) A video that can show the problem clearly(upload to Google Drive and send us the link).\n4) Your current address.",
    "learnMore": "その他",
    "shrink": "折り畳み",
    "noReason_30_day": "1. Jackery offers a 30-day money back guarantee for goods purchased directly from Jackery.com. We are confident that you will love your new Jackery product. However, if you are unsatisfied for any reason, simply request a return for a replacement or refund within 30 days. \n\n2. Please be noted: returns without reason exceeding 30 days will not be accepted.\n\n3. In order to return your product under the 30-day return policy, please check that: Make sure the product is returned in the original packaging, with all materials included, in new or as-new condition.",
    "selectDelivery": "運輸会社をお選びください",
    "enterTrackNumber": "伝票番号",
    "illegal_trackNumber": "数字のみをご入力ください",
    "enterDescription": "具体的な説明を入力してください",
    "comingSoon": '獲得予定ポイント',
    "cancelled": 'キャンセル済',
    "points": "ポイント",
    "comingSoonTip": ' (Jackeryポイントは商品発送後、31日目に発行されます）',
    "pointsBalance": "ポイント残高",
    "tipTitle": '会員特典',
    "shopNow": "今すぐ購入",
    "tip1": `①Jackery公式サイトで <a> ご注文いただく </a> と、保証期間が自動的に延長され（最長5年）、Jackeryポイント（1円＝1ポイント）が付与されます。`,
    "tip2": `②公式サイト以外の正規店舗で購入した<a> 製品を登録する </a> ことで、保証期間が延長されます（最長5年）。`,
    "tip3": ``,
  },
  "bank": {
    "name": "銀行名",
    "branchName": "銀行支店名",
    "type": "口座種別",
    "account": "口座番号",
    "realName": "口座名義"
  },
  "return": {
    "orderNumber": "注文番号",
    "status": "状態",
    "attachment": "ファイル",
    "confirmRevoke": "返品・交換申し込みをキャンセルするか確認してください?",
    "returnPolicy_link": "https://www.jackery.jp/pages/warranty-and-returns",
    "returnPolicy_1": "返品/交換に関しましては、",
    "returnPolicy_2": "「保証・返品・支払い・発送」",
    "returnPolicy_3": "にご確認ください。ご不明な点がございましたら、",
    "returnPolicy_4": "jackery.jp{'@'}jackery.com",
    "returnPolicy_5": "までお問い合わせください。"
  },
  "devices": {
    "empty": "デバイスが見つかりません",
    "more": "詳しく",
    "extendedWarrantyDeadline": "保証期限",
    "warrantyStatus": "製品の保証ステータス",
    "existWarranty": "延長保証登録完了",
    "nonExistentWarranty": "2022年1月1日以降、Jackery Japan公式オンラインストアで製品を購入いただいたお客様は、製品保証登録を行えば従来の2年保証が一年間追加となり、三年間の長期保証をご提供させていただきます。既に製品保証登録したお客様は自動的に三年間の保証となりますので、再度のご登録は不要です。",
    "userManual": "取扱説明書",
    "warrantyInfo": "「確認」ボタンをクリックし、延長保証をご提供させていただきます。",
    "newsletter": "ニュースレターの配信を希望する",
    "warrantySuccess": "延長保証登録完了",
    "download": "ダウロード",
    "none": "なし",
    "addNewDevice": "保証登録へ",
    "shopNow": "今すぐ購入"
  },
  "address": {
    "empty": "お届け先がまだ登録されていません～",
    "add": "新しいお届け先を追加する",
    "edit": "送り先を変更する",
    "userName": "氏名",
    "address1": "送り先①",
    "address2": "送り先②",
    "country": "国/地域",
    "country_code": "国/地域 郵便番号",
    "province": "都道府県",
    "province_code": "都道府県 郵便番号",
    "city": "市",
    "zip": "郵便番号",
    "phone": "電話番号",
    "company": "会社",
    "default": "デフォルトの送り先にする",
    "isDefault": "デフォルトアドレス",
    "error": "配送先住所が異常か、情報が不完全です。確認してください"
  },
  "character": {
    "comma": "、"
  },
  // Following is server language pack
  "msg": {
    "success": "成功"
  },
  "user": {
    "log": {
      "expire": "もう一度ログインしてください。"
    }
  },
  "message": {
    "order_status_shipping": "出荷済み",
    "order_status_completed": "注文完了",
    "order_status_processing": "処理中",
    "order_status_archived": "出荷済み",
    "order_status_canceled": "キャンセルに成功しました",
    "order_status_unpaid": "未払い",
    "order_deliver_time_desc": "1~3営業日以内に発送",
    "device_not_found": "デバイスが存在しません",
    "device_disable_can_not_set_warranty": "延長保証をご提供いただけません。",
    "device_was_warranty": "本製品は延長保証の対象となります。",
    "device_not_support_warranty": "延長保証をご提供いただけません。"
  },
  "create": {
    "address": {
      "fail": "アドレスの追加に失敗しました"
    }
  },
  "activation": {
    "email": {
      "expire": "このメールの期限が切れました。"
    }
  },
  "select_country": {
    "title": "お住まいの国／地域を選択"
  },



  // 会员首页 多语言
  "member_index" : {
    "common": {
      "login": "ログイン",
      "register": "登録",
      "already_a_member": "既にアカウントをお持ちですか？",
      "points": "ポイント",
      "redeem": "交換",
      "rules": "使用方法",
      "copy_successfully": "コピーしました",
      "outOfStock": "在庫切れ",
      "quick_login": "ログイン/新規登録"
    },
    // Banner 标题
    "banner": {
      "title": `Jackery 会員`,
      "description": `Jackeryポイントを貯めて、限定特典と交換し、Jackeryで大切なことをしましょう。`,
      "points_balance": "ポイント残高",
      "coming_soon": "獲得予定ポイント",
      "my_rewards": "私の特典",
      "my_activity": "私の実績",
      "unused": "未使用",
      "to_be_redeemed": "交換可能",
      "no_unused_rewards": "利用できる特典はありません",
      "no_convertible_rewards": "特典はありません",
      "no_activity_records": "現時点、ポイント実績がありません",
      "time": "日付",
      "action": "内容",
      "points": "ポイント",
    },
    "section_tabs": {
      "membership_benefits": "会員特典",
      "redeem_points": "ポイント交換",
      "earn_points": "ポイントを貯める",
      "guide": "ガイド",
      "faq": "よくある質問",
    },
    // Benefits
    "membership_benefits": {
      "title": `Jackery会員限定特典<br class="block lg:hidden"/>について`,
      "banner_text": "お買い物でJackeryポイントを貯め、限定特典に交換できます",
      "coupon_benefit": "会員登録で{num}OFFクーポン進呈",
      "giftcard_benefit": "ワンクリックで返品可能",
      "product_benefit": "製品定期点検ご連絡サービス",
      "gift_benefit": "保証期間終了後15％OFFクーポン進呈"
    },
    // Redeem Points
    "redeem_points": {
      "title": "ポイント交換で特典をゲット",
      "unusedTips": "有効期限が切れる前に利用しましょう！",
      "pointTips": "お持ちのJackeryポイントは {0} です",
      "category_tabs": {
        "coupon": "クーポン",
        "gift_card": "ギフトカード",
        "jackery_products": "Jakcery Product",
        "virtualCard": "Extras",
      },
      "code": "コード",
      "redeem_confirm_title": "{0} ポイントが使用されます。",
      "redeem_confirm_subtitle": "一度交換されたポイントは返却できません。",
      "redeem_confirm_content": "本当にご利用になりますか？",
      "successfully_redeemed": "交換に成功しました",
      "information_tips": "ギフトカードに関する情報はメールアドレスまで送信されました。ご確認ください。",
      "copy_and_use": "コピーして使用",
      "day": "日",
      "hour": "時",
      "minute": "分",
      "seconds": "秒",
      "count_down_info": "キャンペーンは終了し、特典に交換できません。ページをリフレッシュして再度ご確認ください。"

    },
    // 获取积分方式
    "earn_points": {
      "title": "ポイント獲得方法",
      "go_now": "特典をゲット",
      "points_earned": "ゲットしました",
      "membership_registration": "会員登録",
      "membership_registration_desc": "会員になると、{0} Jackeryポイントが付与されます。",
      "email_subscribtion": "メルマガ登録",
      "email_subscribtion_desc": "Jackeryのメールマガジンをご登録いただくと、{0} Jackeryポイントが獲得できます。",
      "sms_subscribtion": "SMS購読",
      "sms_subscribtion_desc": "SMSでJackeryを購読すると、{0} Jackeryポイントが付与されます。",
      "fill_in_your_birthday": "誕生日を記入する",
      "fill_in_your_birthday_desc": "誕生日を記入すると、{0} Jackeryポイントが付与されます。<br/>誕生日ごとにポイントが付与され、お買い物ポイントが2倍になります。",
      "extend_your_warranty": "製品保証の延長",
      "extend_your_warranty_desc": `
        Jackery公式サイトでの製品保証登録1回につき{0} Jackeryポイントが付与されます。
        <br/>
        (公式サイト以外の正規販売店でのご購入のみ。)
      `,
      "shop_and_earn": "お買い物ポイント",
      "shop_and_earn_desc": `
        お買い物で、1円＝1ポイントになります。<br/>
        {1}のご注文時には、さらに{0} Jackeryポイントが付与されます。<br/>
        また、購入金額が{3}に達した場合、さらに{2} Jackeryポイントを獲得できます。
      `,
      "tip": "複数サイトの購読は１回の購読としてカウントされます。"
    },
    "guide": {
      "title": "仕組みについて",
      "step_title_1": "1. 新規登録",
      "step_content_1": "Jackeryのアカウントを作成し、会員限定の特典をプレゼント。",
      "step_title_2": "2. ポイントを貯めます",
      "step_content_2": `特定アクションにより、ポイントが獲得できます（例：個人情報の記入\/商品の購入\/イベントへの参加など）。`,
      "step_title_3": "3. 特典を獲得",
      "step_content_3": `Jackeryポイントで限定特典（クーポン、ギフトカードなど）を交換します。`,
      "learn_more": "詳しくはこちら",
    },
    // 注册 指引页
    "register_guide": {
      "title": "Jackery会員限定特典の獲得方法",
      "step_1_title": "ステップ1 - 会員登録とログイン",
      "step_1_subtitle_1": "「会員新規登録」",
      "step_1_content_1": `① <a class="text-primary hover:text-primary" href="https://account.jackery.com/register?CR=JP" target="_blank">会員新規登録</a>ページにアクセスするか、<a class="text-primary hover:text-primary" href="https://account.jackery.com?CR=JP" target="_blank">account.jackery.com</a>で「新規会員登録」を選択します。`,
      "step_1_content_2": "②メールアドレスとパスワードを入力します。",
      "step_1_content_3": "③ 新しいパスワードを確認し、メールで取得した認証コードを入力します。",
      "step_1_content_4": `④「Jackeryのプライバシーポリシーと利用規約に同意します」と「Jackeryの最新ニュース、お得な情報、プロモーション、製品情報を受け取ることに同意します」にチェックを入れます。あなたの権利を守るため、Jackeryのプライバシーポリシーと利用規約を必ずお読みください。`,
      "step_1_content_5": `⑤「送信」をクリックして登録を完了します。<br/>(登録済みの場合は、このステップをスキップし、ログインしてください。）`,
      "step_1_subtitle_2": "「ログイン」",
      "step_1_content_6": `① ３つのログイン方法：`,
      "step_1_content_7": `<a class="text-primary hover:text-primary" href="https://account.jackery.com/login?CR=JP" target="_blank">ログインページ</a>にアクセスします`,
      "step_1_content_8": `<a class="text-primary hover:text-primary" href="https://account.jackery.com?CR=JP" target="_blank">account.jackery.com</a>で「ログイン」を選択します`,
      "step_1_content_9": `ホームページで「アカウント」のアイコンをクリックします。`,
      "step_1_content_10": `② メールアドレスとパスワードを入力します。GoogleやAmazonなどの外部サービスでログインすることも可能です。`,
      "step_1_content_11": `③「ログイン」をクリックして、アカウントセンターにアクセスします。`,
      "step_1_img_1_pc": "https://cdn.shopify.com/s/files/1/0100/1537/5438/files/1_6623d739-d315-4baf-aa29-68ae1f85cad6.png?v=1689919437",
      "step_1_img_2_pc": "https://cdn.shopify.com/s/files/1/0100/1537/5438/files/2_756a9c40-e43d-4ade-ae26-f9df16f41941.png?v=1689919437",
      "step_1_img_3_pc": "https://cdn.shopify.com/s/files/1/0100/1537/5438/files/3_fa37568a-b042-4c3b-b588-3e4f9d8b40a9.png?v=1689919437",
      "step_1_img_4_pc": "https://cdn.shopify.com/s/files/1/0100/1537/5438/files/4_9ff086cd-1cf2-4ab2-9460-9b1d34327a63.png?v=1689919437",
      "step_1_img_5_pc": "https://cdn.shopify.com/s/files/1/0100/1537/5438/files/5_f5a60068-2f27-4463-bbd8-e7f53ca991b8.png?v=1689919437",
      "step_1_img_6_pc": "https://cdn.shopify.com/s/files/1/0100/1537/5438/files/6.1.png?v=1689919437",
      "step_1_img_1_mb": "https://cdn.shopify.com/s/files/1/0100/1537/5438/files/1_46da8029-1339-4ecb-9285-9ee0f1d6ad97.png?v=1689918217",
      "step_1_img_2_mb": "https://cdn.shopify.com/s/files/1/0100/1537/5438/files/2_4f643651-1543-4362-af05-4b30552dbc2b.png?v=1689918216",
      "step_1_img_3_mb": "https://cdn.shopify.com/s/files/1/0100/1537/5438/files/3.1.png?v=1689918218",
      "step_1_img_4_mb": "https://cdn.shopify.com/s/files/1/0100/1537/5438/files/3.2.png?v=1689918217",
      "step_1_img_5_mb": "https://cdn.shopify.com/s/files/1/0100/1537/5438/files/3.3.png?v=1689918219",
      "step_1_img_6_mb": "https://cdn.shopify.com/s/files/1/0100/1537/5438/files/3.4.png?v=1689918218",

      "step_2_title": "ステップ2 - Jackeryポイントの獲得",
      "step_2_content_1": `<a class="text-primary hover:text-primary" href="https://membership.jackery.com?CR=JP" target="_blank">「Jackery 会員」</a>ページで「ポイント獲得方法」でJakceryポイントの獲得方法を確認します。`,
      "step_2_img_1_pc": "https://cdn.shopify.com/s/files/1/0100/1537/5438/files/6.2.png?v=1689919433",
      "step_2_img_1_mb": "https://cdn.shopify.com/s/files/1/0100/1537/5438/files/3.5.png?v=1689918217",

      "step_3_title": "ステップ3 - Jackeryポイントの確認",
      "step_3_content_1": `ログインした後、<a class="text-primary hover:text-primary" href="https://account.jackery.com/account/overview?CR=JP" target="_blank">アカウントセンター</a>または<a class="text-primary hover:text-primary" href="https://membership.jackery.com?CR=JP" target="_blank">Jackery会員ページ</a>でポイント残高を確認できます。`,
      "step_3_content_2": `① アカウントセンター：ポイントを確認する方法は３つあります：「概要」、「プロフィール」と「ウォレット」。`,
      "step_3_content_3": `②「ポイント残高」をクリックすることで、<a class="text-primary hover:text-primary" href="https://membership.jackery.com?CR=JP" target="_blank">Jackery会員ページ</a>にアクセスし、ポイント残高を確認することができます。`,
      "step_3_content_4": `ポイントは２種類あります：有効ポイントと保留中ポイント。`,
      "step_3_content_5": `有効ポイントは「ポイント残高」として表示され、交換に使用できます。`,
      "step_3_content_6": `保留中ポイントは「獲得予定ポイント」として表示され、交換に使用できません。`,
      "step_3_content_7": `「獲得予定ポイント」は特定の日付に「ポイント残高」に変更されます。`,
      "step_3_content_8": `例えば、お買い物で獲得したお買い物ポイントは、商品発送時に「獲得予定ポイント」に表示されます。30日後、そのポイントは「ポイント残高」に変更されます。`,
      "step_3_img_1_pc": "https://cdn.shopify.com/s/files/1/0100/1537/5438/files/7_b3d8d856-3d0e-4aee-b2dc-ab26e19e2dc1.png?v=1689919434",
      "step_3_img_2_pc": "https://cdn.shopify.com/s/files/1/0100/1537/5438/files/8_9af1762d-5afa-431c-8700-f0e0058a3b27.png?v=1689919435",
      "step_3_img_3_pc": "https://cdn.shopify.com/s/files/1/0100/1537/5438/files/9_f5ddc20d-33b4-4fa5-973b-1d84503f9888.png?v=1689919434",
      "step_3_img_4_pc": "https://cdn.shopify.com/s/files/1/0100/1537/5438/files/10_0cb6307b-8632-4dee-b665-3483371bec28.png?v=1689919435",
      "step_3_img_1_mb": "https://cdn.shopify.com/s/files/1/0100/1537/5438/files/4.1.png?v=1689918218",
      "step_3_img_2_mb": "https://cdn.shopify.com/s/files/1/0100/1537/5438/files/4.2.png?v=1689918218",
      "step_3_img_3_mb": "https://cdn.shopify.com/s/files/1/0100/1537/5438/files/4.3.png?v=1689918218",
      "step_3_img_4_mb": "https://cdn.shopify.com/s/files/1/0100/1537/5438/files/4.4.png?v=1689918219",

      "step_4_title": "ステップ4 - Jackeryポイント交換",
      "step_4_content_1": `① <a class="text-primary hover:text-primary" href="https://membership.jackery.com?CR=JP" target="_blank">Jackery会員ページ</a>にログインし、「私の特典 - 交換可能」をクリックするか、ナビゲーションメニューの「ポイント交換」をクリックします。`,
      "step_4_content_2": `お持ちのポイント残高で交換できる特典はハイライトされます。`,
      "step_4_content_3": `② 好きな特典を選択して、「交換」をクリックした後、ポップアップの「確認」をクリックすれば特典に交換できます。`,
      "step_4_img_1_pc": "https://cdn.shopify.com/s/files/1/0100/1537/5438/files/11_f1d25490-4491-46fd-99f1-f34445308add.png?v=1689919435",
      "step_4_img_2_pc": "https://cdn.shopify.com/s/files/1/0100/1537/5438/files/12_159354c8-588f-4280-a4e4-2eab64e9ad1c.png?v=1689919435",
      "step_4_img_3_pc": "https://cdn.shopify.com/s/files/1/0100/1537/5438/files/16.png?v=1689919434",
      "step_4_img_4_pc": "https://cdn.shopify.com/s/files/1/0100/1537/5438/files/13_416dbc5c-9fbd-46f0-ad8b-43d601c6e2b6.png?v=1689919434",
      "step_4_img_1_mb": "https://cdn.shopify.com/s/files/1/0100/1537/5438/files/4.5.png?v=1689918219",
      "step_4_img_2_mb": "https://cdn.shopify.com/s/files/1/0100/1537/5438/files/4.6.png?v=1689918218",
      "step_4_img_3_mb": "https://cdn.shopify.com/s/files/1/0100/1537/5438/files/5_55ff1497-0501-42e7-ba6a-b32a14b714a8.png?v=1689918218",
      "step_4_img_4_mb": "https://cdn.shopify.com/s/files/1/0100/1537/5438/files/7_001173a7-c311-4787-b927-f5c4e9805f3f.png?v=1689918218",

      "step_5_title": "ステップ5 - 特典の使い方",
      "step_5_content_1": `ポイントをJackeryクーポンとギフトカードに交換した場合、「コピーして使用」をクリックすれば、クーポンコードがコピーされます。Jackery公式サイトでお買い物し、チェックアウトの際にクーポンを使用すると、よりお得にお買い物をすることができます。`,
      "step_5_content_2": `ポイントをJackery Productに交換した場合、購入の際にクーポンコードを適用することで、無料で製品を購入することができます。`,
      "step_5_img_1_pc": "https://cdn.shopify.com/s/files/1/0100/1537/5438/files/14_5af01405-0970-4d0c-9ba7-4b7221326322.png?v=1689919434",
      "step_5_img_2_pc": "https://cdn.shopify.com/s/files/1/0100/1537/5438/files/15_76177ec3-71db-4a44-8493-6ab9299a26d2.png?v=1689919435",
      "step_5_img_1_mb": "https://cdn.shopify.com/s/files/1/0100/1537/5438/files/8_ad548cd8-4e9f-493c-8d14-008ca5106d10.png?v=1689918218",
      "step_5_img_2_mb": "https://cdn.shopify.com/s/files/1/0100/1537/5438/files/9_12c8603b-c0fb-49a7-bdc9-598a4ee7db1f.png?v=1689918218",
    },
    "faq": {
      "title": "よくある質問",
      "membership": {
        "title": "Jackery会員",
        "question_1": "1. Jackery会員サービスとは？",
        "answer_1": `Jackery会員サービスとは、お客様が<a class="text-primary hover:text-primary" href="https://www.jackery.jp">jackery.jp</a>でポイントを獲得するポイント制サービスです。完全無料で参加でき、貯まったポイントは、特典と交換が可能です。Jackery会員になることで、お客様のご愛顧に感謝の気持ちをお伝えして参ります。`,
        "question_2": "2. Jackery会員になるには？",
        "answer_2": `
          Jackery会員になるには、Jackeryアカウントを登録する必要があります。
          <br/>
          まだお持ちでない方は、ご登録ください。<a class="text-primary hover:text-primary" href="/index/guide" target="_blank">登録方法</a>についてはこちらをご覧ください。
          <br/>
          すでにJackery公式サイトにアカウントをお持ちの方は、既存のアカウントを有効化またはログインしてください。
        `,
        "question_3": "3. すでに登録済みの場合、Jackeryの他サイトから再登録する必要がありますか？",
        "answer_3": "Jackery公式サイトで登録済であれば、再登録は不要です。",
        "question_4": "4. 多くのJackeryアカウントを持っている場合、1つに統合することは可能ですか？",
        "answer_4": "異なるメールアドレスで登録した場合、Jackery IDが異なるため、統合することはできません。",
        "question_5": "5. Jacker会員から退会するにはどうすればよいですか？",
        "answer_5": `
          退会希望の場合はカスタマーサービスまでご連絡ください。ポイントはゼロにリセットされます。
          <br/>
          退会したユーザーは再登録が可能ですが、ポイントは再計算されますのでご了承下さい。
          <br/>
          会員として再登録する場合、正常にログインするため、別のメールアドレスでログインしてください。
        `,
      },
      "earn_points": {
        "title": "ポイントを貯めましょう",
        "question_1": "1. ポイントを貯めるには？",
        "answer_1": `
          Jackery会員になると、公式サイト上で指定のタスクを行うことでポイントを貯めることができます。以下注意事項をご覧ください。
          <br/>
          <br/>
          ① 会員登録について：2022年4月以前に会員登録された方は、ログインしてアカウントを確認することで、会員資格を有効化する必要があります。初回登録または有効化でポイントが付与されます。
          <br/>
          ② メルマガ登録：メルマガを初回登録するとポイントを獲得できます。ただし、配信停止後の再受信はポイント付与対象外です。複数サイトの購読は１回の購読としてカウントされます。
          <br/>
          ③ SMS購読：マイアカウント-マイプロフィールで電話番号を入力し、SMSを初回購読するとポイントが付与されます。複数サイトの購読は１回の購読としてカウントされます。
          <br/>
          ④ 誕生日の入力： 誕生日を記入すると、ポイントが付与されます。誕生日にポイントが付与され、お買い物ポイントが2倍になります。誕生日は１回のみ記入できます。
          <br/>
          ⑤ 保証期間の延長：Amazon、楽天市場、ヤフーショッピングなどのサードパーティプラットフォームで購入したユーザーのみが対象です。公式サイトで製品保証の延長に成功した場合、ポイントがルールに従って付与されます。
          <br/>
          ⑥ お買い物ポイント：Jackery公式サイトで商品を購入すると、注文の合計金額に応じてポイントが付与されます。「それに」{0}の注文、または購入金額が{1}に達した場合、さらにポイントが付与されます。
          <br/>
          ⑦ 特別ポイントキャンペーンに参加：Jackery公式サイトの特別ポイントキャンペーンに参加すると、ポイントが付与されます。詳しくはJackery会員ページ内の実績説明をご覧ください。
        `,
        "question_2": "2. ポイントはいつ付与されますか？",
        "answer_2": `
          ポイント付与時間には2種類あります：
          <br/>
          <br/>
          ① 即時発行：登録、購読、誕生日記入、保証延長など公式サイトで指定されたタスクを完了した後、ポイントが24時間以内にアカウントに付与されます。
          <br/>
          ② 後日発行：jackery.jp でのお買い物ポイントは、商品発送後に「獲得予定ポイント」として付与されます。発送された30日後に「ポイント残高」として有効になります(1回の注文に複数の商品が含まれる場合は、最初の商品の発送日が基準となります)。対象となる注文で付与されるポイントは、実際の支払総額に基づきます。
          <br/>
          <br/>
          なお、保留中ポイントは、公式サイトの特典との交換には使用できません。
        `,
        "question_3": "3. ポイントがアカウントに正しく付与されていない場合はどうすればよいですか？",
        "answer_3": `会員アカウントやポイント残高に関するご質問は、カスタマーサービス（<a class="text-primary hover:text-primary" href="mailto:jackery.jp{'@'}jackery.com">jackery.jp{'@'}jackery.com</a>）までご連絡ください。`,
        "question_4": "4. ポイントに有効期限はありますか？",
        "answer_4": `
          Jackeryポイントの有効期限は2年間です。ユーザーがJackery会員サービスの正式な開始日（2023年6月29日）から貯めたポイントは2年間で失効し、失効したポイントはアカウントから差し引かれます。
          <br/>
          お客様の権利と利益を守るため、有効なポイントを確認し、有効期限内に交換してください。
        `,
        "question_5": "5. Jackery会員サービスの開始前に行われた取引は、ポイントが付与されるのでしょうか？",
        "answer_5": "はい！Jackery会員サービスは2023年6月29日に正式に開始されます。Jackeryの会員登録をすることで、（2020年1月1日以降）Jackery公式サイトでの注文や指定のタスクを行った場合、対応するオリジナルポイントが付与されます。オリジナルポイントの有効期限は（2023年6月29日から）2年間です。",
        "question_6": "6. 税金や送料をポイントに付与することはできますか？",
        "answer_6": "いいえ、できません。ポイントの計算には、配送料およびすべての税金（国税、地方税、使用税を含むがこれに限定されない）が含まれません。",
        "question_7": "7. 注文をキャンセルしたり、商品を返品したり、商品の一部返金を申請した場合、ポイントはどうなりますか？",
        "answer_7": "ご注文のキャンセル、返金、返品の場合、付与されたポイントは相殺されるか減少します。価格調整による一部返金の場合は、ポイントが差し引かれます。",
        "question_8": "8. クロスサイトポイント／為替レート／ポイント表示方法に関する特記事項",
        "answer_8": `
          Jackeryポイントは、Jackery全ての公式サイトで獲得・利用することができます。
          <br/>
          Jackeryの各公式サイトでポイント残高がある場合、システム上各サイトの残高を為替レートによって換算した合計ポイント数が表示されます。
          <br/>
          合計ポイント数は毎月1日の最新の米ドル為替レートに基づいて換算されるため、ポイント残高は毎月為替レートによって変動します。
          <br/>
          ポイントは整数値で表示され、小数点以下の端数はカウントされますが、表示されず、交換することはできません。
        `,
      },
      "redeem": {
        "title": "ポイント交換",
        "setting1": "ユーザーは直近{num}日間に{time}回までご利用いただけます。",
        "setting2": "会員は{time}回しか交換できません。",
        "setting3": "利用可能なポイントを十分お持ちの場合、特典に交換できます。",
        "question_1": "1. Jackeryポイントを特典に交換する方法は？",
        "answer_1": `
          会員ホームページ（membership.jackery.com）からログインして交換することができます。交換可能な特典はハイライト表示されます。特典を選択し、「交換」をクリックしてください。
          <br/>
          アカウントセンターにログインし、「ポイント残高」をクリックすると、会員ホームページにジャンプして、ポイントを特典に交換することができます。
          <br/>
          一度交換したポイントの払い戻しはできません。交換を希望されることをご確認の上、お手続きをお願いします。
        `,
        "question_2": "2. Jackery ポイントはどのような特典に交換できますか？",
        "answer_2": `
          豊富な特典をご用意しています。ポイントは、クーポン、ギフトカード、Jackery製品、面白いプレゼントなどと交換することができます。特典は、ポイントを貯めた、または交換したサイトでのみ利用でき、他のサイトで使用することはできません。 有効期間内にご利用ください。詳しくは会員ホームページをご覧ください。一定期間内に特典に交換できる回数は限られています。交換回数上限に達すると、システムから提示が届きます。新しいサイクルに入ると、再度特典にご交換いただけます。
          <br/>
          <br/>
          注意事項
          <br/>
          ① Jackeryクーポン： 各クーポンには、具体的な割引額、最低注文金額、適用商品、有効期間などがあります。ご利用の際は、各クーポンの詳細情報をご確認ください。クーポンは、セールなどと併用できません。{0}
          <br/>
          ② Jackeryギフトカード： 各ギフトカードには、具体的な金額、有効期間があります。ご利用の際は、各ギフトカードの詳細情報をご確認ください。利用中に何かご質問がありましたら、Jackery公式カスタマーサービスまたはメール<a class="text-primary hover:text-primary" href="mailto:jackery.jp{'@'}jackery.com">jackery.jp{'@'}jackery.com</a>にご連絡ください。{1}
          <br/>
          ③ Jackery Product：こちらのクーポンはセールや他のクーポンなど、他のキャンペーンと併用できません。チェックアウト時にクーポンコードとクーポンを獲得したメールアドレスを入力して、ご利用ください。各Jackery Productクーポンは、特定の商品1点のみにご利用いただけます。{2}
          <br/>
          ④ Jackery以外のギフトカード： Jackery 以外のギフトカードは数量が限られています。在庫がなくなった場合、「在庫切れ」のメッセージが表示され、ポイント交換ができなくなることがあります。Jackery以外のギフトカードは在庫切れの場合、表示されません。Jackery以外のギフトカードの利用ルールについては、該当ブランドの公式サイトでご確認ください。{3}
        `,
        "question_3": "3. 交換した特典の確認方法は？",
        "answer_3": `
          ① アカウントセンター（<a class="text-primary hover:text-primary" href="https://account.jackery.com?CR=JP">account.jackery.com</a>）にログインし、「ウォレット」でご確認ください。または、会員ホームページ（<a class="text-primary hover:text-primary" href="https://membership.jackery.com?CR=JP">membership.jackery.com</a>）にログインし、「私の特典」でご確認ください。
          <br/>
          ② クーポン、ギフトカード、Jakcery Productなど、特典獲得のお知らせメールが届きます。
        `,
        "question_4": "4. 特典、ポイントは現金に換えることができますか？",
        "answer_4": `
          Jackery会員サービスを通じて獲得した特典、ポイントに現金価値がなく、現金に換えることも、他人に譲渡することもできません。(ただし、ギフトカードはお友達やご家族に贈ることができます）。
          <br/>
          交換した特典を第三者に販売することはできません。
          <br/>
          Jackeryは不正な取引に対して法的措置を講じる権利を有します。
        `,
        "question_5": "5. ポイント交換で入手した現物景品は、送料が必要ですか？",
        "answer_5": "ほとんどの場合、交換した商品の送料は無料とさせていただいております。特定の商品については、送料をご負担いただく場合があります。",
        "question_6": "6. ポイントで交換した特典をキャンセル、返品、交換することはできますか？",
        "answer_6": `
          いいえ、できません。交換条件をよくお読みの上、交換してください。交換が完了した後のキャンセルはできかねます。
          <br/>
          配送中に商品の破損があった場合は、7日以内にカスタマーサービス（<a class="text-primary hover:text-primary" href="https://support.jackery.com?cr=JP" target="_blank">support.jackery.com</a>）までご連絡ください。
        `,
        "question_7": "7. 交換した商品は、Jackeryで購入した商品と一緒に発送してもらえますか？",
        "answer_7": `
          いいえ、できません。交換した商品（ポイント交換や抽選で入手した商品）は別送となり、30～90営業日以内にお届けします。
          <br/>
          公式サイトでの配送手配が迅速に行えるよう、住所情報の送信または確認が必要です。
        `,
      },
      "others": {
        "title": "その他",
        "question_1": "1. お問い合わせはこちら",
        "answer_1": `
        ご不明な点がございましたら、カスタマーサービス（<a class="text-primary hover:text-primary" href="mailto:jackery.jp{'@'}jackery.com">jackery.jp{'@'}jackery.com</a>）までメールでお問い合わせいただくか、<a class="text-primary hover:text-primary" href="https://support.jackery.com?cr=JP" target="_blank">support.jackery.com</a>でお問い合わせください。また、こちらをクリックしてお問い合わせください。
        `,
        "question_2": "2. Jackery会員ポイント規約違反の取り扱い",
        "answer_2": `利用者が不正または虚偽の行為によりポイントの取得、利用、または特典を獲得した場合、Jackeryは、当該利用者のポイントの凍結または無効化、サービスの提供停止または当該利用者のアカウントの取り消しを適宜行う権利を有し、当該利用者の再登録申請を受理しない権利を有します。`,
        "question_3": "3. 特別ステートメント",
        "answer_3": `
          Jackery会員サービスに関するすべての権利は、Jackeryが有します。
          <br/>
          会員特典は随時変更される可能性があります。詳細は会員ページでご確認ください。
        `,
        "question_4": "4. 虚偽の情報を使用したり、特典を得るために複数のアカウントを作成したりする場合は、失格となります",
        "answer_4": `
          Jackeryは、以下の方式で取得した賞品/注文を処理する権利を有します:
          <br/>
          ① 虚偽の情報の使用：虚偽の名前、住所、メールアドレス、または他人の個人情報を使用してアカウントを作成することは禁止されます。
          <br/>
          ② 複数のアカウントの作成：１人のユーザーが追加の特典や割引を取得するために複数のアカウントを作成することは禁止されます。異なるメールアドレスまたは ID を使用して複数のアカウントに登録することも含まれます。
          <br/>
          ③ 自動登録ツール：自動化ツールやスクリプトを使用してアカウントを一括で登録することは禁止されます。
          <br/>
          ④ 不正行為：システムのバグを悪用して不正取引を実行するなどの不正行為は禁止されます。
        `,
      }
    }
  },
  // overview
  "overview": {
    "unit": 'ポイント',
    "pointsBalance": "ポイント残高",
    "comingSoon": "獲得予定ポイント",
    "benefitsForYou": "特典",
    "welcomeGift": "{num}OFF クーポン",
    "welcomeGiftInfo": "Jackeryの会員に初めて登録すると、{num}OFFクーポンを進呈致します。",
    "firstRegistration": "ワンクリックで 返品",
    "firstRegistrationInfo": "会員登録をされているお客様がログインしてご注文された場合、マイページより返品・交換が可能です。",
    "largeOrderBenefits": "15％OFF クーポン",
    "largeOrderBenefitsInfo": "延長保証終了後3ヶ月以内に製品に何らかの問題が発生した場合、当社のカスタマーサービスにご連絡いただくことで、補償として15%OFFクーポンを差し上げます。",
    "referral": "友達を紹介する",
    "referralInfo": "お友達にクーポンを贈り、お友達が登録したり注文すると特典がもらえます！",
  },
  // 我的钱包
  "wallet": {
    "unit": 'ポイント',
    "jackeryPointsBalance": "ポイント残高",
    "comingSoon": "獲得予定ポイント",
    "pointsDetails": "私の実績",
    "redeemMyCoins": "ポイント交換",
    "coupon": "クーポン",
    "giftCard": "ギフトカード",
    "jackeryProducts": "Jackery Product",
    "extras": "Extras",
    "used": "使用済み",
    "expired": "期限切れ",
    "daysLeft": "残り{num}日(未使用)",
    "un_used": "未使用",
    "partially_used": "一部使用済み",
    "code": "コード",
    "rules": "使用方法",
    "copyAndUse": "コピーして使用",
    "noCoupon": "利用できるクーポンはありません",
    "noGiftCard": "利用できるギフトカードはありません",
    "noJackeryProducts": "利用できるJackery product クーポンはありません",
    "noExtras": "Extras",
    "redeemMyPoints": "ポイント交換へ",
    "viewMore": "もっと見る",
    "couponRules": {
      "one": {
        "title": "1. クーポンとは",
        "step1": "1) クーポンとは、Jackeryがキャンペーン、ターゲットマーケティング、会員ポイント交換などの形でユーザーに提供し、購入代金を軽減するための還元方法です。",
        "step2": "2) Jackeryが発行するクーポンは、電子版のクーポンで、Jackeryの公式サイトでの商品購入にご利用いただけます。",
        "step3": "3) 注文決済の際に、Jackeryクーポンのコードを入力すると、注文の合計金額から割引/クーポン金額が自動的に差し引かれます。クーポンを現金に交換ことはできません。",
      },
      "two": {
        "title": "2. クーポンの確認方法",
        "step1": "1) Jackeryの会員登録を行い、ユーザーセンター-ウォレット-クーポンでクーポン（未使用、使用済み、有効期限切れ情報を含む）を確認します。",
        "step2": "2）クーポンの有効期限をご参照の上、有効期限内にご使用ください。有効期間を過ぎた場合は返金いたしません。",
      },
      "three": {
        "title": "3. クーポンの使用方法",
        "step1": "1) 各クーポンには、具体的な割引額、最低注文金額、適用商品、有効期間などがあります。ご利用の際は、各クーポンの詳細情報をご確認ください。",
        "step2": "2) 適用サイト：クーポンは獲得したサイトでのみ使用でき、ほかのサイトでの使用はできません。",
        "step3": "3) 1回のご注文で使用できるクーポンは1枚のみで、キャンペーンとの併用はできません。ご注文に複数の商品が含まれる場合、クーポンは対象商品のみに適用されます。",
        "step4": "4) 一部のクーポンは獲得したアカウントに紐づいており、個人アカウントでのみ使用可能です。注文画面のメールアドレスは、クーポンを獲得したメールアドレスと一致している必要があり、譲渡することはできません。また、一部のクーポンは、他のユーザーに譲渡して使用することができます。詳細は、クーポンの使用方法をご参照ください。",
        "step5": "5) クーポンは、ギフトカードと併用することができます。",
        "step6": "6) 会員は、ポイントをクーポンに交換することができます。ポイントが差し引かれた後は、ポイント、クーポンともに返却することができません。一定期間内に会員ポイントをクーポンに交換できる回数にが限られている場合があります。",
        "step7": "7) クーポンを使用したご注文は、返金の場合クーポンの返却はできません。"
      },
      "four": {
        "title": "４．クーポン利用規定違反の取り扱い",
        "step1": "利用者が偽造・売買・譲渡したクーポンを使用した場合、Jackeryは、当該クーポンの凍結または無効化、クーポンが使用された注文の取消、当該ユーザーのクーポン使用資格の取消、サービスの提供停止または当該ユーザーのアカウントの取消を行う権利を有し、当該ユーザーによる再登録の申請を受け付けない権利を有します。",
      }
    },
    "giftRules": {
      "one": {
        "title": "1、Jackeryギフトカードとは",
        "step1": "1) ギフトカードは、Jackeryがキャンペーン、ターゲットマーケティング、会員ポイント交換などの形でユーザーに提供し、購入代金を軽減するための還元方法です。",
        "step2": "2）Jackeryギフトカードは電子版のクーポンで、Jackeryの公式サイトでの商品購入にご利用いただけます。",
        "step3": "3）注文決済の際に、Jackeryギフトカードのコードを入力すると、注文の合計金額からギフトカードの金額が自動的に差し引かれます。ギフトカードを現金に交換することはできません。",
      },
      "two": {
        "title": "2、ギフトカードの確認方法",
        "step1": "1）Jackeryの会員登録を行い、ユーザーセンター-ウォレット-ギフトカードでギフトカード（未使用、使用済み、有効期限切れ情報を含む）を確認します。",
        "step2": "2）ギフトカードの有効期限をご参照の上、有効期限内にご使用ください。有効期間を過ぎた場合は利用できません。",
      },
      "three": {
        "title": "3、ギフトカード使用方法",
        "step1": "1) 各ギフトカードには、具体的な金額、有効期間があります。ご利用の際は、各ギフトカードの詳細情報をご確認ください。",
        "step2": "2) 適用サイト：ギフトカードは、クーポンは獲得したサイトでのみ使用でき、ほかのサイトでの使用はできません。",
        "step3": "3）ギフトカードは、最低注文金額がなく、すべての商品に適用されます。",
        "step4": "4）ギフトカードは個人のアカウントに限らず、友人へのギフトとして譲渡することができます。",
        "step5": "5）ギフトカードは同一注文に複数枚使用でき、キャンペーン、クーポンと併用できます。",
        "step6": "6）会員は、ポイントをギフトカードに交換することができます。ポイントが差し引かれた後は、ポイント、ギフトカードともに返却することができません。",
        "step7": "7）一定期間内に会員ポイントをギフトカードに交換できる回数にが限られている場合があります。回数がなくなるとシステムからお知らせがあります。",
        "step8": "8）ギフトカードを使用したご注文は、返金の場合ギフトカードの返却はできません。",
      },
      "four": {
        "title": "４．ギフトカード利用規定違反の取り扱い",
        "step1": "利用者が偽造・売買したギフトカードを使用した場合、Jackeryは、当該ギフトカードの凍結または無効化、ギフトカードが使用された注文の取消、当該ユーザーのギフトカード使用資格の取消、サービスの提供停止または当該ユーザーのアカウントの取消を行う権利を有し、当該ユーザーによる再登録の申請を受け付けない権利を有します。"
      }
    },
    "productRules": {
      "one": {
        "title": "1. Jackery Product 景品とは",
        "step1": "1) Jackery Product 景品は、Jackeryがキャンペーン、ターゲットマーケティング、会員ポイント交換などの形でユーザーに提供する還元方法です。",
        "step2": "2) Jackeryが発行するJackery Productは、電子版のクーポンで、Jackeryの公式サイトで対応する商品の全額または一部の金額を差し引かれて購入することができます。",
        "step3": "3) 注文決済時に、Jackery Productの割引コードを入力すると、注文の合計金額から割引/クーポン金額が自動的に差し引かれます。クーポンを現金に交換ことはできません。",
      },
      "two": {
        "title": "2. Jackery Productクーポンの確認方法",
        "step1": "1) Jackeryの会員登録を行い、ユーザーセンター-ウォレット-Jackery ProductでJackery Productクーポン（未使用、使用済み、有効期限切れ情報を含む）を確認します。",
        "step2": "2) Jackery Productクーポンの有効期限をご参照の上、有効期限内にご使用ください。有効期間を過ぎた場合は利用できません。",
      },
      "three": {
        "title": "3. Jackery Product景品の使用方法",
        "step1": "1) 各Jackery Productクーポンには有効期限がありますので、ご利用の際は詳細情報をご確認ください。",
        "step2": "2) 適用サイト：Jackery Productクーポンは、クーポンは獲得したサイトでのみ使用でき、ほかのサイトでの使用はできません。",
        "step3": "3) 1回のご注文で使用できるクーポンは1枚のみで、キャンペーンとの併用はできません。ご注文に複数の商品が含まれる場合、クーポンは対象商品のみに適用され、他の商品に適用されません。",
        "step4": "4) Jackery Productクーポンは獲得したアカウントに紐づいており、個人アカウントでの使用のみ可能です。注文画面のメールアドレスは、クーポンを獲得したメールアドレスと一致している必要があり、譲渡することはできません。",
        "step5": "5) 会員は、ポイントを Jackery Productクーポンに交換することができます。ポイントが差し引かれた後は、ポイント、クーポンともに返却することができません。一定期間内に会員ポイントをクーポンに交換できる回数にが限られている場合があります。",
        "step6": "6) クーポンを使用したご注文は、返金の場合クーポンの返却はできません。"
      },
      "four": {
        "title": "4. Jackery Product景品の交換規約",
        "step1": "1）Jackery公式サイトにログインし、該当の商品詳細ページに入り、クリックして購入します。",
        "step2": "2) 決済画面で該当のクーポンコードを入力します（決済時にコード{XXX}を貼り付けます）。",
        "step3": "3) 決済画面に表示されるメールアドレスは、クーポンを獲得したメールアドレスであることをご注意ください。"
      },
      "five": {
        "title": "5．クーポン利用規定違反の取り扱い",
        "step1": "利用者が偽造・売買・譲渡したクーポンを使用した場合、Jackeryは、当該クーポンの凍結または無効化、クーポンが使用された注文の取消、当該ユーザーのクーポン使用資格の取消、サービスの提供停止または当該ユーザーのアカウントの取消を行う権利を有し、当該ユーザーによる再登録の申請を受け付けない権利を有します。"
      }
    },
    "extraRules": {
      "one": {
        "title": "1. Jackery Extrasとは",
        "step1": "1) Jackery Extrasは、商品券、クーポン、ギフトカード、現物景品など、Jackery以外のブランドからのギフトです。",
        "step2": "2) Jackeryはキャンペーン、ターゲットマーケティング、会員ポイント交換などの形でユーザーに提供する還元方法です。ポイントをJackery Extrasに交換し、ポイントが差し引かれた後、ポイント、Jackery Extrasともに返却することができません。",
      },
      "two": {
        "title": "2. Jackery Extrasの確認方法",
        "step1": "1) Jackeryの会員登録を行い、ユーザーセンター-ウォレット-Extrasで確認します。",
        "step2": "2) Jackery Extrasの有効期限をご参照の上、有効期限内にご使用ください。有効期間を過ぎた場合は返金いたしません。",
        "step3": "3) 景品の詳細情報はメールで送信されます。メールボックスから関連情報をご確認ください。"
      },
      "three": {
        "title": "3. Jackery Extrasの使用方法：",
        "step1": {
          "title": "1）現物景品の獲得方法：",
          "tip1": "①Jackery以外の現物景品は、住所を明確に記入する必要があります。",
          "tip2": "②申し込んだ後、30～90日以内に発送します。",
          "tip3": "③伝票番号などの重要な情報は、商品発送後にメールにてお知らせします。 ",
          "tip4": "④配送中に商品の破損があったり、その他のご質問がある場合は、お気軽にカスタマーサービスにご連絡ください。",
        },
        "step2": {
          "title": "2) Jackery以外のギフトカードの使用方法",
          "tip1": "①Jackery以外のギフトカードの獲得詳細、交換方法は、メールにてお知らせします。",
          "tip2": "②Jackery以外のギフトカードの受け取り期限はXX年で、受け取った時点で有効化されます。有効化されたギフトカードの有効期間は、対応するブランドが指定した有効期間に基づきます。詳細は対応するブランドの公式サイトでご確認ください。",
          "tip3": "③Jackery以外のギフトカードの具体的な使用方法は、ギフトカード交換プラットフォームで使用方法の紹介をご覧ください。",
        }
      },
      "four": "4. 紛争が発生した場合、Jackeryが最終的な決定権を有するものとします。"
    }
  },
  // My Service
  "service": {
    'imgText1': 'ようこそ、',
    'imgText2': 'Jackeryサポートへ',
    'pcImage': 'https://cdn.shopifycdn.net/s/files/1/0550/0524/9633/files/My_Service_f2d45c3c-6199-4b74-85d8-bc1977a5d9b9.png?v=1683191312',
    'mbImage': 'https://cdn.shopifycdn.net/s/files/1/0550/0524/9633/files/My_Service_efc7e4e4-a728-40e4-ab7b-31aab55839af.png?v=1683191345',
    'text': 'Jackery Support for Smart Serviceにアクセスします.',
    'contactUs': 'お問い合わせ',
    'viewSupport': 'サポートにアクセス',
  },
  // My Extended Warranty
  "myExtendedWarranty":{
    "emptyTips":"延長保証情報はありません",
    "shopNow": "ショッピングへ",
    "sn":"延長保証商品のSN番号",
    "notBound":"まだバインドされていない",
    "activate":"有効化へ",
    "underReview":"審査中です、少々お待ちください。",
  },
  // My Jackery Life
   "jackeryLife": {
    'title': '私の投稿',
    'imgText1': 'Jackeryで',
    'imgText2': 'エコな暮らし始めよう',
    'pcImage': 'https://cdn.shopifycdn.net/s/files/1/0550/0524/9633/files/2_2bf016ce-d9a0-4e4b-a163-e4272aba015d.png?v=1683189189',
    'mbImage': 'https://cdn.shopifycdn.net/s/files/1/0550/0524/9633/files/Jackery_life_8ab97bcf-fd91-4e1d-a1d4-8c378f824fdb.png?v=1683189235',
    'text': 'Jackery LifeでJackeryとのストーリーをシェアし、素敵な賞品をゲットするチャンス！',
    'learnMore': '詳しくはこちら',
  },
  "wishlist": {
    "empty": "欲しいものリストに商品がありません",
    "shopNow": "ショッピングへ",
    "modalTitle": "商品を欲しいものリストから削除しますか？",
    "modalCancel": "いいえ",
    "modalOk": "はい",
    "removeToast": "商品は欲しいものリストから削除されました。",
    "createdDesc": "追加日　新しい順",
    "createdAsc": "追加日　古い順",
    "priceDesc": "価格（降順）",
    "priceAsc": "価格（昇順）",
    "purchased": "購入済",
    "buyNow": "今すぐ購入",
    "selectAll": "すべての商品を選択する",
    "selected": "選択済",
    "amount": "合計金額",
    "addOn": "追加時間",
    "notGoodsGotoBuy": '商品を選択してください。',
    "viewMoreHref": "https://www.jackery.jp/"
  },
  "old-invite-new": {
    "myRewardsTitle": "私の特典",
    "myRewardsSubTitle": "こちらで獲得した特典を管理または利用します。期限切れな特典や利用された特典については「ウォレット」で確認してください。",
    "myRewardsNoData": "お友達をメルマガ登録または注文に招待して、下のダッシュボードで特典を獲得してください",
    "viewMore": "もっと見る",
    "myReferralsTitle": "私の招待",
    "myReferralsSubTitle": "招待の進捗状況を確認して、特典を獲得します。特典をクリックして詳細を確認してください。",
    "myReferralsNoData": "お友達をメルマガ登録または注文に招待し、こちらで特典を獲得します",
    "claim": "受取る",
    "claimed": "受取りました",
    "expired": "期限切れ",

    // 补充
    "myReferral": "私の招待",
    "enterEmail": "メールアドレスを入力してください",
    "invalidEmail": "無効なメールアドレスです",
    "messageSent": "メールを送信しました。",
    "copy": "コピーする",
    "copied": "コピーしました",
    "copiedSuccess": "コードをコピーしました",
    "friendEmail": "お友達のメールアドレスを入力してください",
    "referee": "被招待者",
    "referralType": "招待種類",
    "rewards": "特典",
    "status": "状態",
    "instagramCopySuccessTip": "紹介リンクをコピーして貼り付け、Instagram に共有しました",
  }
}
